<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="公告详情"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="detail_info">
      <div class="title">上课消息</div>
      <div class="detail">
        北京2022年冬残奥会将于3月4日至13日举行。2月21日，中国体育代表团在京成立，这是我国第五次组团参加冬残奥会。
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import teacher_api from "../api/teacher_api"
import guardian_api from "../api/guardian_api"
export default {
  data() {
    return {};
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .detail_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .title {
      width: 100%;
      height: 13vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-weight: bold;
    }
    .detail {
      width: 90%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>