<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="考勤汇总"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- 背景 -->
    <div class="background_img"></div>

    <!-- 顶部 -->
    <div class="time">
      <van-popover
        v-model="showPopover"
        :actions="dates"
        @select="onSelectDate"
        placement="bottom-start"
      >
        <template #reference>
          <div class="date" @click="showPopover = !showPopover">
            {{ date + " " }}<van-icon name="arrow-down" />
          </div>
        </template>
      </van-popover>

      <div class="morning_afternoon">
        <van-tabs
          v-model="morning_afternoon"
          type="card"
          @change="onSelectMorning_afternoon"
        >
          <van-tab title="上午" name="0"></van-tab>
          <van-tab title="下午" name="1"></van-tab>
        </van-tabs>
      </div>
    </div>

    <!-- 选择考勤类型 -->
    <div class="summary_type">
      <div
        :class="{ item: true, item_active: this.summaryType == 0 }"
        @click="changeSummaryType(0)"
      >
        <div
          class="value"
          v-if="this.summaryInfo.normalAttendanceVoList != undefined"
        >
          {{ this.summaryInfo.normalAttendanceVoList.length }}
        </div>
        <div class="title">出勤</div>
      </div>
      <div
        :class="{ item: true, item_active: this.summaryType == 1 }"
        @click="changeSummaryType(1)"
      >
        <div class="value" v-if="this.summaryInfo.lateStudentList != undefined">
          {{ this.summaryInfo.lateStudentList.length }}
        </div>
        <div class="title">迟到</div>
      </div>
      <div
        :class="{ item: true, item_active: this.summaryType == 2 }"
        @click="changeSummaryType(2)"
      >
        <div
          class="value"
          v-if="this.summaryInfo.absentStudentList != undefined"
        >
          {{ this.summaryInfo.absentStudentList.length }}
        </div>
        <div class="title">未到</div>
      </div>
      <div
        :class="{ item: true, item_active: this.summaryType == 3 }"
        @click="changeSummaryType(3)"
      >
        <div class="value" v-if="this.summaryInfo.leaveEarlyList != undefined">
          {{ this.summaryInfo.leaveEarlyList.length }}
        </div>
        <div class="title">早退</div>
      </div>
      <div
        :class="{ item: true, item_active: this.summaryType == 4 }"
        @click="changeSummaryType(4)"
      >
        <div
          class="value"
          v-if="this.summaryInfo.recessStudentList != undefined"
        >
          {{ this.summaryInfo.recessStudentList.length }}
        </div>
        <div class="title">请假</div>
      </div>
    </div>

    <!-- 表格 -->
    <table class="table" cellpadding="7">
      <tr>
        <th class="title" width="17%">姓名</th>
        <th class="title" width="30%">学号</th>
        <th class="title" width="38%">打卡时间</th>
        <th class="title">详情</th>
      </tr>
      <!-- 学生列表 -->
      <tr
        v-for="(item, index) in summaryType == 0
          ? this.summaryInfo.normalAttendanceVoList
          : summaryType == 1
          ? this.summaryInfo.lateStudentList
          : summaryType == 2
          ? this.summaryInfo.absentStudentList
          : summaryType == 3
          ? this.summaryInfo.leaveEarlyList
          : this.summaryInfo.recessStudentList"
        :key="index"
      >
        <td class="value" width="17%">{{ item.studentName }}</td>
        <td class="value" width="24%">{{ item.studentNumber }}</td>
        <td class="value" width="38%">
          {{ summaryType == 2 || summaryType == 4 ? "" : item.accessTime }}
        </td>
        <td class="value_detail" @click="jumpToDetail(item)">
          {{ summaryType == 2 ? "" : "详情" }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { attendanceSummaryInfo } from "../api/teacher_api";
export default {
  data() {
    return {
      // 选择日期下拉框
      showPopover: false,
      // 日期列表
      dates: [
        {
          text:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 1),
        },
        {
          text:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 2),
        },
        {
          text:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 3),
        },
      ],
      date:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
      // 选择上午还是下午
      // 上午 06:00 - 07:40   下午 13:30-14:40
      morning_afternoon: "0",
      // 统计类型 0为出勤 1为迟到 2为未到  3为早退 4为请假
      summaryType: 0,
      // 考勤信息
      summaryInfo: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    // ...
    if (from.name == "homepage") {
      to.meta.reload = true;
    }
    next();
  },
  created() {
    this.$route.meta.reload = true;
  },
  activated() {
    if (this.$route.meta.reload) {
      // 到校统计详情 、考勤汇总信息
      this.attendanceSummaryInfo();
      this.$route.meta.reload = false;
    }
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 获取到校统计详情 、考勤汇总
    attendanceSummaryInfo() {
      let req = {
        clazzId: this.$store.state.clazz.clazzId,
        attendanceStartTime:
          this.date + (this.morning_afternoon == 0 ? " 06:00:00" : " 13:30:00"),
        attendanceEndTime:
          this.date + (this.morning_afternoon == 0 ? " 07:40:00" : " 14:40:00"),
        lateStartTime:
          this.date + (this.morning_afternoon == 0 ? " 07:40:01" : " 14:40:01"),
      };
      attendanceSummaryInfo(req).then((res) => {
        if (res.data.code == 1) {
          this.summaryInfo = res.data.data;
          console.log("this.summaryInfo", this.summaryInfo);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 选择日期
    onSelectDate(item) {
      this.date = item.text;
      this.showPopover = false;
      this.attendanceSummaryInfo();
    },

    // 选择上下午
    onSelectMorning_afternoon(name) {
      console.log(name);
      this.morning_afternoon = name;
      this.attendanceSummaryInfo();
    },

    // 切换类型
    changeSummaryType(index) {
      this.summaryType = index;
    },

    // 跳转到消息详情
    jumpToDetail(item) {
      if (item.recordId != undefined && this.summaryType != 4) {
        this.$router.push(
          "/tabMessage/messageDetail/inout?recordId=" + item.recordId
        );
      } else if (item.recordId != undefined && this.summaryType == 4) {
        // 请假详情
        this.$router.push(
          "/leaveApplicationRecord/leaveApplicationDetail?recessRecordId=" +
            item.recordId
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    z-index: 10;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .background_img {
    width: 100%;
    height: 300px;
    background: url("../assets/tabmeicon/背景.png") no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: 0;
  }
  .time {
    width: calc(100% - 80px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    z-index: 1;
    .date {
      font-size: 20px;
      color: white;
    }
    .morning_afternoon {
      width: 50%;
      // border: 1px solid white;
      border-radius: 100px;
      ::v-deep .van-tabs__wrap {
        border-radius: 10px;
      }
      ::v-deep .van-tabs__nav {
        margin: 0;
      }
      ::v-deep .van-tab.van-tab--active {
        background-color: white;
        color: #09c3b1;
      }
      ::v-deep .van-tab {
        background-color: #09c3b1;
        color: white;
      }
    }
  }
  .summary_type {
    max-width: 280px;
    width: calc(100% - 40px);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 60px;
    .item {
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: bold;
      .value {
        font-size: 25px;
        margin-bottom: 5px;
      }
      &.item_active {
        color: #f8884b;
      }
    }
  }
  table,
  td,
  tr,
  th {
    border: 0.5px solid grey;
    border-collapse: collapse;
    font-size: 13px;
  }
  td {
    text-align: center;
  }
  .table {
    width: calc(100% - 50px);
    margin-top: 100px;
    .title {
      background-color: rgb(214, 214, 214);
    }
    .value_detail {
      color: rgb(0, 151, 238);
    }
  }
}
</style>
