<template>
  <!-- <div :class="{main:true,main_after:this.showLogin == false}"> -->
  <div :class="{ main: true }">
    <div class="box">
      <van-form class="form" :model="loginForm" ref="loginForm" :rules="rules">
        <!-- <div class="title">请登录粒子微校</div> -->
        <div class="title">请登录智慧校园平台</div>
        <!-- 大图 -->
        <div class="img_box">
          <img class="img" src="../assets/login.png" width="300" alt="" />
        </div>

        <!-- 输入框 -->
        <van-cell-group class="input_box">
          <van-field
            class="input"
            v-model="loginForm.account"
            left-icon="manager-o"
            required
            placeholder="请输入用户名"
            :rules="[{ required: true, message: '请输入用户名' }]"
          />
        </van-cell-group>
        <van-cell-group class="input_box">
          <van-field
            class="input"
            v-model="loginForm.password"
            left-icon="shield-o"
            required
            placeholder="请输入密码"
            type="password"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        </van-cell-group>
        <div class="forget_password" @click="show = !show">忘记密码</div>
        <!-- 登录按钮 -->
        <!-- <div class="btn_box" v-show="this.showLogin"> -->
        <!-- <van-cell-group> -->
        <van-button
          class="login"
          color="#FFFFFF"
          round
          type="info"
          @click="login()"
        >
          登录
        </van-button>
        <!-- </van-cell-group> -->
        <!-- </div> -->
      </van-form>
    </div>

    <!-- 忘记密码弹窗 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block">
          <div class="title">
            <van-icon name="checked" color="green" /> 遗忘密码请联系学校管理员
          </div>
          <div class="detail">
            请致电学校管理员，提供绑定的手机号码、监护人姓名、学生姓名。由管理员为您进行密码重置。
          </div>
          <van-button class="submit" color="#09c3b1" @click="show = false"
            >确定</van-button
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { login } from "@/api/api";
// 引入工具函数
import { checkAccount, checkPassword } from "@/utils/tools";

export default {
  data() {
    return {
      // account: "",
      // password: "",
      active: 0,
      bigNum1: [1, 2, 3],
      bigNum2: [2, 3, 5, 3],
      // 登录按钮
      showLogin: true,
      docmHeight:
        document.documentElement.clientHeight || document.body.clientHeight,
      showHeight:
        document.documentElement.clientHeight || document.body.clientHeight,
      hideshow: true, //显示或者隐藏footer
      // 忘记密码弹窗
      show: false,
      loginForm: {
        account: "",
        password: "",
      },

      // 验证规则
      rules: {
        account: [
          {
            required: true,
            validator: checkAccount,
            reg: /^[\u4E00-\u9FA5a-zA-Z0-9]{3,12}$/,
            is_check: true,
            trigger: "blur",
          },
        ],
        password: [
          { required: true, validator: checkPassword, trigger: "blur" },
        ],
      },

      formRules: [
        {
          // require,
        },
      ],
    };
  },
  watch: {
    // 监听显示高度
    // showHeight:function() {
    //   if(this.docmHeight > this.showHeight){
    //     //隐藏
    //     this.showLogin = false
    //     // document.getElementsByClassName("main").style.height = "100%"
    //   }else{
    //     //显示
    //     this.showLogin = true
    //     // document.getElementsByClassName("main").style.height = "calc(100vh - 50px)"
    //   }
    //   console.log("showLogin",this.showLogin);
    // }
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  mounted() {
    //监听事件
    // window.onresize = ()=>{
    //   console.log("视窗变化");
    //   return(()=>{
    //       this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //   })()
    // }
    // 键盘调起
    // var wHeight = window.innerHeight;   //获取初始可视窗口高度
    // console.log("初始可视窗口高度wHeight",wHeight);
    // window.addEventListener('resize', function(){       //监测窗口大小的变化事件
    // console.log("视窗变化");
    // var hh = window.innerHeight;     //当前可视窗口高度
    // console.log("当前可视窗口高度",hh);
    // var viewTop = $(window).scrollTop();   //可视窗口高度顶部距离网页顶部的距离
    // if(wHeight > hh){           //可以作为虚拟键盘弹出事件
    //     console.log("键盘弹出");
    //     document.getElementsByClassName(".main").animate({scrollTop:viewTop + 300});    //调整可视页面的位置
    // }else{         //可以作为虚拟键盘关闭事件
    //     document.getElementsByClassName(".main").animate({scrollTop:viewTop - 300});
    // }
    // wHeight = hh;
    // })
  },
  methods: {
    login() {
      let schoolId = localStorage.getItem("schoolId");
      console.log("学校DI", schoolId);
      /**
       * 正常情况下 this.$store.state 存储，但是页面刷新的时候，this.$store.state 的数据就会丢失，这个时候就从sessionStorage获取
       */
      let userInfo = "";
      if (this.$store.state.userInfo) {
        userInfo = JSON.parse(this.$store.state.userInfo);
      } else {
        userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      }
      this.$refs.loginForm
        .validate()
        .then(() => {
          let req = {
            accountNumber: this.loginForm.account,
            inputPassword: this.loginForm.password,
            updateOpenid: true,
            schoolId: schoolId,
            userInfo: userInfo,
            // userInfo: JSON.parse(localStorage.getItem("userInfo"))
          };
          console.log("req", req);
          this.submit(req);
        })
        .catch(() => {
          // this.$toast.fail("提交失败");
          console.log("验证不通过");
        });
    },
    submit(req) {
      login(req).then((res) => {
        if (res.data.code == 1001 || res.data.code == 1002) {
          console.log(res.data.data);
          // 家长
          if (res.data.code == 1001) {
            this.$store.commit("setType", 2);
            this.$store.commit("setGuardianId", res.data.data.guardianId);
            this.$store.commit("setGuardianName", res.data.data.guardianName);
            this.$store.commit(
              "setChildren",
              res.data.data.studentIdAndNameVoList
            );
            // 学生姓名有可能不存在
            if (res.data.data.studentIdAndNameVoList.length > 0) {
              this.$store.commit("setChild", {
                text: res.data.data.studentIdAndNameVoList[0].studentName,
                studentId: res.data.data.studentIdAndNameVoList[0].studentId,
              });
            }
          }
          // 老师
          else {
            this.$store.commit("setType", 1);
            this.$store.commit("setTeacherId", res.data.data.teacherId);
            this.$store.commit("setTeacherName", res.data.data.teacherName);
            this.$store.commit(
              "setClasses",
              res.data.data.selectTeacherGradeClazzVoList
            );
            this.$store.commit("setClazz", {
              text: res.data.data.selectTeacherGradeClazzVoList[0]
                .gradeClazzName,
              clazzId:
                res.data.data.selectTeacherGradeClazzVoList[0].gradeClazzId,
            });
          }
          this.$store.commit("setHeadImgUrl", res.data.data.headImgUrl);
          this.$store.commit("setOpenid", res.data.data.openid);
          localStorage.setItem("token", res.data.data.token);
          this.$router.replace(
            "/?type=" + this.$route.query.type + "&id=" + this.$route.query.id
          );
        } else {
          console.log(res.data.message);
          this.$toast(res.data.message);
          // this.$toast("账号或密码错误！");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  // height: calc(100% - 50px);
  min-height: calc(100% - 50px);
  background-color: #09c3b1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form {
      text-align: center;

      .title {
        color: white;
        font-size: 20px;
        margin-bottom: 20px;
        // padding-top: 10px;
        padding-top: 10px;
        text-align: center;
      }
      .img_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        .img {
          width: 50%;
        }
      }
      .input_box {
        margin: 0 auto;
        width: 70%;
        margin-bottom: 20px;
        border-radius: 10px;
        .input {
          border-radius: 10px;
        }
      }
      ::v-deep .van-hairline--top-bottom::after {
        display: none !important;
      }
      .forget_password {
        width: calc(70% + 50px);
        display: flex;
        justify-content: flex-end;
        color: white;
      }
      .login {
        text-align: center;
        margin: 0 auto;
        width: 190px;
        height: 45px;
        margin-top: 10px;
        ::v-deep .van-button__content {
          color: black;
          font-size: 16px;
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .submit {
      width: 50%;
    }
  }
  .block {
    width: 70%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    .title {
      font-size: 17px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .detail {
      margin-bottom: 20px;
    }
  }
}
</style>
