<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
      @click-right="binarycode"
    >
      <template #right v-if="this.type == 2">
        <img src="../assets/binarycod.png" width="25px" alt="" />
      </template>
    </van-nav-bar>
    <div class="background_img"></div>
    <div class="head_box">
      <div
        v-if="this.type == 1 && this.teacherInfo.teacherName"
        :class="{ head_img: true, head_img_teacher: this.type == 1 }"
      >
        {{
          this.teacherInfo.teacherName.slice(
            this.teacherInfo.teacherName.length - 2,
            this.teacherInfo.teacherName.length
          )
        }}
      </div>
      <div
        v-if="this.type == 2 && this.guardianInfo.guardianName"
        :class="{ head_img: true, head_img_guardian: this.type == 2 }"
      >
        {{
          this.guardianInfo.guardianName.slice(
            this.guardianInfo.guardianName.length - 2,
            this.guardianInfo.guardianName.length
          )
        }}
      </div>
      <div class="name_job">
        <div class="name">
          {{
            this.type == 1
              ? this.teacherInfo.teacherName
              : this.guardianInfo.guardianName
          }}
        </div>
        <div class="job">
          {{
            this.type == 1
              ? this.teacherInfo.teacherRoleName
              : this.guardianInfo.guardianTypeName
          }}
        </div>
      </div>
    </div>
    <!-- 教师详情 -->
    <div class="detail" v-if="this.type == 1">
      <div class="item">
        <div class="title">职位</div>
        <div class="value">{{ this.teacherInfo.teacherRoleName }}</div>
      </div>
      <div class="item">
        <div class="title">联系电话</div>
        <div class="value">{{ this.teacherInfo.teacherPhone }}</div>
      </div>
      <div class="item">
        <div class="title">邮箱</div>
        <div class="value">{{ this.teacherInfo.teacherEmail }}</div>
      </div>
      <!-- <div class="item_describe">
        <div class="title">老师简介</div>
        <div class="value">
          简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介
        </div>
      </div> -->
    </div>
    <!-- 家长详情 -->
    <div class="detail" v-if="this.type == 2">
      <div class="item">
        <div class="title">关系</div>
        <div class="value">{{ this.guardianInfo.guardianTypeName }}</div>
      </div>
      <div class="item">
        <div class="title">接送卡</div>
        <div class="value">{{ this.guardianInfo.guardianCardListStr }}</div>
      </div>
      <div class="item">
        <div class="title">电话</div>
        <div class="value">{{ this.guardianInfo.guardianPhone }}</div>
      </div>
    </div>

    <!-- 学生详情 -->
    <div class="detail" v-if="this.type == 3">
      <div class="item">
        <div class="title">学生姓名</div>
        <div class="value">{{ this.studentInfo.studentName }}</div>
      </div>
      <div class="item">
        <div class="title">学生学号</div>
        <div class="value">{{ this.studentInfo.studentNumber }}</div>
      </div>
      <div class="item">
        <div class="title">学生班级</div>
        <div class="value">{{ this.studentInfo.gradeClazz }}</div>
      </div>
      <div class="item">
        <div class="title">目前照片</div>
        <div class="img_box">
          <img
            class="img"
            style="width: 200px"
            :src="this.studentInfo.photoUrl"
            alt=""
          />
        </div>
      </div>
      <!--<div style="margin: 0 auto; text-align: center; margin-bottom: 20px">-->
      <van-cell-group style="margin-top: 20px">
        <UploadImages :father_data="studentImg" @goto="confirmMask" />
      </van-cell-group>
      <van-button
        type="primary"
        @click="changePhoto"
        :disabled="studentImg.photo_url == '' || studentImg.photo_url == null"
        style="margin-top: 10px"
        >修改照片</van-button
      >
      <!--</div>-->
      <!--<div style="margin: 0 auto; text-align: center; margin-bottom: 20px">-->
      <!--  <van-cell-group style="margin-top: 20px">-->
      <!--    <UploadImages :father_data="studentImg" @goto="confirmMask" />-->
      <!--  </van-cell-group>-->
      <!--  <van-button-->
      <!--      type="primary"-->
      <!--      @click="changePhoto"-->
      <!--      style="margin-top: 10px"-->
      <!--      :disabled="studentImg.photo_url == '' || studentImg.photo_url == null"-->
      <!--  >修改照片</van-button-->
      <!--  >-->
      <!--</div>-->

      <!-- 下部按钮 -->
      <!-- <div class="button" @click="jump(1)">
        <div class="title">
          <img class="icon" src="../assets/tabmeicon/2.png" alt="" />
          <div v-if="this.type == 2">班级老师</div>
          <div v-else>班级学生</div>
        </div>
        <div class="arrow">
          <van-icon name="arrow" />
        </div>
      </div> -->

      <!--<div class="item_guar">-->
      <!--  <div class="title">监护人</div>-->
      <!--  <div-->
      <!--    class="sub_item"-->
      <!--    v-for="(item, index) in this.studentInfo-->
      <!--      .teacherSelectGuardianInfoVoList"-->
      <!--    :key="index"-->
      <!--  >-->
      <!--    <div class="sub_title">-->
      <!--      {{ item.guardianName }}-->
      <!--    </div>-->
      <!--    <div-->
      <!--      class="sub_value"-->
      <!--      @click="phoneConfirmDialog(item.guardianPhone)"-->
      <!--    >-->
      <!--      手机号：{{ item.guardianPhone }}-->
      <!--      <van-icon class="icon" name="phone" />-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</div>-->
    </div>
    <!-- 下方按钮 -->
    <div class="btn" v-if="this.type != 3">
      <van-button
        class="left"
        v-if="this.type == 1"
        color="#bf2f2f"
        round
        type="info"
        @click="phoneConfirmDialog(teacherInfo.teacherPhone)"
      >
        联系老师
      </van-button>
      <!-- <van-button class="left" v-if="this.type == 2" color="#bf2f2f" round type="info">
        挂失接送卡
      </van-button> -->
      <van-button class="right" round type="info" @click="backToHomepage()"
        >返回首页</van-button
      >
    </div>
  </div>
</template>

<script>
import { updateStudentPhoto } from "@/api/api";
import { getStudentConnectDetail } from "@/api/teacher_api";
import { reqGetGuardianCardByStudentIdAndGuardianId } from "@/api/guardianCard_api";
import {} from "@/api/guardian_api";
import { Dialog } from "vant";
import UploadImages from "@/components/UploadImages.vue";

export default {
  data() {
    return {
      // 类型 1为教师详情 2是家长详情 3是学生详情
      type: "2",
      // 教师详情
      teacherInfo: {},
      // 家长详情
      guardianInfo: {
        guardianId: "",
        guardianName: "",
        guardianTypeName: "",
        guardianPhone: "",
        guardianCardListStr: "",
      },
      // 学生id
      studentId: "",
      // 学生详细信息
      studentInfo: {},
      studentImg: {
        file_list: [],
        photo_url: "",
        max_count: 1,
        label: "学生照片",
        errorMessage: "",
      },
    };
  },
  components: {
    UploadImages,
  },
  created() {
    this.type = this.$route.params.type;
    this.studentId = this.$route.query.studentId;
    if (this.type == 1) {
      // 监护人端看教师详情
      this.teacherInfo.teacherName = this.$route.query.teacherName;
      this.teacherInfo.teacherPhone = this.$route.query.teacherPhone;
      this.teacherInfo.teacherRoleName = this.$route.query.teacherRoleName;
      this.teacherInfo.teacherEmail = this.$route.query.teacherEmail;
    } else if (this.type == 2) {
      // 监护人端看监护人详情
      this.guardianInfo.guardianId = this.$route.query.guardianId;
      this.guardianInfo.guardianName = this.$route.query.guardianName;
      this.guardianInfo.guardianTypeName = this.$route.query.guardianTypeName;
      this.guardianInfo.guardianPhone = this.$route.query.guardianPhone;
      this.getGuardianCardByStudentIdAndGuardianId();
    } else {
      // 教师端查询本班某位学生详细信息 (通讯录详情)
      this.getStudentConnectDetail();
    }
    this.$forceUpdate();
  },

  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 返回首页
    backToHomepage() {
      this.$router.push("/");
    },
    async getGuardianCardByStudentIdAndGuardianId() {
      console.log("小孩id", this.$store.state.child.studentId);
      await reqGetGuardianCardByStudentIdAndGuardianId({
        studentId: this.$store.state.child.studentId,
        guardianId: this.guardianInfo.guardianId,
      })
        .then((res) => {
          //用''/隔开给guardianCardListStr'
          this.guardianInfo.guardianCardListStr = res.data.data.join("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 教师端查询本班某位学生详细信息 (通讯录详情)
    getStudentConnectDetail() {
      let req = {
        studentId: this.studentId,
      };
      getStudentConnectDetail(req).then((res) => {
        if (res.data.code == 1) {
          this.studentInfo = res.data.data;
          console.log("this.studentInfo", this.studentInfo);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 拨打电话提示弹窗
    phoneConfirmDialog(phone) {
      Dialog.confirm({
        title: "拨打电话",
        message: "是否确认拨打" + phone + "?",
      })
        .then(() => {
          //  动态创建a标签拨打电话
          const a = document.createElement("a");
          a.href = "tel:" + phone;
          a.click();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 修改照片
    changePhoto() {
      let userId = "";
      // 判断是否有新增或修改照片
      if (!this.studentImg.photo_url) {
        this.$toast.loading({
          message: "请先上传照片...",
          forbidClick: true, // 通过forbidClick属性可以禁用背景点击
        });
        return false;
      }
      if (this.type == 1) {
        // 账号登录的是教师身份
        userId = this.$store.state.teacherId;
      } else if (this.type == 2) {
        // 账号登录的是家长身份
        userId = this.$store.state.guardianId;
      } else {
        // 其他可能 这里暂不处理
      }
      console.log("00000000000");
      console.log(this.teacherInfo);
      console.log(this.$store.state.teacherId);
      console.log(this.$store.state.setTeacherId);

      updateStudentPhoto({
        studentId: this.$route.query.studentId, // 学生的id
        userId: userId, // 教师或者家长的id
        photoUrl: this.studentImg.photo_url, // 需要替换的照片
      }).then((res) => {
        this.$toast(res.data.message);
        this.$router.push("/");
        // console.log(res.data);
      });
      console.log(this.studentImg);
    },

    confirmMask(fileList) {
      // this.zhufangrenzhaopian.errorMessage = "";
      // this.vreserveForm.v_fangyizhaopian = fileList;
    },

    // 点击二维码
    binarycode() {},
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .background_img {
    width: 100%;
    height: 180px;
    background: url("../assets/tabmeicon/背景.png") no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 30px;
    z-index: 0;
  }
  .head_box {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    margin-top: 70px;
    z-index: 1;
    .head_img {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 50px;
      border-radius: 50%;
      font-size: 19px;
      text-indent: 7px;
      letter-spacing: 7px;
      font-weight: bold;
      color: white;
      &.head_img_teacher {
        background-image: linear-gradient(rgb(255, 142, 49), rgb(243, 115, 10));
      }
      &.head_img_guardian {
        background-image: linear-gradient(rgb(77, 148, 255), rgb(21, 103, 226));
      }
    }
    .name_job {
      color: white;
      .name {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .job {
        font-size: 14px;
      }
    }
  }
  .detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    z-index: 1;
    .item {
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px 12px 15px;
      margin-bottom: 15px;
      font-size: 14px;
      border-radius: 5px;
      background-color: white;
      .value {
        color: #adadad;
      }
    }
    .item_guar {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px 15px 12px 15px;
      margin-bottom: 15px;
      font-size: 14px;
      border-radius: 5px;
      background-color: white;
      .value {
        color: #adadad;
      }
      .title {
        font-weight: bold;
      }
      .sub_item {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        color: #adadad;
        .icon {
          color: #09d0b2;
          margin-left: 10px;
        }
      }
    }
    .item_describe {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px 15px 12px 15px;
      margin-bottom: 15px;
      font-size: 14px;
      border-radius: 5px;
      background-color: white;
      .title {
        margin-bottom: 15px;
      }
      .value {
        color: #adadad;
        flex-wrap: wrap;
      }
    }
  }
  .btn {
    margin-top: 30px;
    .left {
      height: 30px;
      width: 120px;
      margin-right: 30px;
    }
    .right {
      height: 30px;
      width: 120px;
    }
  }
}
</style>
