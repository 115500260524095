import request from "../utils/request.js";

export function reqNewStudentRegister(data) {
  return request({
    url: "student/new/register",
    method: "post",
    data: data,
  });
}
// export function reqGetMessagePermissionExpireTime(params) {
//   return request({
//     url: "student/messagePermission/expireTime",
//     method: "get",
//     params,
//   });
// }

export function reqGetAllStudent() {
  return request({
    url: "student/all",
    method: "get",
  });
}
