<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="消息通知"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- 标签分页 -->
    <div class="tabs_box">
      <!-- 选择时间 -->
      <div class="selectTime" @click="datePickerShow = true">
        <van-icon name="clock-o" style="margin-right: 5px" />{{
          this.pickDateDisplay
        }}
      </div>
      <van-tabs
        class="tabs"
        :active="active"
        swipeable
        @change="onChangeActive"
      >
        <!-- 进出校提醒 -->
        <van-tab class="pick_up" title="进/出校提醒" name="1">
          <!-- 未读/已读 -->
          <div
            :class="{ search_read: true, search_read_guardian: this.type == 2 }"
          >
            <div class="search" v-if="this.type == 1">
              <van-field
                v-model="searchKeyInout"
                label=""
                left-icon="search"
                placeholder="搜索学生姓名"
              />
            </div>
            <div class="read_unread">
              <div
                :class="[read == 2 ? 'unread_active' : 'unread']"
                @click="changeRead(2)"
              >
                全部
              </div>
              <div
                :class="[read == 0 ? 'unread_active' : 'unread']"
                @click="changeRead(0)"
              >
                未读
              </div>
              <div
                :class="[read == 1 ? 'read_active' : 'read']"
                @click="changeRead(1)"
              >
                已读
              </div>
            </div>
          </div>
          <!-- 消息卡片 -->
          <van-list
            v-if="this.inoutInfo.data.length > 0"
            class="messages"
            v-model="loading[active]"
            :finished="finished[active]"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="message"
              @click="jumpToDetail('inout', item)"
              v-for="(item, index) in this.inoutInfo.data"
              :key="index"
            >
              <div class="message_box" v-if="read == item.read || read == 2">
                <div class="message_top">
                  <div class="left">
                    <div class="title">
                      {{ item.accessDirection == 1 ? "出校" : "入校" }}提醒
                    </div>
                    <div class="time">{{ item.accessTime }}</div>
                  </div>
                  <div
                    :class="{ read: item.read == 1, unread: item.read == 0 }"
                  >
                    {{ item.read == 1 ? "已读" : "未读" }}
                  </div>
                </div>
                <div class="message_main">
                  <div class="detail">
                    <div class="guardian">学生姓名：{{ item.studentName }}</div>
                    <!-- <div>学生卡号：{{ item.accessInfo }}</div> -->
                  </div>
                  <div class="img_box">
                    <img class="img" :src="item.photoUrl" data-set: alt="" />
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <!-- 接送提醒 -->
        <van-tab class="pick_up" title="接送提醒" name="0">
          <!-- 未读/已读 -->
          <div
            :class="{ search_read: true, search_read_guardian: this.type == 2 }"
          >
            <div class="search" v-if="this.type == 1">
              <van-field
                v-model.lazy="searchKeyPickup"
                label=""
                left-icon="search"
                placeholder="搜索学生姓名"
              />
            </div>
            <div class="read_unread">
              <div
                :class="[read == 2 ? 'unread_active' : 'unread']"
                @click="changeRead(2)"
              >
                全部
              </div>
              <div
                :class="[read == 0 ? 'unread_active' : 'unread']"
                @click="changeRead(0)"
              >
                未读
              </div>
              <div
                :class="[read == 1 ? 'read_active' : 'read']"
                @click="changeRead(1)"
              >
                已读
              </div>
            </div>
          </div>
          <!-- 消息卡片 -->
          <van-list
            v-if="this.pickupInfo.data.length > 0"
            class="messages"
            v-model="loading[active]"
            :finished="finished[active]"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="message"
              @click="jumpToDetail('pickup', item)"
              v-for="(item, index) in this.pickupInfo.data"
              :key="index"
            >
              <div class="message_box" v-if="read == item.read || read == 2">
                <div class="message_top">
                  <div class="left">
                    <div class="title">接送提醒</div>
                    <div class="time">{{ item.broadcastTime }}</div>
                  </div>
                  <div
                    :class="{ read: item.read == 1, unread: item.read == 0 }"
                  >
                    {{ item.read == 1 ? "已读" : "未读" }}
                  </div>
                </div>
                <div class="message_main">
                  <div class="detail">
                    <div class="guardian">监护人:{{ item.guardianName }}</div>
                    <div>学生姓名:{{ item.studentName }}</div>
                  </div>
                  <div class="img_box">
                    <img class="img" :src="item.photoUrl" alt="" />
                    <!-- <img class="img" src="../assets/message_img.jpg" alt="" /> -->
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <!-- 异常提醒 -->
        <!-- <van-tab class="abnormals" title="异常提醒" name="2">
          <div :class="{search_read:true,search_read_guardian: this.type == 2}">
            <div class="search" v-if="this.type == 1">
              <van-field v-model="search" label="" left-icon="search" placeholder="搜索学生姓名" />
            </div>
            <div class="read_unread">
              <div
                :class="[read == false ? 'unread_active' : 'unread']"
                @click="changeRead(false)"
              >
                未读
              </div>
              <div
                :class="[read == true ? 'read_active' : 'read']"
                @click="changeRead(true)"
              >
                已读
              </div>
            </div>
          </div>
          <div class="abnormal" @click="jumpToDetail('inout')">
            <div class="left">
              早退
            </div>
            <div class="right">
              <span>姓名:{{"张三"}}</span>
              <span>{{"提前离校"}}</span>
              <span class="time">{{"2021-02-15 17:30"}}</span>
            </div>
          </div>
          <div class="abnormal" @click="openNotice(1)">
            <div class="left">
              无刷卡
            </div>
            <div class="right">
              <span>姓名:{{"张三"}}</span>
              <span>{{"未刷卡"}}</span>
              <span class="time">{{"2021-02-15 17:30"}}</span>
            </div>
          </div>
          <div class="abnormal" @click="openNotice(2)">
            <div class="left">
              未到
            </div>
            <div class="right">
              <span>姓名:{{"张三"}}</span>
              <span>{{"未到校"}}</span>
              <span class="time">{{"2021-02-15 17:30"}}</span>
            </div>
          </div>
        </van-tab> -->
      </van-tabs>
    </div>
    <van-action-sheet v-model="datePickerShow" title="">
      <van-datetime-picker
        v-model="pickDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @confirm="onConfirm()"
        @cancel="datePickerShow = false"
      />
    </van-action-sheet>

    <!-- 未到和未刷卡提醒弹窗 -->
    <!-- <van-overlay :show="noticeShow" @click="noticeShow = false">
      <div class="wrapper">
        <div class="block">
          <van-icon name="warning-o" />
          <div class="text">
            {{this.unnormalType == 1 ? "超过90分钟未刷接送卡":"本学生今日未到校且未提交请假申请"}}
          </div>
        </div>
      </div>
    </van-overlay> -->
  </div>
</template>
<script>
import { getPickupRecords, getInoutRecords } from "../api/teacher_api";
import { getPickupRecordGuar, getInoutRecordGuar } from "@/api/guardian_api";
import { getUnreadCount } from "@/api/api";
import { Dialog } from "vant";
export default {
  name: "tabMessage",
  data() {
    return {
      // 类型 1为教师端 2是家长端
      type: "",
      // 无限加载
      loading: [false, false],
      finished: [false, false],
      // 接送记录信息
      pickupInfo: { page: 0, data: [], size: 5, total: 0, totalPage: 1 },
      // 进出记录信息
      inoutInfo: { page: 0, data: [], size: 5, total: 0, totalPage: 1 },
      // 搜索关键字
      searchKeyPickup: "",
      searchKeyInout: "",
      // 标签栏选中  0为接送 1为进出
      active: 0,
      // 未读为0 已读则为1 全部为2
      read: 2,
      // 选择时间弹窗
      datePickerShow: false,
      // 选择的时间
      pickDate: new Date(),
      pickDateDisplay:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
      maxDate: new Date(),
      // 未到和无刷卡提醒
      // noticeShow: false,
      // type = 1 无刷卡  type = 2 未到
      unnormalType: 1,
      studentId: "", // 学生id
    };
  },
  watch: {
    // 加防抖
    searchKeyPickup(val) {
      // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
      this.finished = [false, false];
      this.pickupInfo = { page: 0, data: [], size: 5, total: 0, totalPage: 1 };
    },
    searchKeyInout(val) {
      // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
      this.finished = [false, false];
      this.inoutInfo = { page: 0, data: [], size: 5, total: 0, totalPage: 1 };
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "messageDetail") {
      from.meta.keepAlive = true;
      console.log("去详情", from.meta.keepAlive);
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
  created() {
    this.getUnreadCount();
    console.log("created生命周期");
    this.pickupInfo.page = 0;
    this.type = this.$store.state.type;
    console.log("首次打开", this.$route.meta.reload);
    // 标签栏选中 获取选中的标签序号
    /**
     * @date 2023-08-30
     * 从底部的导航点击消息时，需要指定active的值
     */
    this.active =
      this.$route.query.active == undefined ? 1 : this.$route.query.active;
    /**
     * 把studentId存到sessionStorage中，页面刷新时取出
     */
    this.studentId = this.$store.state.child.studentId;
    console.log(" this.studentId" + this.studentId);
    // sessionStorage.setItem("studentId", this.studentId);
    localStorage.setItem("studentId", this.studentId);
    // // 获取当前角色未读消息数量
  },
  mounted() {
    this.active =
      this.$route.query.active == undefined ? 1 : this.$route.query.active;
    // 取出studentId
    this.studentId = localStorage.getItem("studentId");
    console.log(this.studentId);
    console.log(this.active);
    console.log("调用无限加载");
    // 教师端
    if (this.type == 1) {
      // 接送
      if (this.active == 0) {
        // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
        this.getPickupRecords();
      }
      // 进出
      else {
        // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
        this.getInoutRecords();
      }
    }
    // 家长端
    else {
      // 接送
      if (this.active == 0) {
        // 监护人端查询某个宝贝所有接送记录
        this.getPickupRecordGuar();
      }
      // 进出
      else {
        // 监护人端查询某个宝贝所有进出记录
        this.getInoutRecordGuar();
      }
    }
  },

  methods: {
    getUnreadCount() {
      // this.$toast("获取未读消息数量");
      getUnreadCount().then((res) => {
        if (res.data.code == 1) {
          // console.log("数量捏：" + res.data.data);
          this.$store.commit("setUnreadCount", res.data.data);
          // this.$toast("未读消息数量:" + res.data.data);
        } else {
          this.$toast("获取失败:", res.data);

          this.$toast(res.data.message);
        }
      });
    },
    //  获取数据区域
    onLoad() {
      this.getUnreadCount();
      console.log("调用无限加载");
      // 教师端
      if (this.type == 1) {
        // 接送
        if (this.active == 0) {
          // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
          this.getPickupRecords();
        }
        // 进出
        else {
          // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
          this.getInoutRecords();
        }
      }
      // 家长端
      else {
        // 接送
        if (this.active == 0) {
          // 监护人端查询某个宝贝所有接送记录
          this.getPickupRecordGuar();
        }
        // 进出
        else {
          // 监护人端查询某个宝贝所有进出记录
          this.getInoutRecordGuar();
        }
      }
    },

    //  监护人区域
    // 监护人端查询某个宝贝所有接送记录
    getPickupRecordGuar() {
      let req = {
        studentId: this.studentId,
        startTime: this.pickDateDisplay + " 00:00:00",
        endTime: this.pickDateDisplay + " 23:59:59",
        page: ++this.pickupInfo.page,
        size: 5,
        readStatus: this.read,
      };
      getPickupRecordGuar(req).then((res) => {
        if (res.data.code == 1) {
          this.pickupInfo.data = this.pickupInfo.data.concat(
            res.data.data.data
          );
          this.pickupInfo.page = res.data.data.page;
          this.pickupInfo.total = res.data.data.total;
          this.pickupInfo.totalPage = res.data.data.totalPage;
          if (
            this.pickupInfo.page > this.pickupInfo.totalPage ||
            this.pickupInfo.page == this.pickupInfo.totalPage
          ) {
            this.finished[this.active] = true;
          }
          this.loading[this.active] = false;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 监护人端查询某个宝贝所有进出记录
    getInoutRecordGuar() {
      console.log("监护人端查询某个宝贝所有进出记录");
      let req = {
        studentId: this.studentId,
        startTime: this.pickDateDisplay + " 00:00:00",
        endTime: this.pickDateDisplay + " 23:59:59",
        page: ++this.inoutInfo.page,
        size: 5,
        readStatus: this.read,
      };
      getInoutRecordGuar(req).then((res) => {
        if (res.data.code == 1) {
          this.inoutInfo.data = this.inoutInfo.data.concat(res.data.data.data);
          this.inoutInfo.page = res.data.data.page;
          this.inoutInfo.total = res.data.data.total;
          this.inoutInfo.totalPage = res.data.data.totalPage;
          console.log("this.inoutInfo", this.inoutInfo);
          if (
            this.inoutInfo.page == this.inoutInfo.totalPage ||
            this.inoutInfo.page > this.inoutInfo.totalPage
          ) {
            this.finished[this.active] = true;
          }
          this.loading[this.active] = false;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 教师区域
    // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
    getPickupRecords() {
      console.log("过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)");
      let req = {
        studentId: this.studentId,
        clazzId: this.$store.state.clazz.clazzId,
        startTime: this.pickDateDisplay + " 00:00:00",
        endTime: this.pickDateDisplay + " 23:59:59",
        studentNameKeyword: this.searchKeyPickup,
        readStatus: this.read,
        // page: ++this.pickupInfo.page,
        page: 1,
        size: 15,
      };
      getPickupRecords(req).then((res) => {
        if (res.data.code == 1) {
          this.pickupInfo.data = res.data.data.data;
          this.pickupInfo.page = res.data.data.page;
          this.pickupInfo.total = res.data.data.total;
          this.pickupInfo.totalPage = res.data.data.totalPage;
          console.log("this.pickupInfo", this.pickupInfo);
          if (this.pickupInfo.page >= this.pickupInfo.totalPage) {
            this.finished[this.active] = true;
          }
          this.loading[this.active] = false;
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
    getInoutRecords() {
      let req = {
        studentId: this.studentId,
        clazzId: this.$store.state.clazz.clazzId,
        startTime: this.pickDateDisplay + " 00:00:00",
        endTime: this.pickDateDisplay + " 23:59:59",
        studentNameKeyword: this.searchKeyInout,
        page: 1,
        size: 5,
        readStatus: this.read,
      };
      getInoutRecords(req).then((res) => {
        if (res.data.code == 1) {
          this.inoutInfo.data = this.inoutInfo.data.concat(res.data.data.data);
          this.inoutInfo.page = res.data.data.page;
          this.inoutInfo.total = res.data.data.total;
          this.inoutInfo.totalPage = res.data.data.totalPage;
          console.log("this.inoutInfo", this.inoutInfo);
          if (this.inoutInfo.page >= this.inoutInfo.totalPage) {
            this.finished[this.active] = true;
          }
          this.loading[this.active] = false;
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 通用区域
    // 返回
    back() {
      this.$router.push("/");
    },
    // 防抖
    debounce(func, wait) {
      let timeout;
      return function () {
        let context = this; // 保存this指向
        let args = arguments; // 拿到event对象
        console.log(arguments);
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          func.apply(context, args);
        }, wait);
      };
    },
    // 切换接送记录和进出记录
    onChangeActive(name) {
      this.active = name;
      // 接送
      // if (name == 0) {
      //   // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
      //   console.log("onChangeActive-----获取接送记录---------");
      //   this.getPickupRecords();
      // }
      // // 进出
      // else {
      //   // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
      //   console.log("onChangeActive-----获取进出记录---------");
      //   this.getInoutRecords();
      // }
      if (this.type == 1) {
        // 接送
        if (this.active == 0) {
          // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
          this.getPickupRecords();
        }
        // 进出
        else {
          // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
          this.getInoutRecords();
        }
      }
      // 家长端
      else {
        // 接送
        if (this.active == 0) {
          // 监护人端查询某个宝贝所有接送记录
          this.getPickupRecordGuar();
        }
        // 进出
        else {
          // 监护人端查询某个宝贝所有进出记录
          this.getInoutRecordGuar();
        }
      }
    },
    // 确定选择时间
    onConfirm() {
      this.finished = [false, false];
      this.pickupInfo = { page: 0, data: [], size: 5, total: 0, totalPage: 1 };
      this.inoutInfo = { page: 0, data: [], size: 5, total: 0, totalPage: 1 };
      this.datePickerShow = false;
      this.pickDateDisplay = this.formatDate(this.pickDate);
      console.log(this.pickDateDisplay);
      // 教师端
      if (this.type == 1) {
        if (this.active == 0) {
          // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
          this.getPickupRecords();
        } else {
          // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
          this.getInoutRecords();
        }
      }
      // 家长端
      else {
        if (this.active == 0) {
          // 监护人端查询某个宝贝所有接送记录
          // this.getPickupRecordGuar()
          //这里我刚刚把没Guar改成了Guar，待会有问题再重新弄回来
          this.getPickupRecords();
          // console.log("进来了");
          // this.getInoutRecords()
        } else {
          // 监护人端查询某个宝贝所有进出记录
          this.getInoutRecordGuar();
        }
      }
    },

    // 切换已读未读
    changeRead(flag) {
      // console.log("切换已读未读", flag);
      this.read = flag;
      //todo 重新获取数据。。。 不要这样子玩
      if (this.type == 1) {
        // 接送
        if (this.active == 0) {
          // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
          this.getPickupRecords();
        }
        // 进出
        else {
          // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
          this.getInoutRecords();
        }
      }
      // 家长端
      else {
        // 接送
        if (this.active == 0) {
          // 监护人端查询某个宝贝所有接送记录
          this.getPickupRecordGuar();
        }
        // 进出
        else {
          // 监护人端查询某个宝贝所有进出记录
          this.getInoutRecordGuar();
        }
      }
    },

    // 点击进入详情
    jumpToDetail(messageType, item) {
      if (item.read == 0) {
        item.read = 1;
        this.$store.commit("decreaseUnreadCount");
      }
      if (messageType == "inout") {
        //如果是进出记录，也就是门禁记录
        this.$router.push(
          "/tabMessage/messageDetail/" +
            messageType +
            "?recordId=" +
            item.recordId
        );
      } else {
        this.$router.push(
          //如果是接送提醒也就是班播记录
          "/tabMessage/messageDetail/" +
            messageType +
            "?recordId=" +
            item.broadcastRecordId
        );
      }
      this.getUnreadCount();
    },

    // 修改日期格式
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },

    // onCancel(){
    //   this.pickDate = new Date()
    //   this.pickDateDisplay = "选择时间"
    //   this.datePickerShow = false
    //   // 教师端
    //   if(this.type == 1){
    //     if(name == 0){
    //       if(this.pickupInfo.length == 0){
    //         // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
    //         this.getPickupRecords()
    //       }
    //     }else{
    //       if(this.inoutInfo.length == 0){
    //         // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
    //         this.getInoutRecords()
    //       }
    //     }
    //   }
    //   // 家长端
    //   else{
    //      if(name == 0){
    //       if(this.pickupInfo.length == 0){
    //         // 监护人端查询某个宝贝所有接送记录
    //         this.getPickupRecordGuar()
    //       }
    //     }else{
    //       if(this.inoutInfo.length == 0){
    //         // 监护人端查询某个宝贝所有进出记录
    //         this.getInoutRecordGuar()
    //       }
    //     }
    //   }
    // },
  },
  activated() {
    console.log("activated生命周期");
    this.$forceUpdate();
    // if(this.$route.meta.reload || this.type != this.$store.state.type){
    //   // 获取教师端还是监护人端
    //   this.type = this.$store.state.type
    //   console.log("type="+ this.type);
    //   // 标签栏选中 获取选中的标签序号
    //   this.active = this.$route.query.active == undefined ? 0 : this.$route.query.active;
    //   // 教师端
    //   if(this.type == 1){
    //     // 接送
    //     if(this.active == 0){
    //       // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
    //       this.getPickupRecords()
    //     }
    //     // 进出
    //     else{
    //       // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
    //       this.getInoutRecords()
    //     }
    //   }
    //   // 家长端
    //   else{
    //     // 接送
    //     if(this.active == 0){
    //       // 监护人端查询某个宝贝所有接送记录
    //       this.getPickupRecordGuar()
    //     }
    //     // 进出
    //     else{
    //       // 监护人端查询某个宝贝所有进出记录
    //       this.getInoutRecordGuar()
    //     }
    //   }
    // }
    // this.$route.meta.reload = true
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__nav--card {
  margin: 0px !important;
}
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    z-index: 2;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: white;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .tabs_box {
    width: 100%;
    margin-top: 45px;
    min-height: 80vh;
    position: relative;
    ::v-deep .van-tabs__nav {
      width: 70%;
    }
    ::v-deep .van-tabs__wrap {
      background: white;
    }
    .selectTime {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      right: 15px;
      top: 10px;
      z-index: 1;
      color: gray;
      font-size: 14px;
    }
    .tabs {
      width: 100%;
      min-height: 80vh;
      .pick_up {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        .search_read {
          width: calc(100% - 50px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.search_read_guardian {
            justify-content: flex-end;
          }
          .search {
            width: 60%;
            display: flex;
            align-items: center;
            background: white;
            color: gray;
            border-radius: 5px;
            ::v-deep .van-cell {
              display: flex;
              align-items: center;
              height: 35px;
              padding: 3px 3px 3px 10px;
              border-radius: 10px;
            }
          }
          .read_unread {
            height: 70px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .unread {
              padding-right: 15px;
            }
            .unread_active {
              padding-right: 15px;
              color: #1684fc;
            }
            .read_active {
              color: #1684fc;
            }
          }
        }
        .messages {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .message {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .message_box {
              width: calc(100% - 75px);
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 15px;
              margin-bottom: 15px;
              border-radius: 20px;
              background-color: white;
              .message_top {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 25px;
                .left {
                  display: flex;
                  align-items: center;
                  .title {
                    margin-right: 30px;
                  }
                  .time {
                    font-size: 13px;
                    color: #1684fc;
                  }
                }
                .read {
                  color: grey;
                }
                .unread {
                  color: orange;
                }
              }
              .message_main {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-bottom: 10px;
                .detail {
                  width: calc(100% - 90px);
                  font-size: 14px;
                  padding-right: 5px;
                  .guardian {
                    margin-bottom: 10px;
                  }
                }
                .img_box {
                  width: 90px;
                  .img {
                    width: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }
      .abnormals {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .search_read {
          width: calc(100% - 50px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.search_read_guardian {
            justify-content: flex-end;
          }
          .search {
            width: 65%;
            display: flex;
            align-items: center;
            background: white;
            color: gray;
            border-radius: 5px;
            ::v-deep .van-cell {
              display: flex;
              align-items: center;
              height: 35px;
              padding: 3px 3px 3px 10px;
              border-radius: 10px;
            }
          }
          .read_unread {
            height: 70px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .unread {
              padding-right: 15px;
            }
            .unread_active {
              padding-right: 15px;
              color: #1684fc;
            }
            .read_active {
              color: #1684fc;
            }
          }
        }
        .abnormal {
          width: calc(100% - 50px);
          height: 7s0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 15px;
          border-radius: 5px;
          background-color: #d3e7f7;
          .left {
            width: 100px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            font-size: 17px;
            font-weight: bold;
            color: white;
            border-radius: 5px 0 0 5px;
            background: #09c3b1;
          }
          .right {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 15px 12px 0px;
            font-size: 14px;
            .time {
              display: flex;
              align-items: center;
              text-align: center;
              padding-top: 5px;
            }
            // .title {
            //   padding-bottom: 5px;
            //   color: #f5c92d;
            //   font-weight: bold;
            // }
            // .title_late {
            //   padding-bottom: 5px;
            //   color: #ac0423;
            //   font-weight: bold;
            // }
            // .right_bottom {
            //   display: flex;
            //   align-items: center;
            //   justify-content: space-between;
            //   .location {
            //     font-size: 13px;
            //   }
            //   .time {
            //     font-size: 13px;
            //   }
            // }
          }
        }
      }
    }
  }
  // .wrapper {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 100%;
  //   .block {
  //     width: 250px;
  //     height: 180px;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     background-color: #edeaea;
  //     color: #d41426;
  //     font-size: 70px;
  //     .text{
  //       width: 80%;
  //       text-align: center;
  //       margin-top: 25px;
  //       font-size: 15px;
  //     }

  //   }
  // }
}
</style>
