<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="审批列表"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="tab">
      <van-tabs
        :active="activeTag"
        shrink
        animated
        swipeable
        @change="onChangeState"
      >
        <van-tab
          class="tab_item"
          v-for="(item, index) in tags"
          :key="index"
          :title="item"
        >
          <van-list
            class="records"
            v-model="loading[state]"
            :finished="finished[state]"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <!-- <div class="records"> -->
            <div
              v-for="(item1, index1) in recordInfos[state].data"
              :key="index1"
              :class="{
                record_back: true,
                record_back_pass: index == 1,
                record_back_reject: index == 2,
                record_back_cancel: index == 3,
              }"
            >
              <div class="record">
                <div class="top">
                  <div class="title">请假申请</div>
                  <div
                    :class="{ read: item1.read == 1, unread: item1.read == 0 }"
                  >
                    {{ item1.read == 1 ? "已读" : "未读" }}
                  </div>
                </div>
                <div class="divide"></div>
                <div class="detail_info">
                  <div class="item">
                    请假周期：<span v-if="item1.startTime && item1.endTime">{{
                      item1.startTime.slice(5, 19) +
                      " 至 " +
                      item1.endTime.slice(5, 19)
                    }}</span>
                  </div>
                  <div class="item_bold">
                    请假学生：<span>{{ item1.studentName }}</span>
                  </div>
                  <div class="item_grey">
                    <span
                      >请假类型：<span>{{
                        item1.recessType == 1
                          ? "事假"
                          : item1.recessType == 2
                          ? "病假"
                          : "其他"
                      }}</span>
                    </span>
                    <span class="detail_btn" @click="toDetail(item1)">
                      详情<van-icon class="icon" name="arrow" />
                    </span>
                  </div>
                  <div class="item_grey">
                    <span
                      >请假理由：<span>{{ item1.reason }}</span></span
                    >
                  </div>
                  <div class="item_grey">
                    <span
                      >请假人：<span>{{ item1.guardianName }}</span>
                    </span>
                    <div class="btn_box" v-if="type == 1 && state == 0">
                      <van-button
                        class="btn"
                        type="primary"
                        color="#1989fa"
                        @click="openJudgeDialog(1, item1)"
                        >同意</van-button
                      >
                      <van-button
                        class="btn"
                        type="danger"
                        @click="openJudgeDialog(-1, item1)"
                        >拒绝</van-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </van-list>
        </van-tab>
      </van-tabs>
    </div>

    <!-- 批复弹窗 -->
    <van-overlay :show="replyVisible" @click="replyVisible = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="title">
            {{ this.judgeType == 1 ? "批复" : "拒绝审批理由！" }}
          </div>
          <div class="divide"></div>
          <div class="input">
            <van-field
              v-model="reply"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="60"
              :placeholder="
                this.judgeType == 1 ? '请输入批复' : '请输入拒绝审批理由！'
              "
              show-word-limit
            />
          </div>
          <div class="btn_box">
            <van-button
              class="btn"
              type="danger"
              v-if="judgeType == -1"
              @click="judgeLeaveApplication(index)"
              >确认拒绝</van-button
            >
            <van-button
              class="btn"
              color="#399fee"
              v-if="judgeType == 1"
              @click="judgeLeaveApplication(index)"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getClassLeaveApplicationRecord } from "@/api/teacher_api";
import { getLeaveApplicationRecord } from "@/api/guardian_api";
export default {
  name: "leaveApplicationRecord",
  data() {
    return {
      // 类型 1为教师端 2是家长端
      type: "",
      // 无限加载
      loading: [false, false, false, false],
      finished: [false, false, false, false],
      // 请假记录列表
      recordInfos: [
        { page: 0, data: [], size: 5, total: 1, totalPage: 1 },
        { page: 0, data: [], size: 5, total: 1, totalPage: 1 },
        { page: 0, data: [], size: 5, total: 1, totalPage: 1 },
        { page: 0, data: [], size: 5, total: 1, totalPage: 1 },
      ],
      // 当前教师点击同意或拒绝的审批recessRecordId
      recessRecordId: "",
      // 选择的标签页
      activeTag: "待审批",
      tags: ["待审批", "已通过", "已拒绝", "已取消"],
      // 0待审批 1已通过 2已拒绝 3已取消
      // 对应的处理状态 0待审批 1已通过 -1已拒绝 -2已取消
      state: 0,
      // 审批类型 1为同意 -1为拒绝
      judgeType: 1,
      // 批复弹窗可见
      replyVisible: false,
      // 批复内容
      reply: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.meta.update != "") {
      to.meta.update = from.meta.update;
      from.meta.update = "";
      console.log("从详情返回但更新信息了");
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "leaveApplicationDetail") {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
  created() {
    this.type = this.$store.state.type;
  },

  activated() {
    console.log("activited周期");
    this.type = this.$store.state.type;
    // update 0家长修改请假消息 1教师同意审批  2教师拒绝审批 3家长取消申请
    if (this.$route.meta.update != "") {
      console.log("this.$route.meta.update", this.$route.meta.update);
      this.recordInfos[0] = {
        page: 0,
        data: [],
        size: 5,
        total: 1,
        totalPage: 1,
      };
      this.finished[0] = false;
      this.recordInfos[this.$route.meta.update] = {
        page: 0,
        data: [],
        size: 5,
        total: 1,
        totalPage: 1,
      };
      this.finished[this.$route.meta.update] = false;
      this.$route.meta.update = "";
      this.$forceUpdate();
    }
  },
  methods: {
    // 返回
    back() {
      this.$router.push("/");
    },

    // 查询本班请假粗略记录
    getClassLeaveApplicationRecord() {
      let req = {
        clazzId: this.$store.state.clazz.clazzId,
        recessStatus:
          this.state == 0 || this.state == 1
            ? this.state
            : this.state == 2
            ? -1
            : -2,
        page: ++this.recordInfos[this.state].page,
        size: 5,
      };
      getClassLeaveApplicationRecord(req).then((res) => {
        if (res.data.code == 1) {
          this.recordInfos[this.state].data = this.recordInfos[
            this.state
          ].data.concat(res.data.data.data);
          this.recordInfos[this.state].page = res.data.data.page;
          this.recordInfos[this.state].total = res.data.data.total;
          this.recordInfos[this.state].totalPage = res.data.data.totalPage;
          console.log("this.recordInfos", this.recordInfos);
          // 标记所有数据都加载完了
          if (
            this.recordInfos[this.state].page >=
            this.recordInfos[this.state].totalPage
          ) {
            this.finished[this.state] = true;
          }
          this.loading[this.state] = false;
          this.$forceUpdate();
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 监护人查询某个宝贝所有粗略请假记录
    getLeaveApplicationRecord() {
      let req = {
        studentId: this.$store.state.child.studentId,
        recessStatus:
          this.state == 0 ? 0 : this.state == 1 ? 1 : this.state == 2 ? -1 : -2,
        page: ++this.recordInfos[this.state].page,
        size: 5,
      };
      getLeaveApplicationRecord(req).then((res) => {
        if (res.data.code == 1) {
          this.recordInfos[this.state].data = this.recordInfos[
            this.state
          ].data.concat(res.data.data.data);
          this.recordInfos[this.state].page = res.data.data.page;
          this.recordInfos[this.state].total = res.data.data.total;
          this.recordInfos[this.state].totalPage = res.data.data.totalPage;
          console.log("this.recordInfos", this.recordInfos);
          // 标记所有数据都加载完了
          if (
            this.recordInfos[this.state].page >=
            this.recordInfos[this.state].totalPage
          ) {
            this.finished[this.state] = true;
          }
          this.loading[this.state] = false;
          this.$forceUpdate();
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 切换请假记录状态
    onChangeState(name) {
      // 0待审批 1已通过 2已拒绝 3已取消
      // 对应的处理状态 0待审批 1已通过 -1已拒绝 -2已取消
      console.log("切换请假状态", name, this.recordInfos);
      this.state = name;
      this.$forceUpdate();
    },

    // 无限加载
    onLoad() {
      console.log("调用无限加载onload this.state", this.state);
      if (this.type == 1) {
        // 粗略查询本班请假记录
        this.getClassLeaveApplicationRecord();
      } else {
        // 监护人查询某个宝贝所有粗略请假记录
        this.getLeaveApplicationRecord();
      }
    },

    // 打开审批窗口
    openJudgeDialog(type, item) {
      this.judgeType = type;
      this.replyVisible = true;
      this.recessRecordId = item.recessRecordId;
    },

    // 批复最终确认按钮
    // 审批请假记录并推送至家长端
    judgeLeaveApplication(index) {
      this.replyVisible = false;
      let req = {
        teacherId: this.$store.state.teacherId,
        teacherName: this.$store.state.teacherName,
        recordId: this.recessRecordId,
        updateStatus: this.judgeType,
        note: this.reply,
      };
      var _index = index;
      teacher_api.judgeLeaveApplication(req).then((res) => {
        if (res.data.code == 1) {
          if (this.judgeType == 1) {
            this.$toast("提交成功，同意此审批！");
            this.recordInfos[1] = {
              page: 0,
              data: [],
              size: 5,
              total: 1,
              totalPage: 1,
            };
            this.finished[1] = false;
          } else {
            this.$toast("提交成功，此审批已拒绝！");
            this.recordInfos[2] = {
              page: 0,
              data: [],
              size: 5,
              total: 1,
              totalPage: 1,
            };
            this.finished[2] = false;
          }
          this.recordInfos[0].data.splice(_index, 1);
          this.reply = "";
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 跳转到详情
    toDetail(item) {
      if (item.read == 0) {
        item.read = 1;
        this.$store.commit("decreaseUnreadCount");
      }
      if (item.recessRecordId != null || item.recessRecordId != undefined) {
        this.$router.push(
          "/leaveApplicationRecord/leaveApplicationDetail?recessRecordId=" +
            item.recessRecordId
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .tab {
    width: 100%;
    padding-top: 45px;
    .tab_item {
      width: 100%;
      min-height: 85vh;
      .records {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        .record_back {
          width: calc(100% - 40px);
          display: flex;
          justify-content: flex-end;
          border-radius: 5px;
          margin-bottom: 20px;
          background: #399fee;
          box-shadow: 2px 2px 3px 1px rgb(207, 207, 207);
          &.record_back_pass {
            background: #09c3b1;
          }
          &.record_back_reject {
            background: #eea0a7;
          }
          &.record_back_cancel {
            background: #aeada9;
          }
          .record {
            width: calc(100% - 8px);
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            background: white;
            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .title {
                padding: 10px 0px 10px 15px;
                font-size: 17px;
                font-weight: bold;
              }
              .read {
                color: grey;
                margin-right: 15px;
              }
              .unread {
                color: orange;
                margin-right: 15px;
              }
            }
            .divide {
              width: 99%;
              border: 1px solid rgb(211, 211, 211);
              transform-origin: center;
              transform: scaleY(0.1);
            }
            .detail_info {
              width: 100%;
              padding: 10px 0 0 15px;
              .item {
                margin-bottom: 10px;
                font-size: 16px;
              }
              .item_bold {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: bold;
              }
              .item_grey {
                width: calc(100% - 35px);
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                color: rgb(172, 172, 172);
                font-size: 16px;
                position: relative;
                .detail_btn {
                  position: absolute;
                  right: 10px;
                  bottom: 15px;
                }
                .btn_box {
                  .btn {
                    width: 60px;
                    height: 30px;
                    margin-right: 10px;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: calc(100% - 100px);
      height: 300px;
      background-color: #fff;
      border-radius: 10px;
      .title {
        padding: 15px 0 15px 15px;
        font-size: 18px;
        font-weight: bold;
      }
      .divide {
        width: 100%;
        border: 1px solid rgb(211, 211, 211);
        transform-origin: center;
        transform: scale(0.9, 0.1);
      }
      .input {
        width: 100%;
        margin-top: 10px;
        ::v-deep .van-cell__value {
          padding: 5px;
          border-radius: 5px;
          background: #faf9f9;
        }
      }
      .btn_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .btn {
          width: 200px;
          border-radius: 20px;
          font-size: 17px;
        }
      }
    }
  }
}
</style>
