<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="我的"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- 背景 -->
    <div class="background_img"></div>
    <!-- 上方基本资料卡 -->

    <div class="head_img_box">
      <div
        :class="{ name: true, name_teacher: type == 1 }"
        v-if="this.type == 1 && this.teacherInfo.teacherName"
      >
        {{
          this.teacherInfo.teacherName.slice(
            this.teacherInfo.teacherName.length - 2,
            this.teacherInfo.teacherName.length
          )
        }}
      </div>
      <div
        :class="{ name: true, name_guardian: type == 2 }"
        v-if="this.type == 2 && this.studentBasicInfo.studentName"
      >
        {{
          this.studentBasicInfo.studentName.slice(
            this.studentBasicInfo.studentName.length - 2,
            this.studentBasicInfo.studentName.length
          )
        }}
      </div>
    </div>
    <div class="basic">
      <div class="basic_info">
        <div class="name">
          {{
            this.type == 1
              ? this.teacherInfo.teacherName
              : this.studentBasicInfo.studentName
          }}
        </div>
        <!-- <div class="status">
          {{
            this.type == 1
              ? "职位：" + this.teacherInfo.roleName
              : "当前状态：" +
                (this.studentStatus.isInSchool == 1 ? "在校" : "不在校")
          }}
        </div> -->
        <!-- <div class="morning_afternoon" v-if="this.type == 2">
          <div class="morning">
            早上：{{ this.studentStatus.morningLate == 1 ? "迟到" : "正常" }}
          </div>
          <div class="afternoon">
            下午：{{ this.studentStatus.afternoonLate == 1 ? "迟到" : "正常" }}
          </div>
        </div> -->
      </div>
    </div>
    <!-- 中部详细 -->
    <div class="detail">
      <div class="left">
        <div class="title">
          <img class="icon" src="../assets/tabmeicon/1.png" alt="" />
          <div>{{ this.type == 1 ? "老师基本信息" : "学生基本信息" }}</div>
        </div>
        <div class="item">
          姓名：{{
            this.type == 1
              ? this.teacherInfo.teacherName
              : this.studentBasicInfo.studentName
          }}
        </div>
        <!-- <div class="item">
          {{
            this.type == 1
              ? "卡号：" + this.teacherInfo.teacherNumber
              : "学号：" + this.studentBasicInfo.studentNumber
          }}
        </div> -->
        <!-- <div class="item" v-if="this.type == 2">
          卡号：{{ this.studentBasicInfo.studentPhysicalCardNumber }}
          <span class="lost"> 挂失 </span>
        </div> -->
        <div class="item">
          学校：{{
            this.type == 1
              ? this.teacherInfo.schoolName
              : this.studentBasicInfo.schoolName
          }}
        </div>
        <div class="item" v-if="this.type == 2">
          班级：{{ this.studentBasicInfo.gradeClazz }}
        </div>
        <div class="item" v-if="this.type == 1">
          {{ "手机：" + this.teacherInfo.teacherPhone }}
        </div>
        <div class="item" v-if="this.type == 1">
          {{ "邮箱：" + this.teacherInfo.email }}
        </div>
      </div>
      <!-- <div class="right">
        <img class="head_img" src="../assets/meheadimg.jpg" alt="" />
        <div class="down">
          <div class="change_headimg">更换头像</div>
          <van-icon name="edit" />
        </div>
      </div> -->
    </div>

    <!-- 人脸照片插件 -->
    <!-- <div style="margin: 0 auto; text-align: center; margin-bottom: 20px">
      <van-cell-group style="margin-top: 20px">
        <UploadImages :father_data="studentImg" @goto="confirmMask" />
      </van-cell-group>
      <van-button
        type="primary"
        @click="changePhoto"
        style="margin-top: 10px"
        :disabled="studentImg.photo_url == '' || studentImg.photo_url == null"
        >修改照片</van-button
      >
    </div> -->

    <!-- 下部按钮 -->
    <!-- <div class="button" @click="jump(1)">
      <div class="title">
        <img class="icon" src="../assets/tabmeicon/2.png" alt="" />
        <div v-if="this.type == 2">班级老师</div>
        <div v-else>班级学生</div>
      </div>
      <div class="arrow">
        <van-icon name="arrow" />
      </div>
    </div> -->
    <div class="button" v-if="this.type == 2" @click="jump(2)">
      <div class="title">
        <img class="icon" src="../assets/tabmeicon/3.png" alt="" />
        <div>监护人</div>
      </div>
      <div class="arrow">
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="button" @click="jump(3)">
      <div class="title">
        <img class="icon" src="../assets/tabmeicon/3.png" alt="" />
        <div>修改密码</div>
      </div>
      <div class="arrow">
        <van-icon name="arrow" />
      </div>
    </div>
    <!-- <div class="button" @click="jump(4)">
      <div class="title">
        <img class="icon" src="../assets/tabmeicon/3.png" alt="" />
        <div>修改手机</div>
      </div>
      <div class="arrow">
        <van-icon name="arrow" />
      </div>
    </div> -->
    <div class="button" v-if="this.type == 1" @click="jump(5)">
      <div class="title">
        <img class="icon" src="../assets/tabmeicon/3.png" alt="" />
        <div>修改邮箱</div>
      </div>
      <div class="arrow">
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="button" @click="logout()">
      <div class="title">
        <img class="icon" src="../assets/tabmeicon/4.png" alt="" />
        <div>退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImages from "@/components/UploadImages.vue";

import { logout, updateStudentPhoto } from "@/api/api";
import { getSelfInfo } from "@/api/teacher_api";
import { getStudentStatus, getStudentInfo } from "@/api/guardian_api";
import { Dialog } from "vant";
export default {
  name: "tabMe",
  data() {
    return {
      // 1为教师端 2为监护人端
      type: "1",
      // 老师基本信息
      teacherInfo: {},
      // 学生基本信息
      studentBasicInfo: {
        clazzId: 1,
        gradeClazz: "",
        schoolName: "",
        studentName: "",
        studentNumber: "",
        studentPhysicalCardNumber: "",
      },
      // 学生状态
      studentStatus: {
        afternoonLate: 1,
        isInSchool: 0,
        morningLate: 1,
      },

      studentImg: {
        file_list: [],
        photo_url: "",
        max_count: 1,
        label: "学生照片",
        errorMessage: "",
      },

      // 临时容器，用了中转学生人脸照片
      photo: {
        url: "",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "studentList" || from.name == "connect") {
      to.meta.reload = false;
    } else {
      to.meta.reload = true;
    }
    next();
  },
  components: {
    UploadImages,
  },
  created() {
    this.$route.meta.reload = true;
    if (this.$route.meta.reload || this.type != this.$store.state.type) {
      // 获取教师端还是监护人端
      this.type = this.$store.state.type;
      if (this.type == 1) {
        // 获取老师信息
        console.log("获取老师信息");
        this.getSelfInfo();
      } else {
        console.log("获取学生信息");
        // 查询学生基本信息
        this.getStudentInfo();
        // 获取学生状态
        this.getStudentStatus();
      }
      this.$route.meta.reload = false;
    }
  },

  methods: {
    // 返回
    back() {
      this.$router.push("/");
    },

    confirmMask(fileList) {
      // this.zhufangrenzhaopian.errorMessage = "";
      // this.vreserveForm.v_fangyizhaopian = fileList;
    },

    // 老师获取自己的信息
    getSelfInfo() {
      let req = {
        teacherId: this.$store.state.teacherId,
      };
      getSelfInfo(req).then((res) => {
        if (res.data.code == 1) {
          if (res.data.data == undefined) {
            console.log("获取教师信息接口没有返回参数");
            return;
          }
          this.teacherInfo = res.data.data;
          console.log("this.teacherInfo", this.teacherInfo);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 获取学生状态
    getStudentStatus() {
      let req = {
        studentId: this.$store.state.child.studentId,
      };
      getStudentStatus(req).then((res) => {
        if (res.data.code == 1) {
          this.studentStatus = res.data.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 获取学生基本信息
    getStudentInfo() {
      let req = {
        studentId: this.$store.state.child.studentId,
      };
      getStudentInfo(req).then((res) => {
        if (res.data.code == 1) {
          this.studentBasicInfo = res.data.data;
          // 把照片路径复制给录入照片的地方
          this.photo.url = res.data.data.photoUrl;
          // 添加照片之前，需要先把之前的数组清空
          this.studentImg.file_list.length = 0;
          this.studentImg.file_list.push(this.photo);
          // this.studentImg.photo_url = "";
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 跳转到通讯录
    jump(flag) {
      if (flag == 1) {
        if (this.type == 2) {
          // 班级老师
          this.$router.push("/tabMe/connect/1");
        } else {
          // 班级学生
          this.$router.push("/tabMe/studentList");
        }
      } else if (flag == 2) {
        //  监护人
        this.$router.push("/tabMe/connect/2");
      } else {
        // 修改密码 手机 邮箱
        this.$router.push("/tabMe/changePasswordOrPhone?flag=" + flag);
      }
    },

    //  登出
    logout() {
      Dialog.confirm({
        title: "",
        message: "是否确认退出登录？",
      })
        .then(() => {
          // on confirm
          logout().then(
            (res) => {
              if (res.data.code == 1) {
                // this.$store.commit("setToken","")
                localStorage.setItem("token", "");
                this.$router.replace("/login");
              } else {
                this.$toast(res.data.message);
              }
            },
            () => {
              this.$toast("登出接口错误");
              this.$router.replace("/login");
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },

    // 修改照片
    changePhoto() {
      let userId = "";

      // 判断是否有新增或修改照片
      if (!this.studentImg.photo_url) {
        this.$toast.loading({
          message: "请先上传照片...",
          forbidClick: true, // 通过forbidClick属性可以禁用背景点击
        });
        return false;
      }
      if (this.type == 1) {
        // 账号登录的是教师身份
        userId = this.$store.state.teacherId;
      } else if (this.type == 2) {
        // 账号登录的是家长身份
        userId = this.$store.state.guardianId;
      } else {
        // 其他可能 这里暂不处理
      }
      updateStudentPhoto({
        studentId: this.$store.state.child.studentId, // 学生的id
        userId: userId, // 教师或者家长的id
        photoUrl: this.studentImg.photo_url, // 需要替换的照片
      }).then((res) => {
        this.$toast(res.data.message);
        // console.log(res.data);
      });
      console.log(this.studentImg);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    z-index: 3;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
  }
  .background_img {
    width: 100%;
    height: 220px;
    background: url("../assets/tabmeicon/背景.png") no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: 0;
  }
  .head_img_box {
    position: absolute;
    top: 80px;
    z-index: 2;
    .head_img {
      width: 85px;
      height: 85px;
      border-radius: 50%;
    }
    .name {
      width: 85px;
      height: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: rgb(49, 131, 255);
      border-radius: 50%;
      font-size: 25px;
      text-indent: 7px;
      letter-spacing: 7px;
      font-weight: bold;
      color: white;
      &.name_teacher {
        background-image: linear-gradient(rgb(255, 142, 49), rgb(243, 115, 10));
      }
      &.name_guardian {
        background-image: linear-gradient(rgb(77, 148, 255), rgb(21, 103, 226));
      }
    }
  }
  .basic {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-top: 135px;
    padding-top: 30px;
    margin-bottom: 20px;
    border-radius: 15px;
    z-index: 1;
    .basic_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        font-size: 19px;
        font-weight: bold;
        margin: 10px;
      }
      .status {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .morning_afternoon {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        font-size: 14px;
        .morning {
          margin-right: 25px;
        }
      }
    }
  }
  .detail {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 20px;
    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      margin-left: 20px;
      .title {
        width: calc(100% - 30px);
        display: flex;
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 15px;
        .icon {
          width: 18px;
          height: 20px;
          margin-right: 7px;
        }
      }
      .item {
        margin-left: 20px;
        margin-bottom: 10px;
        font-size: 14px;
        .lost {
          color: rgb(180, 19, 19);
          background-color: rgb(187, 184, 184);
          padding: 2px;
          margin-left: 5px;
          border-radius: 5px;
        }
      }
    }
    .right {
      margin-right: 25px;
      display: flex;
      flex-direction: column;
      .head_img {
        width: 80px;
        height: 80px;
        box-shadow: 1px 1px 5px grey;
        margin-bottom: 10px;
      }
      .down {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .change_headimg {
          font-size: 14px;
          margin-right: 5px;
          color: rgb(180, 19, 19);
        }
      }
    }
  }
  .button {
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 15px;
    font-size: 17px;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        width: 25px;
        margin-left: 20px;
        margin-right: 10px;
      }
    }
    .arrow {
      padding-right: 15px;
      color: rgb(66, 66, 255);
    }
  }
}
</style>
