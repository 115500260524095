<template>
  <div>
    <!-- 轮播图 -->
    <div class="swiper_box" v-if="show == 0" style="height: 27vh">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        lazy-render
      >
        <van-swipe-item
          v-for="(item, index) in schoolAdvertImages"
          :key="index"
        >
          <img :src="item.image" alt="" width="100%" style="height: 27vh" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="box">
      <div>
        <van-form class="form" :model="form" ref="myform">
          <van-cell-group>
            <van-field
              label="家长姓名"
              class="input"
              v-model="form.guardianName"
              required
              placeholder="请输入您的姓名"
              :rules="nameRule"
            />
            <van-field
              label="手机号码"
              class="input"
              v-model="form.guardianPhone"
              required
              placeholder="请输入您的手机号码"
              type="text"
              :rules="phoneRule"
            />
            <van-field
              label="学生姓名"
              class="input"
              v-model="form.studentName"
              required
              placeholder="请输入您的孩子姓名"
              :rules="nameRule"
            />
            <!-- 学生班级，下拉选择输入框 -->
            <van-field
              v-model="fieldValue"
              is-link
              required
              readonly
              label="班级"
              placeholder="请选择所在班级"
              @click="onClick"
              :rules="nameRule"
            />
            <van-popup v-model:show="showClazz" round position="bottom">
              <van-cascader
                v-model="form.clazzId"
                title="请选择所在地区"
                :options="options"
                @close="onClose"
                @finish="onFinish"
              />
            </van-popup>
            <van-field
              required
              name="uploader"
              label="家长照"
              :rules="photoRules"
            >
              <template #input>
                <van-uploader
                  v-model="fileList"
                  :after-read="afterRead"
                  :max-count="1"
                  :before-read="beforeRead"
                  :max-size="50000 * 1024"
                />
                <van-image
                  :src="require('../assets/guardianRegister.png')"
                  style="width: 25%"
                />
              </template>
              <!-- 提示 -->
            </van-field>
            <span style="color: red">
              备注:<br />
              1、请入校家长填写匹配的学生姓名和班级，错误无法提交;<br />
              2、拍摄照片选择光线较好和干净的背景
            </span>
            <van-divider />
          </van-cell-group>
        </van-form>
      </div>
      <van-button color="#4ba578" round type="info" @click="register()">
        提交申请
      </van-button>
    </div>
  </div>
</template>

<script>
import { reqGuardianRegister } from "@/api/guardian_api";
import { getSchoolAdvert } from "@/api/school_api";
import request from "../utils/request.js";
import { reqGetClazzTree } from "@/api/clazz_api";
import { Dialog } from "vant";
// import { getUserInfo } from "@/api/api";

export default {
  async created() {
    // this.getUserInfoV2();
    console.log("家长注册");
    this.getSchoolAdvert();
    this.getClazzTree();
  },
  data() {
    return {
      options: [],
      showClazz: false,
      fieldValue: "",
      schoolAdvertImages: [],
      nameRule: [
        {
          required: true,
          //   message: "姓名不能为空",
          trigger: "onBlur",
        },
        {
          validator: this.validateIdCard,
          trigger: "blur",
        },
      ],
      photoRules: [
        {
          required: true,
          message: "请上传家长照",
          trigger: "onBlur/onChange",
        },
      ],
      fileList: [],
      phoneRule: [
        {
          required: true,
          //   message: "手机号不能为空",
          trigger: "onBlur/onChange",
        },
        {
          validator: (value) => {
            const phoneReg = /^1[3456789]\d{9}$/;
            if (!phoneReg.test(value)) {
              return false;
            } else {
              return true;
            }
          },
        },
      ],
      active: 0,
      // 忘记密码弹窗
      show: false,
      form: {
        guardianName: "",
        guardianPhone: "",
        studentName: "",
        photoUrl: [],
        clazzId: undefined,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  methods: {
    //获取学校轮播图
    async getSchoolAdvert() {
      await getSchoolAdvert(localStorage.getItem("schoolId"))
        .then((res) => {
          if (res.data.code === 1) {
            this.schoolAdvertImages = res.data.data;
            console.log("获取后的数据", this.schoolAdvertImages);
          } else {
            // eslint-disable-next-line no-undef
            Dialog.alert({
              title: "提示",
              message: "获取学校介绍信息失败，请关闭页面重新进入",
            }).then(() => {
              // on close
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClazzTree() {
      //从路由参数获取schoolId
      reqGetClazzTree(this.$route.query.schoolId).then(
        (res) => {
          console.log("班级树", res);
          this.options = res.data.data;
          console.log("班级树", this.options);
        },
        (err) => {
          console.log("获取班级树失败", err);
        }
      );
    },
    register() {
      this.$refs.myform
        .validate()
        .then(() => {
          let req = {
            studentName: this.form.studentName,
            guardianPhone: this.form.guardianPhone,
            guardianName: this.form.guardianName,
            photoUrl: this.form.photoUrl,
            clazzId: this.form.clazzId,
            schoolId: this.$route.query.schoolId,
          };
          reqGuardianRegister(req).then((res) => {
            if (res.data.code == 1) {
              this.$toast.success("注册成功");
            } else {
              this.$toast.fail("注册失败:" + res.data.message);
            }
          });
          this.form = {
            guardianName: "",
            guardianPhone: "",
            studentName: "",
            photoUrl: [],
            clazzId: null,
            schoolId: null,
          };
          this.fieldValue = "";
        })
        .catch((e) => {
          console.log("验证不通过");
          console.log(e);
        });
    },
    beforeRead(event) {
      //File文件 ，判断是否是图片类型
      if (event) {
        //含image即可
        if (event.type.indexOf("image") === -1) {
          console.log("不是图片");
          //不是图片格式
          this.$toast.fail("请上传图片");
          return false;
        } else {
          return true;
        }
      }
    },

    afterRead(event) {
      const { file } = event;
      // 当设置 mutiple 为 true 时, file 为数组格式，否则为对象格式
      //上传
      let formData = new FormData();
      formData.append("file", file);
      console.log("上传的文件", formData);
      request
        //TODO 换成域名+端口
        .post("http://42.193.243.167:8080/file/uploadImg/guardian", formData)
        .then((res) => {
          console.log("上传成功", res);
          this.form.photoUrl = res.data.data;
          console.log("上传成功", this.form.photoUrl);
        })
        .catch((err) => {
          console.log("上传失败", err);
        });
    },
    onClick() {
      console.log("你好");
      this.showClazz = true;
      console.log("showClazz" + this.showClazz);
    },

    onClose() {
      this.showClazz = false;
    },

    onFinish(e) {
      this.fieldValue = e.selectedOptions
        .map((option) => option.text || option.name)
        .join("/");
      this.form.clazzId = e.selectedOptions[e.selectedOptions.length - 1].value;
      this.showClazz = false;
    },
    // getUserInfoV2() {
    //   if (!this.$route.query.token) {
    //     this.$toast("没有token");
    //     // jiaji的
    //     // let hhAppId = "wx9c68a4cb0ec896a8";
    //     //公司的
    //     let hhAppId = "wxa9991f1c4fbe89bb";
    //     //公司域名
    //     // let domain = "wxtest.ubooking.cn";
    //     //jj域名
    //     // let domain = "shjj.ubooking.cn";
    //     //测试域名
    //     let domain = "xyue.mynatapp.cc";
    //     let type = this.$route.query.type;
    //     let id = this.$route.query.id;
    //     let schoolId = localStorage.getItem("schoolId");
    //     let authUrl = this.buildAuthUrl(hhAppId, domain, type, id, schoolId);
    //     if (authUrl) {
    //       console.log("authUrl", authUrl);
    //       window.location.href = authUrl;
    //     } else {
    //       Dialog.alert({
    //         title: "提示",
    //         message: "获取学校缓存信息失败，请关闭页面重新进入",
    //       }).then(() => {
    //         // on close
    //       });
    //     }
    //   } else {
    //     this.$toast("有token了");
    //     getUserInfo({ token: this.$route.query.token }).then((res) => {
    //       //有可能有信息，但是被禁用了，所以需要判断
    //       res.data.data.userInfo.schoolId = this.$route.query.schoolId;
    //       this.$store.commit("setOpenid", res.data.data.userInfo.openid);
    //       this.$toast("openId::" + res.data.data.userInfo.openid);
    //       this.$store.commit(
    //         "setUserInfo",
    //         JSON.stringify(res.data.data.userInfo)
    //       );
    //       sessionStorage.setItem(
    //         "userInfo",
    //         JSON.stringify(res.data.data.userInfo)
    //       );
    //     });
    //   }
    // },
    // buildAuthUrl(hhAppId, domain, type, id, schoolId) {
    //   let backUrl =
    //     // 云上nginx部署用这个
    //     "http%3A%2F%2F" + domain + "%2Fapi%2Fwechat%2FauthorizeInfo%3F";
    //   // 本地测试用这个，因为没有自动去掉api
    //   // "http%3A%2F%2F" + domain + "%2Fwechat%2FauthorizeInfo%3F";
    //   let href =
    //     `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${hhAppId}&redirect_uri=` +
    //     backUrl +
    //     "type=" +
    //     type +
    //     "%26id=" +
    //     id +
    //     "%26schoolId=" +
    //     schoolId +
    //     "%26isGuardianRegister=" +
    //     1 +
    //     "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //   return href;
    // },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  height: 100%;
  width: 100%;
}
</style>
