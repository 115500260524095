<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="请假详情"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- <div class="tip">
      {{"温馨提示：提交审批后，将由上级领导进行审批！"}}
    </div> -->
    <van-notice-bar
      v-if="this.applicationInfo.status == 0"
      class="tip"
      scrollable
      text="温馨提示：提交审批后，将由上级领导进行审批！"
    />

    <!-- 详细信息 -->
    <div class="box">
      <div class="detail">
        <div class="big_title" v-if="type == 2">您提交的请假申请</div>
        <div
          v-if="type == 2"
          :class="{
            statu: true,
            status_wait: this.applicationInfo.status == '0',
            status_pass: this.applicationInfo.status == '1',
            status_reject: this.applicationInfo.status == '-1',
            status_cancel: this.applicationInfo.status == '-2',
          }"
        >
          {{
            this.applicationInfo.status == "0"
              ? "正在审批中..."
              : this.applicationInfo.status == "1"
              ? "审批通过"
              : this.applicationInfo.status == "-1"
              ? "审批拒绝"
              : "审批取消"
          }}
        </div>
        <div class="item" v-if="type == 1">
          <span class="title">请假学生</span>
          <span class="value">{{ this.applicationInfo.studentName }}</span>
        </div>
        <div class="divide"></div>
        <div class="item">
          <span class="title">请假类型</span>
          <span class="value">{{
            this.applicationInfo.type == 1
              ? "事假"
              : this.applicationInfo.type == 2
              ? "病假"
              : "其他"
          }}</span>
        </div>
        <div class="divide"></div>
        <div class="item">
          <span class="title">开始时间</span>
          <span class="value">{{ this.applicationInfo.startTime }}</span>
        </div>
        <div class="divide"></div>
        <div class="item">
          <span class="title">结束时间</span>
          <span class="value">{{ this.applicationInfo.endTime }}</span>
        </div>
        <div class="divide"></div>
        <div class="item_reson">
          <div class="title">请假事由</div>
          <br />
          <div class="value">{{ this.applicationInfo.reason }}</div>
        </div>
      </div>

      <!-- 审批流程 -->
      <div class="process">
        <div class="big_title">审批流程</div>
        <div class="initiate">
          <div class="initiate_box">
            <div class="left">
              <div class="headimg">
                {{
                  this.applicationInfo.guardianName.slice(
                    this.applicationInfo.guardianName.length - 2,
                    this.applicationInfo.guardianName.length
                  )
                }}
              </div>
              <div>发起申请</div>
            </div>
            <div class="right">
              {{
                this.applicationInfo.status == -2
                  ? "已取消"
                  : this.applicationInfo.updateTime
              }}
            </div>
          </div>
        </div>
        <div class="divide" v-if="this.applicationInfo.status != -2"></div>
        <div class="teacher" v-if="this.applicationInfo.status != -2">
          <div class="teacher_box">
            <div class="left">
              <div class="headimg">
                {{ "老师" }}
              </div>
              <div class="job">
                {{ this.applicationInfo.teacherRole }}<br />
                <div class="name">
                  {{ this.applicationInfo.teacherName
                  }}{{
                    this.applicationInfo.status == 0
                      ? ""
                      : this.applicationInfo.status == -2
                      ? ""
                      : "(已审批)"
                  }}
                </div>
              </div>
            </div>
            <div
              :class="{
                right: true,
                right_reject: this.applicationInfo.status == -1,
              }"
            >
              {{
                this.applicationInfo.status == 0
                  ? "等待审批"
                  : this.applicationInfo.status == 1
                  ? "2016-02-30 12:00:00"
                  : "已拒绝"
              }}
            </div>
          </div>
        </div>
        <div
          class="reply"
          v-if="
            this.applicationInfo.status == 1 ||
            this.applicationInfo.status == -1
          "
        >
          <div class="reply_box">
            <div class="title">批复:</div>
            <div class="content">
              {{ this.applicationInfo.note }}
            </div>
          </div>
        </div>
      </div>

      <!-- 按钮 -->
      <div class="btn" v-if="this.applicationInfo.status == 0 && type == 2">
        <van-button
          class="btn1"
          round
          type="primary"
          color="#0e9cff"
          @click="changeApplication()"
          >修改申请</van-button
        >
        <van-button
          class="btn2"
          round
          type="primary"
          color="#f38a1b"
          @click="cancelApplication()"
          >撤销申请</van-button
        >
      </div>
      <div class="btn" v-if="this.applicationInfo.status == 0 && type == 1">
        <van-button
          class="btn1"
          round
          type="primary"
          color="#0e9cff"
          @click="openJudgeDialog(1)"
          >同意申请</van-button
        >
        <van-button
          class="btn2"
          round
          type="primary"
          color="#f38a1b"
          @click="openJudgeDialog(-1)"
          >拒绝申请</van-button
        >
      </div>
    </div>

    <!-- 批复弹窗 -->
    <van-overlay :show="replyVisible" @click="replyVisible = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="title">
            {{ this.judgeType == 1 ? "批复" : "拒绝审批理由！" }}
          </div>
          <div class="divide"></div>
          <div class="input">
            <van-field
              v-model="reply"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="60"
              :placeholder="
                this.judgeType == 1 ? '请输入批复' : '请输入拒绝审批理由！'
              "
              show-word-limit
            />
          </div>
          <div class="btn_box">
            <van-button
              class="btn"
              type="danger"
              v-if="judgeType == -1"
              @click="judgeLeaveApplication()"
              >确认拒绝</van-button
            >
            <van-button
              class="btn"
              color="#399fee"
              v-if="judgeType == 1"
              @click="judgeLeaveApplication()"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  getLeaveApplicationRecordDetail,
  judgeLeaveApplication,
} from "@/api/teacher_api";
import { cancelLeaveApplication } from "@/api/guardian_api";
import { Dialog } from "vant";
export default {
  data() {
    return {
      // 类型 1为教师端 2是家长端
      type: "1",
      // 请假记录id
      recessRecordId: "",
      // 请假记录详情信息
      applicationInfo: {
        studentId: 1,
        studentName: "2",
        guardianName: "4",
        teacherName: "张老师",
        teacherRole: "班主任",
        // 请假类型 事假1 病假2 其他0
        type: 1,
        reason: "6",
        startTime: "1111-11-11 11:11:11",
        endTime: "1111-11-11 11:11:11",
        // 审批状态  0：审核中，1：审核通过，-1审核拒绝，-2已取消
        status: 0,
        note: "已知悉，注意安全",
        createTime: "2022-01-18 17:00:21",
      },
      // 审批类型 1为同意 -1为拒绝
      judgeType: 1,
      // 批复弹窗可见
      replyVisible: false,
      // 批复内容
      reply: "",
    };
  },
  mounted() {
    // 获取教师端还是监护人端
    this.type = this.$store.state.type;
    console.log("type=" + this.type);
    // 获取请假记录的id
    this.recessRecordId = this.$route.query.recessRecordId;
    // 查询请假详情记录（监护人端和教师端都可以用）
    this.getLeaveApplicationRecordDetail();
    // 查看路由上有没有update  有则代表是家长修改后跳转来的
    this.$route.meta.update = this.$route.query.update
      ? this.$route.query.update
      : "";
  },
  methods: {
    // 返回
    back() {
      if (this.$route.meta.update != "") {
        this.$router.push("/leaveApplicationRecord");
      } else {
        this.$router.go(-1);
      }
    },

    // 查询请假详情记录（监护人端和教师端都可以用）
    getLeaveApplicationRecordDetail() {
      let req = {
        recordId: this.recessRecordId,
      };
      getLeaveApplicationRecordDetail(req).then((res) => {
        if (res.data.code == 1) {
          this.applicationInfo = res.data.data;
          console.log("this.applicationInfo", this.applicationInfo);
        } else {
          this.$toast("查询请假详情记录接口：" + res.data.message);
        }
      });
    },

    // 打开审批窗口
    openJudgeDialog(type) {
      this.judgeType = type;
      this.replyVisible = true;
    },

    // 审批请假记录并推送至家长端
    judgeLeaveApplication() {
      let req = {
        teacherId: this.$store.state.teacherId,
        teacherName: this.$store.state.teacherName,
        recordId: this.recessRecordId,
        updateStatus: this.judgeType,
        note: this.reply,
      };
      judgeLeaveApplication(req).then((res) => {
        if (res.data.code == 1) {
          console.log(res.data.data);
          this.reply = "";
          this.replyVisible = false;
          if (this.judgeType == 1) {
            this.$toast("提交成功，同意此审批！");
            this.$route.meta.update = 1;
          } else {
            this.$toast("提交成功，此审批已拒绝！");
            this.$route.meta.update = 2;
          }
          this.$router.push("/leaveApplicationRecord");
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 修改申请
    changeApplication() {
      this.$router.push({
        path: "/leaveApplication",
        query: {
          type: this.applicationInfo.type,
          startDate: this.applicationInfo.startTime,
          endDate: this.applicationInfo.endTime,
          // startDate: this.applicationInfo.startTime,
          reason: this.applicationInfo.reason,
          recessRecordId: this.recessRecordId,
        },
      });
    },

    // 取消请假弹窗
    cancelApplication() {
      Dialog.confirm({
        title: "",
        message: "是否取消请假？",
      })
        .then(() => {
          // on confirm
          this.cancelLeaveApplication();
        })
        .catch(() => {
          // on cancel
        });
    },

    // 取消请假
    cancelLeaveApplication() {
      let req = {
        recordId: this.recessRecordId,
        guardianId: this.$store.state.guardianId,
      };
      cancelLeaveApplication(req).then((res) => {
        if (res.data.code == 1) {
          this.$toast("取消成功！");
          this.$route.meta.update = 3;
          this.$router.push("/leaveApplicationRecord");
        } else {
          this.$toast(res.data.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .tip {
    width: calc(100% - 14px);
    font-size: 14px;
    padding: 3px 7px 3px 7px;
    // color: white;
    // background: #ff9800;
    height: 25px;
    position: absolute;
    top: 45px;
  }
  .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .detail {
      width: calc(100% - 30px);
      min-height: 400px;
      margin-top: 90px;
      border-radius: 10px;
      background: white;
      .big_title {
        font-size: 20px;
        font-weight: bold;
        padding: 5px 0 10px 15px;
      }
      .statu {
        width: 100%;
        font-weight: bold;
        padding: 0 0 5px 15px;
        &.status_wait {
          color: #ff9800;
        }
        &.status_pass {
          color: #09c3b1;
        }
        &.status_reject {
          color: #d41426;
        }
        &.status_cancel {
          color: grey;
        }
      }
      .divide {
        width: 100%;
        border: 1px solid rgb(235, 235, 235);
        transform-origin: center;
        transform: scale(0.98, 0.1);
      }
      .item {
        width: calc(100% - 30px);
        display: flex;
        justify-content: space-between;
        padding: 20px 0 20px 15px;
        .value {
          color: gray;
        }
      }
      .item_reson {
        width: calc(100% - 30px);
        padding: 20px 0 20px 15px;
        .value {
          display: flex;
          flex-wrap: wrap;
          color: gray;
        }
      }
    }
    .process {
      width: calc(100% - 30px);
      min-height: 100px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 10px;
      background: white;
      .big_title {
        font-size: 20px;
        font-weight: bold;
        padding: 5px 0 10px 15px;
      }
      .initiate {
        width: 100%;
        display: flex;
        justify-content: center;
        .initiate_box {
          width: calc(100% - 30px);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .left {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: bold;
          .headimg {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(
              rgb(77, 148, 255),
              rgb(21, 103, 226)
            );
            text-indent: 3px;
            letter-spacing: 3px;
            color: white;
            font-size: 15px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
        .right {
          color: gray;
        }
      }
      .divide {
        width: 0px;
        height: 25px;
        border: 1px solid gray;
        border-width: 0 0 0 1px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .teacher {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        .teacher_box {
          width: calc(100% - 30px);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .left {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: bold;
          .headimg {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(
              rgb(255, 142, 49),
              rgb(243, 115, 10)
            );
            text-indent: 3px;
            letter-spacing: 3px;
            color: white;
            font-size: 15px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .name {
            margin-top: 5px;
            font-size: 14px;
            color: grey;
          }
        }
        .right {
          color: gray;
          &.right_reject {
            color: #d41426;
          }
        }
      }
      .reply {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        .reply_box {
          width: calc(100% - 20px);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 15px;
          .title {
            width: 100%;
            padding-left: 10px;
          }
          .content {
            width: calc(100% - 40px);
            min-height: 100px;
            display: flex;
            flex-wrap: wrap;
            padding: 15px;
            margin-top: 5px;
            color: grey;
            font-weight: bold;
            background: rgb(231, 231, 231);
          }
        }
      }
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .btn1 {
        width: 150px;
      }
      .btn2 {
        width: 150px;
        margin-left: 20px;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: calc(100% - 100px);
      height: 300px;
      background-color: #fff;
      border-radius: 10px;
      .title {
        padding: 15px 0 15px 15px;
        font-size: 18px;
        font-weight: bold;
      }
      .divide {
        width: 100%;
        border: 1px solid rgb(211, 211, 211);
        transform-origin: center;
        transform: scale(0.9, 0.1);
      }
      .input {
        width: 100%;
        margin-top: 10px;
        ::v-deep .van-cell__value {
          padding: 5px;
          border-radius: 5px;
          background: #faf9f9;
        }
      }
      .btn_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .btn {
          width: 200px;
          border-radius: 20px;
          font-size: 17px;
        }
      }
    }
  }
}
</style>
