<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="缴费记录"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="tabs_box">
      <!-- 选择时间 -->
      <div class="selectTime" @click="datePickerShow = true">
        <van-icon name="clock-o" style="margin-right: 5px" />{{
          this.pickDateDisplay
        }}
      </div>
      <van-tabs class="tabs" :active="active">
        <van-tab class="pick_up" title="缴费记录">
          <van-list
            v-if="this.paymentRecord.data.length > 0"
            class="messages"
            v-model="loading[active]"
            :finished="finished[active]"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="message"
              v-for="(item, index) in this.paymentRecord.data"
              :key="index"
            >
              <div class="message_box">
                <div class="message_main">
                  <div class="detail">
                    <div class="guardian">
                      缴费金额：{{ item?.cost / 100 }}元<br />
                      关联学生：{{ item?.studentName }} <br />交易状态:{{
                        orderStatusMap[item?.status]
                      }}<br />
                      订单创建时间:{{ item?.createTime }} 订单号:{{
                        item?.outTradeNo
                      }}
                      <div v-if="item?.refundSuccessTime">
                        退款成功时间:{{ item?.refundSuccessTime }}
                      </div>
                      <div v-else>交易成功时间{{ item?.successTime }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <van-action-sheet v-model="datePickerShow" title="">
      <van-datetime-picker
        v-model="pickDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @confirm="onConfirm()"
        @cancel="datePickerShow = false"
      />
    </van-action-sheet>
  </div>
</template>
<script>
import { reqGetPaymentRecord } from "../api/payment_api";
import { Dialog } from "vant";
export default {
  name: "paymentRecord",
  data() {
    return {
      orderStatusMap: {
        0: "交易成功",
        1: "交易中",
        2: "交易失败",
        3: "退款成功",
        5: "退款中",
        6: "退款异常",
        7: "退款关闭",
      },
      isPayMap: {
        0: "未支付",
        1: "已支付",
      },
      active: 0,
      // 无限加载
      loading: [false, false],
      finished: [false, false],
      paymentRecord: { page: 0, data: [], size: 5, total: 0, totalPage: 1 },
      // 选择时间弹窗
      datePickerShow: false,
      // 选择的时间
      pickDate: new Date(),
      pickDateDisplay:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1 < 10 ? "0" : "") +
        (new Date().getMonth() + 1) +
        "-" +
        (new Date().getDate() < 10 ? "0" : "") +
        new Date().getDate(),
      maxDate: new Date(),
      studentId: "", // 学生id
    };
  },
  watch: {
    // 加防抖
    // searchKeyPickup(val) {
    //   // 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
    //   this.finished = [false, false];
    //   this.paymentRecord = { page: 0, data: [], size: 5, total: 0, totalPage: 1 };
    // },
    // searchKeyInout(val) {
    //   // 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
    //   this.finished = [false, false];
    //   this.paymentRecord = { page: 0, data: [], size: 5, total: 0, totalPage: 1 };
    // },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "messageDetail") {
      from.meta.keepAlive = true;
      console.log("去详情", from.meta.keepAlive);
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
  created() {
    console.log("created生命周期");
    this.paymentRecord.page = 0;
    console.log("this.paymentRecord.page::" + this.paymentRecord.page);
    this.studentId = this.$store.state.child.studentId;
    this.getPaymentRecord();
  },
  mounted() {
    this.studentId = this.$store.state.child.studentId;
    //获取缴费记录逻辑
  },
  methods: {
    //  获取数据区域
    onLoad() {
      //获取数据真实逻辑。。。
      this.getPaymentRecord();
    },
    // 监护人端查询某个宝贝所有进出记录
    getPaymentRecord() {
      let req = {
        studentId: this.studentId,
        startTime: this.pickDateDisplay + " 00:00:00",
        endTime: this.pickDateDisplay + " 23:59:59",
        page: ++this.paymentRecord.page,
        size: 5,
      };
      reqGetPaymentRecord(req).then((res) => {
        console.log("res", res);
        if (res.data.code == 1) {
          this.paymentRecord.data = this.paymentRecord.data.concat(
            res.data.data.records
          );
          this.paymentRecord.page = res.data.data.current;
          this.paymentRecord.total = res.data.data.total;
          this.paymentRecord.totalPage = res.data.data.pages;

          // 关闭 loading 状态
          this.loading[this.active] = false;

          // 如果当前页已经是最后一页，设置 finished 为 true
          if (this.paymentRecord.page >= this.paymentRecord.totalPage) {
            this.finished[this.active] = true;
          }
        } else {
          this.$toast(res.data.message);
          // 关闭 loading 状态
          this.loading[this.active] = false;
        }
        console.log("获取后的数据", this.paymentRecord.data);
      });
    },
    back() {
      this.$router.push("/");
    },
    // 防抖
    debounce(func, wait) {
      let timeout;
      return function () {
        let context = this; // 保存this指向
        let args = arguments; // 拿到event对象
        console.log(arguments);
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          func.apply(context, args);
        }, wait);
      };
    },
    // 确定选择时间
    onConfirm() {
      this.finished = [false, false];
      this.paymentRecord = {
        page: 0,
        data: [],
        size: 5,
        total: 0,
        totalPage: 1,
      };
      this.datePickerShow = false;
      this.pickDateDisplay = this.formatDate(this.pickDate);
      this.getPaymentRecord();
    },

    // 修改日期格式
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份补零
      const day = date.getDate().toString().padStart(2, "0"); // 日期补零
      return `${year}-${month}-${day}`;
    },
  },
  activated() {
    this.$forceUpdate();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__nav--card {
  margin: 0px !important;
}
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    z-index: 2;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
  }
  .tabs_box {
    width: 100%;
    margin-top: 45px;
    min-height: 80vh;
    position: relative;
    ::v-deep .van-tabs__nav {
      width: 70%;
    }
    ::v-deep .van-tabs__wrap {
      background: white;
    }
    .selectTime {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      right: 15px;
      top: 10px;
      z-index: 1;
      color: gray;
      font-size: 14px;
    }
    .tabs {
      width: 100%;
      min-height: 80vh;
      .pick_up {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        .messages {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .message {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .message_box {
              width: calc(100% - 75px);
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 15px;
              margin-bottom: 15px;
              border-radius: 20px;
              background-color: white;
              .message_top {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 25px;
                .left {
                  display: flex;
                  align-items: center;
                  .title {
                    margin-right: 30px;
                  }
                  .time {
                    font-size: 13px;
                    color: #1684fc;
                  }
                }
              }
              .message_main {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-bottom: 10px;
                .detail {
                  width: calc(100% - 90px);
                  font-size: 14px;
                  padding-right: 5px;
                  .guardian {
                    margin-bottom: 10px;
                  }
                }
                .img_box {
                  width: 90px;
                  .img {
                    width: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }
      .abnormals {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .abnormal {
          width: calc(100% - 50px);
          height: 7s0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 15px;
          border-radius: 5px;
          background-color: #d3e7f7;
          .left {
            width: 100px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            font-size: 17px;
            font-weight: bold;
            color: white;
            border-radius: 5px 0 0 5px;
            background: #09c3b1;
          }
          .right {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 15px 12px 0px;
            font-size: 14px;
            .time {
              display: flex;
              align-items: center;
              text-align: center;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
