<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="当日离校接送"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- 背景 -->
    <div class="background_img"></div>

    <!-- 顶部 -->
    <div class="time">
      <van-popover
        v-model="showPopover"
        :actions="dates"
        @select="onSelectDate"
        placement="bottom-start"
      >
        <template #reference>
          <div class="date" @click="showPopover = !showPopover">
            {{ date + " " }}<van-icon name="arrow-down" />
          </div>
        </template>
      </van-popover>

      <div class="morning_afternoon">
        <van-tabs
          v-model="morning_afternoon"
          type="card"
          @change="onSelectMorning_afternoon"
        >
          <van-tab title="上午" name="0"></van-tab>
          <van-tab title="下午" name="1"></van-tab>
        </van-tabs>
      </div>
    </div>

    <div class="pick_value">
      <div
        class="left"
        v-if="this.pickupInfo.broadcastBasicRecordList != undefined"
      >
        {{ this.pickupInfo.broadcastBasicRecordList.length }}
      </div>
      <div
        class="right"
        v-if="this.pickupInfo.noBroadcastStudentList != undefined"
      >
        {{ this.pickupInfo.noBroadcastStudentList.length }}
      </div>
    </div>
    <div class="pick_nopick">
      <van-tabs v-model="pick_nopick" type="card" @change="onSelectPick_nopick">
        <van-tab title="已接送" name="0"></van-tab>
        <van-tab title="未接送" name="1"></van-tab>
      </van-tabs>
    </div>

    <!-- 表格 -->
    <table class="table" cellpadding="8">
      <tr>
        <th class="title" width="17%">姓名</th>
        <th class="title" width="17%">监护人</th>
        <th class="title" width="50%">接送时间</th>
        <th class="title">详情</th>
      </tr>
      <tr
        v-for="(item, index) in pick_nopick == 0
          ? pickupInfo.broadcastBasicRecordList
          : pickupInfo.noBroadcastStudentList"
        :key="index"
      >
        <td class="value">{{ item.studentName }}</td>
        <td class="value">{{ pick_nopick == 0 ? item.guardianName : "" }}</td>
        <td class="value">{{ pick_nopick == 0 ? item.broadcastTime : "" }}</td>
        <td class="value_detail" @click="jumpToDetail(item)">
          {{ pick_nopick == 0 ? "查看" : "" }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getPickupSummaryInfo } from "../api/teacher_api";
export default {
  data() {
    return {
      // 班级id
      clazzId: "1",
      // 接送信息
      pickupInfo: { broadcastBasicRecordList: [], noBroadcastStudentList: [] },
      // 选择日期下拉框
      showPopover: false,
      // 日期列表
      dates: [
        {
          text:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 1),
        },
        {
          text:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 2),
        },
        {
          text:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 3),
        },
      ],
      date:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
      // 选择上午还是下午 上午 11:00 - 13:00   下午 16:30-18:30
      morning_afternoon: "0",
      // 选择接了还是没接 0为接了 1为未接
      pick_nopick: "0",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "homepage") {
      to.meta.reload = true;
    }
    next();
  },
  created() {
    this.$route.meta.reload = true;
  },
  activated() {
    if (this.$route.meta.reload) {
      // 班级接送统计信息
      this.getPickupSummaryInfo();
      this.$route.meta.reload = false;
    }
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },

    // 获取接送统计数据
    getPickupSummaryInfo() {
      let req = {
        clazzId: this.$store.state.clazz.clazzId,
        startTime:
          this.date + (this.morning_afternoon == 0 ? " 11:00:00" : " 16:30:00"),
        endTime:
          this.date + (this.morning_afternoon == 0 ? " 13:30:00" : " 18:30:00"),
      };
      getPickupSummaryInfo(req).then((res) => {
        if (res.data.code == 1) {
          this.pickupInfo = res.data.data;
          console.log("this.pickupInfo", this.pickupInfo);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 选择日期
    onSelectDate(item) {
      this.date = item.text;
      this.showPopover = false;
      this.getPickupSummaryInfo();
    },

    // 选择上下午
    onSelectMorning_afternoon(name) {
      console.log(name);
      this.morning_afternoon = name;
      this.getPickupSummaryInfo();
    },

    // 选择接送还是未接送
    onSelectPick_nopick(name) {
      this.pick_nopick = name;
    },

    // 查看详情
    jumpToDetail(item) {
      console.log("点击详情");
      if (item.id != undefined) {
        this.$router.push(
          "/tabMessage/messageDetail/pickup?recordId=" + item.id
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    z-index: 10;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .background_img {
    width: 100%;
    height: 300px;
    background: url("../assets/tabmeicon/背景.png") no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: 0;
  }
  .time {
    width: calc(100% - 80px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    z-index: 1;
    .date {
      font-size: 20px;
      color: white;
    }
    .morning_afternoon {
      width: 50%;
      // border: 1px solid white;
      border-radius: 100px;
      ::v-deep .van-tabs__wrap {
        border-radius: 10px;
      }
      ::v-deep .van-tabs__nav {
        margin: 0;
      }
      ::v-deep .van-tab.van-tab--active {
        background-color: white;
        color: #09c3b1;
      }
      ::v-deep .van-tab {
        background-color: #09c3b1;
        color: white;
      }
    }
  }
  .pick_value {
    width: 35%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 35px;
    z-index: 1;
    margin-top: 30px;
  }
  .pick_nopick {
    width: 50%;
    z-index: 1;
    margin-top: 20px;
    border-radius: 100px;
    ::v-deep .van-tabs__wrap {
      border-radius: 10px;
    }
    ::v-deep .van-tabs__nav {
      margin: 0;
    }
    ::v-deep .van-tab.van-tab--active {
      background-color: white;
      color: #09c3b1;
    }
    ::v-deep .van-tab {
      background-color: #09c3b1;
      color: white;
    }
  }
  table,
  td,
  tr,
  th {
    border: 0.5px solid grey;
    border-collapse: collapse;
    font-size: 13px;
  }
  td {
    text-align: center;
  }
  .table {
    width: calc(100% - 50px);
    margin-top: 80px;
    .title {
      background-color: rgb(214, 214, 214);
    }
    .value_detail {
      color: rgb(0, 151, 238);
    }
  }
}
</style>
