import request from "../utils/request.js";
// homepage页面-------------------------------------------------------------------------
// 粗略查询本班到校统计(首页下部消息提醒)
export function getArriveRoughSummary(params) {
  return request({
    url: "/accessDevice/roughlyCountClazzAccess",
    method: "get",
    params: params,
  });
}
// 到校统计详情 、考勤汇总
export function attendanceSummaryInfo(params) {
  return request({
    url: "/accessDevice/countClazzAttendance",
    method: "get",
    params: params,
  });
}

// 班级接送统计
export function getPickupSummaryInfo(params) {
  // return axios.post(
  //   "broadcastDevice/countBroadcastRecordByClazzId",
  //   JSON.stringify(req),
  //   {
  //     headers: { "Content-Type": "application/json" },
  //     // headers: { token: "token" },
  //   }
  // );
  return request({
    url: "/broadcastDevice/countBroadcastRecordByClazzId",
    method: "get",
    params: params,
  });
}
// 查询单条接送记录详情
export function getSinglePickupRecord(params) {
  return request({
    url: "/broadcastDevice/selectBroadcastRecordByRecordId",
    method: "get",
    params: params,
  });
}

// 请假相关
// 审批请假记录并推送至家长端
export function judgeLeaveApplication(data) {
  // return axios.post("recess/checkRecessRecord", JSON.stringify(params), {
  //   headers: { "Content-Type": "application/json" },
  //   // headers: { token: "token" },
  // });
  return request({
    url: "/recess/checkRecessRecord",
    method: "post",
    data: data,
  });
}

// 查询本班请假粗略记录
export function getClassLeaveApplicationRecord(params) {
  // return axios.post("recess/selectRecessBasicRecord", JSON.stringify(req), {
  // headers: { "Content-Type": "application/json" },
  // headers: { token: "token" },
  // });
  return request({
    url: "/recess/selectRecessBasicRecord",
    method: "get",
    params: params,
  });
}
// 查询请假详情记录（监护人端和教师端都可以用）
export function getLeaveApplicationRecordDetail(req) {
  // return axios.get("recess/selectDetailedRecordById?" + qs.stringify(req), {
  // headers: { token: "token" },
  // });
  return request({
    url: "/recess/selectDetailedRecordById",
    method: "get",
    params: req,
  });
}

// 消息页面-------------------------------------------------------------------------
// 通过学生名字关键字搜索接送记录（没关键字也要查）(接送提醒)
export function getPickupRecords(params) {
  // return axios.post(
  //   "broadcastDevice/selectBroadcastBasicRecord",
  //   JSON.stringify(req),
  //   {
  //     headers: { "Content-Type": "application/json" },
  //     // headers: { token: "token" },
  //   }
  // );
  return request({
    url: "/broadcastDevice/selectBroadcastBasicRecord",
    method: "get",
    params: params,
  });
}

// 通过学生名字关键字搜索进出记录（没关键字也要查）(进出记录)
export function getInoutRecords(params) {
  // return axios.post(
  //   "accessDevice/selectAccessBasicRecord",
  //   JSON.stringify(req),
  //   {
  //     headers: { "Content-Type": "application/json" },
  //     // headers: { token: "token" },
  //   }
  // );
  return request({
    url: "/accessDevice/selectAccessBasicRecord",
    method: "get",
    params: params,
  });
}

// 查询进出记录详情  （两端通用）
export function getInoutRecordDetail(params) {
  // return axios.get("accessDevice/selectRecordById?" + qs.stringify(req), {
  //   // headers: { token: "token" },
  // });
  return request({
    url: "/accessDevice/selectRecordById",
    method: "get",
    params: params,
  });
}

// Me我的页面-------------------------------------------------------------------------
// 教师端查询本班某位学生详细信息 (通讯录详情)
export function getStudentConnectDetail(params) {
  // return axios.get("student/selectStudentInfo?" + qs.stringify(req), {
  //   // headers: { token: "token" },
  // });
  return request({
    url: "/student/selectStudentInfo",
    method: "get",
    params: params,
  });
}

// 教师端关键词查询本班学生粗略信息 (通讯录)
export function getStudentConnect(params) {
  // return axios.get(
  //   "student/selectStudentSomeInfoByClazzIdAndKeyword?" + qs.stringify(req),
  //   {
  //     // headers: { token: "token" },
  //   }
  // );
  return request({
    url: "/student/selectStudentSomeInfoByClazzIdAndKeyword",
    method: "get",
    params: params,
  });
}

// 修改教师自己的信息   已对
export function changeSelfInfo(data) {
  // return axios.post("teacher/updateTeacherInfo", JSON.stringify(req), {
  //   headers: { "Content-Type": "application/json" },
  //   // headers: { token: "token" },
  // });
  return request({
    url: "/teacher/updateTeacherInfo",
    method: "post",
    data: data,
  });
}
// 修改教师自己的密码   已对
export function changeSelfPassword(data) {
  // return axios.post("account/updatePassword", JSON.stringify(req), {
  //   headers: { "Content-Type": "application/json" },
  //   // headers: { token: "token" },
  // });
  return request({
    url: "/account/updatePassword",
    method: "post",
    data: data,
  });
}

// 教师端查询个人信息
export function getSelfInfo(params) {
  // return axios.get("teacher/selectTeacherBasicInfo?" + qs.stringify(req), {});
  return request({
    url: "/teacher/selectTeacherBasicInfo",
    method: "get",
    params: params,
  });
}
