<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      title="缴费管理"
      left-text="返回"
      left-arrow
      @click-left="back"
    />

    <div class="backimg">
      <div class="greetTitle">
        <div class="text">
          {{ this.guardianName + "家长，您好" }}
        </div>
        <van-popover
          v-model="changeVisible"
          :actions="this.children"
          @select="onConfirm"
          placement="bottom-start"
        >
          <template #reference>
            <span class="change_child" @click="changeVisible = !changeVisible">
              <van-icon class="icon" name="sort" />
              {{ child ? child.text : "切换宝贝" }}
            </span>
          </template>
        </van-popover>
      </div>
      <div class="headimg_box">
        <img class="headimg" :src="this.headImgUrl" alt="" width="50px" />
      </div>
    </div>
    <div class="notice-box">
      <div class="notice-text" v-html="content"></div>
      <van-button v-if="canRenew" type="danger" class="renew" @click="renew">{{
        btnValue
      }}</van-button>
    </div>
    <!-- 弹窗 -->
    <van-dialog
      v-model="isNeedToNotify"
      title="提示"
      show-cancel-button
      confirm-button-text="去开启"
      cancel-button-text="关闭"
      @confirm="renew"
      @cancel="isNeedToNotify = false"
    >
      <div class="dialog-content" v-html="dialogContent"></div>
    </van-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { reqRenew } from "@/api/payment_api.js";
import { reqGetAllStudent } from "@/api/student_api";

export default {
  name: "paymentManagement",
  computed: {
    ...mapState({
      type: "type",
      headImgUrl: "headImgUrl",
      teacherName: "teacherName",
      guardianName: "guardianName",
      clazz: "clazz",
      child: "child",
    }),
    ...mapGetters({
      classes: "getClasses",
      children: "getChildren",
    }),
  },
  created() {
    this.messagePermissionEndTime = new Date(
      this.$store.state.schoolInfo.semester.endTime
    )
      .toLocaleString("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour12: false,
      })
      .replace(/\//g, "年")
      .replace(/\//g, "月");
    this.validateNeedToRenew();
  },
  data() {
    return {
      canRenew: false,
      changeVisible: false,
      expireTime: "",
      isNeedToNotify: false,
      content: undefined,
      dialogContent: undefined,
      messagePermissionEndTime: undefined,
      schoolInfo: undefined,
      btnValue: "立即续费",
      wxPayParams: undefined,
    };
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    onConfirm(item) {
      this.changeVisible = false;
      console.log("切换小孩");
      this.$store.commit("setChild", item);
      console.log(this.child);
      this.validateNeedToRenew();
    },
    validateNeedToRenew() {
      this.canRenew = false;
      let studentId = this.child.studentId;
      this.schoolInfo = this.$store.state.schoolInfo;
      let student;
      console.log("关联所有学生::", this.$store.state.childrenV2);
      this.$store.state.childrenV2.forEach((item) => {
        if (item.id === studentId) {
          student = item;
        }
      });
      console.log("当前学生" + student);
      if (student.semester) {
        if (student.semester.id === this.schoolInfo.semester.id) {
          this.content =
            "您的孩子:" +
            `<span style="color:red;">${student.studentName}</span>` +
            "当前学期已缴费，无需再次开通";
        } else {
          let now = new Date().getTime();
          if (now > student.semester.endTime) {
            //已经不在有效期了，需要重新开通
            this.content =
              "您的孩子消息权限" +
              `<span style="color:red;">${student.studentName}</span>` +
              "当前学期未缴费，请先缴费";
            this.btnValue = "立即缴费";
          } else {
            //还在有效期，到提醒期则提醒
            let days =
              (this.schoolInfo.semester.endTime - now) / (1000 * 60 * 60 * 24);
            if (days <= this.schoolInfo.reminderDaysBefore) {
              this.content =
                "您的孩子消息权限" +
                `<span style="color:red;">${
                  student.studentName
                }</span>还${days.toFixed(1)}天到期，如有需要请及时续费`;

              this.isNeedToNotify = true;
              this.dialogContent =
                "您的孩子消息权限" +
                `<span style="color:red;">${student.studentName}</span>` +
                "即将到期，请尽快续费";
              this.btnValue = "立即缴费";
            }
            this.canRenew = true;
          }
        }
      } else {
        this.content =
          "您的孩子:" +
          `<span style="color:red;">${student.studentName}</span>` +
          "还未开通消息权限，如有需要，请开通";
        this.btnValue = "立即开通";
        this.canRenew = true;
      }
    },
    renew() {
      let data = {
        studentId: this.child.studentId,
        openId: this.$store.state.openid,
        schoolId: localStorage.getItem("schoolId"),
      };

      //来个提示，确定要续费吗
      this.$dialog
        .confirm({
          title: "提示",
          message: `确定要开启权限到<span style="color: red;">${this.messagePermissionEndTime}日</span>吗？<br/><span style="color: red; margin-top:10px">温馨提示：缴费时请保持网络良好，防止发生意外情况，且不要多次支付，支付后请重新进入公众号查看，如有意外情况，请及时联系管理员</span>`,
        })
        .then(() => {
          reqRenew(data).then((res) => {
            console.log(res);
            if (res.data.code === 1) {
              this.toPay(res.data.data);
            }
          });
        })
        .catch(() => {
          return;
        });
    },
    toPay(data) {
      let {
        appId,
        timeStamp,
        nonceStr,
        package: packageStr,
        signType,
        paySign,
      } = data;
      console.log("预下单数据：", data);
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId, // 微信的appid
          timeStamp, //时间戳
          nonceStr, //随机串
          package: packageStr, // 订单号
          signType, //微信签名方式：
          paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
          }
        }
      );
    },
    async getAllStudent() {
      await reqGetAllStudent().then((res) => {
        console.log("所有学生", res.data.data);
        if (res.data.code === 1) {
          this.$store.commit("setChildrenV2", res.data.data);
        } else {
          this.$toast("获取学生列表失败,请重新进入。");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.backimg {
  width: 100%;
  background: url("../assets/homepagebackimg.png") no-repeat;
  background-position: center;
  height: 220px;
  background-size: cover;
  .box {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 25px;
    font-size: 16px;
    .headimg_box {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 5px;
      .headimg {
        border-radius: 50%;
      }
    }
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .text {
          margin-right: 40px;
        }
        .change_class {
          font-size: 16px;
          color: rgb(204, 18, 55);
          .icon {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  // 监护人端
  .greetTitle {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 20px;
    .text {
      padding-right: 40px;
    }
    .change_child {
      font-size: 16px;
      color: rgb(204, 18, 55);
      .icon {
        transform: rotate(90deg);
        // ms-transform: rotate(90deg); /* IE 9 */
        // moz-transform: rotate(90deg); /* Firefox */
        // webkit-transform: rotate(90deg); /* Safari and Chrome */
        // o-transform: rotate(90deg); /* Opera */
      }
    }
  }
}
.headimg_box {
  padding-left: 20px;
  .headimg {
    border-radius: 50%;
  }
}
.notice-box {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  .notice-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }

  .van-button {
    width: 100%;
    max-width: 200px;
  }
}
.renew {
  //圆角
  border-radius: 12px;
  //阴影
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  //背景颜色
  //   background-color: #ffffff;
  //宽度
  width: 80%;
  //最大宽度
  max-width: 400px;
  //居中
  margin-left: auto;
  margin-right: auto;
  //内边距
  padding: 15px;
  //外边距
  margin-top: 20px;
  //字体大小
}
</style>
