import request from "../utils/request.js";

// 登录接口
export function login(data) {
  return request({
    url: "/account/login",
    method: "post",
    data: data,
  });
}
// 退出登录
export function logout() {
  return request({
    url: "/account/logout",
    method: "get",
  });
}
// 修改密码
export function changePassword(data) {
  return request({
    url: "/account/updatePassword",
    method: "post",
    data: data,
  });
}
// 获取UserInfo，验证登录结果，只能用一次
export function getUserInfo(req) {
  console.log("调用获取UserInfo接口，参数:", req);
  return request({
    url: "/account/getAuthorizeResult",
    method: "get",
    params: req,
  });
}
// 获取当前角色未读消息数量
export function getUnreadCount() {
  return request({
    url: "/account/countMessage",
    method: "get",
  });
}
// 上传照片（返回照片路径）
export function upload(data) {
  return request({
    url: "/account/upload",
    method: "post",
    data: data,
  });
}
// 调用上传照片之后，通过这个接口把获取到的图片路径更新到学生信息里面（家长和老师都可以调用这个接口）
export function updateStudentPhoto(req) {
  return request({
    url: "/account/updateStudentPhoto",
    method: "post",
    data: req,
  });
}

export function register(data) {
  return request({
    url: "/newStudent/register",
    method: "post",
    data: data,
  });
}
