/**
 * 工具函数
 */

// 验证账号
export const checkAccount = (rule, value, callback) => {
  if (!rule.is_check) {
    callback();
  } else {
    if (!value) {
      callback(new Error("请输入账号"));
    } else if (!rule.reg.test(value)) {
      callback(new Error("3-12位（字母，数字，中文）"));
    } else {
      callback();
    }
  }
};

// 验证密码
export const checkPassword = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入密码"));
  } else if (!/^[a-zA-Z0-9]{3,12}$/.test(value)) {
    callback(new Error("3-12位（字母，数字）"));
  } else {
    callback();
  }
};
