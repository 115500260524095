import axios from "axios";
import store from "@/store";
import router from "../router";
import qs from "qs";
var _baseURL;
// mock模拟
// _baseURL = 'https://mock.apipost.cn/app/mock/project/1d16c4f5-55c4-4596-9009-4497259ec86d/';
// 服务器ip
// _baseURL = 'http://1.12.235.13:80/';
// 本地测试专用
// _baseURL = "http://xyue.mynatapp.cc/api/";
// _baseURL = "http://127.0.0.1:8090/";
// 服务器域名
// _baseURL = "https://dwxx.liuyunchuan.com/api/";
// _baseURL = "http://shjj.ubooking.cn/api/";
// _baseURL = "http://wx.ubooking.cn/api/";
_baseURL = "https://wxtest.ubooking.cn/api/";
const service = axios.create({
  baseURL: _baseURL,
  timeout: 600000,
});
axios.defaults.retry = 4; // 重试次数
axios.defaults.retryDelay = 4000; // 重试间隔
axios.defaults.withCredentials = true; // 跨域传递cookies
// 加载
// let loading;
// function startLoading() {
//     loading = Loading.service({
//         lock: true,
//         text: '加载中....',
//         background: 'rgba(0, 0, 0, 0.7)'
//     })
// }

// function endLoading() {
// loading.close()
// }

// 请求拦截
service.interceptors.request.use(
  (config) => {
    // startLoading();
    config.headers["Authentication"] = localStorage.getItem("token");
    // console.log(config.method)
    // 判断请求方法，如果是POST或PUT，添加schoolId到请求体
    // if (config.method == "post" || config.method == "put") {
    //   console.log("post");
    //   // 如果 config.data 是 FormData 实例，则直接处理
    //   if (config.data instanceof FormData) {
    //     // 处理 FormData 对象
    //     const formData = config.data;
    //     // 添加 schoolId 参数
    //     formData.append("schoolId", localStorage.getItem("schoolId"));
    //     // 重新赋值回 config.data
    //     config.data = formData;
    //   } else {
    //     let data = {};
    //     // 如果 config.data 是字符串类型，解析为对象
    //     if (typeof config.data === "string") {
    //       data = JSON.parse(config.data);
    //     } else {
    //       // 否则假设它是一个对象
    //       data = config.data;
    //     }
    //     // 添加 schoolId 参数
    //     // data.schoolId = 1;
    //     data.schoolId = localStorage.getItem("schoolId"); // 添加schoolId参数
    //     // 重新将对象转换为 JSON 字符串并赋值回 config.data
    //     config.data = JSON.stringify(data);
    //   }
    // }
    // // 如果是GET请求，可以在请求参数中添加schoolId
    // if (config.method == "get") {
    //   console.log("get");
    //   const params = {
    //     ...config.params,
    //     schoolId: localStorage.getItem("schoolId"),
    //   };
    //   // const params = { ...config.params, schoolId : 111};
    //   config.params = params;
    // }

    return config;
  },
  (error) => {
    // endLoading();
    // eslint-disable-next-line no-undef
    console.log(err);
    return Promise.reject(error);
  }
);
// service.interceptors.request.use((config) => {
//     return config;
// });

// 响应拦截
service.interceptors.response.use(
  function (response) {
    // endLoading()
    return response;
  },
  (err) => {
    // endLoading()
    // token不合法
    console.log(err);
    if (err.response.status == 401) {
      this.$toast.fail("账号状态已改变，请重新登录");
      console.log("需要登陆");
      router.replace("/login");
      // 粒子微校
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa9991f1c4fbe89bb&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect"
      // 大晚小学
      // window.location.href =
      //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx09f055c813056224&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    }
    console.log("请求错误信息", err.response);
    return Promise.reject(err.response.data);
  }
);

export default service;
