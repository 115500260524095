<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="公告"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- <div class="top">
            <div class="top_left" @click="back()">
                <van-icon name="arrow-left" />
                返回
            </div>
            <div class="top_center">
                消息
            </div>
            <div class="top_right">
                <van-icon name="plus" />
            </div>
        </div> -->
    <!-- 搜索下面 -->
    <div class="up">
      <!-- form层是为了ios显示 -->
      <form action="/">
        <van-search
          v-model="value"
          placeholder="请输入搜索关键词"
          @search="onSearch"
          @cancel="onCancel"
        />
      </form>
      <div class="up_down">
        <div class="announcement">
          <div class="btn1">
            <van-icon name="bell" />
          </div>
          <div>学校公告</div>
        </div>
        <div class="divide"></div>
        <div class="announcement">
          <div class="btn2">
            <van-icon name="bell" />
          </div>
          <div>教育局公告</div>
        </div>
      </div>
    </div>
    <!-- 详细消息 -->
    <div class="messages">
      <div class="message" @click="toDetail()">
        <div class="box">
          <van-badge dot>
            <div class="btn1">
              <van-icon name="bell" />
            </div>
          </van-badge>
          <div class="right">
            <div class="right_top">
              <div class="title">学校公告</div>
              <div class="time">3-19</div>
            </div>
            <div class="detail">临时断电balabalabalabalabalab</div>
          </div>
        </div>
      </div>

      <div class="message" @click="toDetail()">
        <div class="box">
          <van-badge dot>
            <div class="btn2">
              <van-icon name="bell" />
            </div>
          </van-badge>
          <div class="right">
            <div class="right_top">
              <div class="title">教育局公告</div>
              <div class="time">3-19</div>
            </div>
            <div class="detail">
              临时断电临时断电临时断电临时断电临时断电临时断电临时断电临时断电
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import teacher_api from "../api/teacher_api"
import guardian_api from "../api/guardian_api"
export default {
  data() {
    return {
      // 搜索
      value: "",
    };
  },
  methods: {
    // 返回
    back() {
      this.$router.push("/");
    },
    // 搜索
    onSearch(val) {
      this.$toast(val);
    },
    onCancel() {
      this.$toast("取消");
    },
    // 点击跳转到公告详情
    toDetail() {
      this.$router.push("/tabAnnouncement/announcementDetail");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // color: white;
    // background-color: #3b7aff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    // }
    // .top_right{
    //     position: absolute;
    //     right: 15px;
    //     font-size: 20px;
    // }
  }
  .up {
    width: 100%;
    background-color: white;
    .search {
      padding: 20px;
    }
    .up_down {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .announcement {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .btn1 {
          width: 45px;
          height: 45px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          font-size: 25px;
          color: white;
          border-radius: 5px;
          background-color: #3bcaff;
        }
        .btn2 {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          font-size: 25px;
          color: white;
          border-radius: 5px;
          background-color: #3b7aff;
        }
      }
      .divide {
        width: 0.5px;
        height: 350px;
        background-color: #e4e3e6;
        transform-origin: center;
        transform: scaleY(0.2);
        margin: 30px;
      }
    }
  }
  .messages {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    .message {
      width: 100%;
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      background-color: white;
      .box {
        width: calc(100% - 30px);
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn1 {
          width: 50px;
          height: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: white;
          border-radius: 5px;
          background-color: #3bcaff;
        }
        .btn2 {
          width: 50px;
          height: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: white;
          border-radius: 5px;
          background-color: #3b7aff;
        }
        .right {
          width: calc(100% - 20px);
          margin-left: 10px;
          .right_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 8px;
            .title {
            }
            .time {
              font-size: 13px;
              color: #8c8c8c;
            }
          }
          .detail {
            width: 100%;
            font-size: 13px;
            color: #8c8c8c;
          }
        }
      }
    }
  }
}
</style>