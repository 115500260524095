<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="学生列表"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- 搜索框 -->
    <div class="search">
      <van-search
        v-model="searchKey"
        shape="round"
        placeholder="搜索学生名字"
      />
    </div>
    <div class="index">
      <!-- 索引列表 -->
      <van-index-bar id="bar" highlight-color="#09c3b1" scrollTo="1000">
        <van-index-anchor index="A" v-if="this.orderList['A']" />
        <van-cell
          v-for="item in orderList['A']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="B" v-if="this.orderList['B']" />
        <van-cell
          v-for="item in orderList['B']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="C" v-if="this.orderList['C']" />
        <van-cell
          v-for="item in orderList['C']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="D" v-if="this.orderList['D']" />
        <van-cell
          v-for="item in orderList['D']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="E" v-if="this.orderList['E']" />
        <van-cell
          v-for="item in orderList['E']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="F" v-if="this.orderList['F']" />
        <van-cell
          v-for="item in orderList['F']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="G" v-if="this.orderList['G']" />
        <van-cell
          v-for="item in orderList['G']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="H" v-if="this.orderList['H']" />
        <van-cell
          v-for="item in orderList['H']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="I" v-if="this.orderList['I']" />
        <van-cell
          v-for="item in orderList['I']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="J" v-if="this.orderList['J']" />
        <van-cell
          v-for="item in orderList['J']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="K" v-if="this.orderList['K']" />
        <van-cell
          v-for="item in orderList['K']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="L" v-if="this.orderList['L']" />
        <van-cell
          v-for="item in orderList['L']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="M" v-if="this.orderList['M']" />
        <van-cell
          v-for="item in orderList['M']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="N" v-if="this.orderList['N']" />
        <van-cell
          v-for="item in orderList['N']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="O" v-if="this.orderList['O']" />
        <van-cell
          v-for="item in orderList['O']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="P" v-if="this.orderList['P']" />
        <van-cell
          v-for="item in orderList['P']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="Q" v-if="this.orderList['Q']" />
        <van-cell
          v-for="item in orderList['Q']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="R" v-if="this.orderList['R']" />
        <van-cell
          v-for="item in orderList['R']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="S" v-if="this.orderList['S']" />
        <van-cell
          v-for="item in orderList['S']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="T" v-if="this.orderList['T']" />
        <van-cell
          v-for="item in orderList['T']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="U" v-if="this.orderList['U']" />
        <van-cell
          v-for="item in orderList['U']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="V" v-if="this.orderList['V']" />
        <van-cell
          v-for="item in orderList['V']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="W" v-if="this.orderList['W']" />
        <van-cell
          v-for="item in orderList['W']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="X" v-if="this.orderList['X']" />
        <van-cell
          v-for="item in orderList['X']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="Y" v-if="this.orderList['Y']" />
        <van-cell
          v-for="item in orderList['Y']"
          :key="item.studentName"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />

        <van-index-anchor index="Z" v-if="this.orderList['Z']" />
        <!--<van-cell -->
        <!--  v-for="item in orderList['Z']"-->
        <!--  :key="index - 1" -->
        <!--  :title="item.studentName" -->
        <!--  @click="jumpToDetail(item)"/>-->
        <van-cell
          v-for="item in orderList['Z']"
          :key="item.studentId"
          :title="item.studentName"
          @click="jumpToDetail(item)"
        />
      </van-index-bar>
    </div>
  </div>
</template>

<script>
import { getStudentConnect } from "@/api/teacher_api";
import getFirstLetter from "./name";
export default {
  data() {
    return {
      // 搜索关键字
      searchKey: "",
      // 学生列表
      list: [],
      // 排序后列表
      orderList: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "connectDetail") {
      to.meta.reload = true;
    }
    next();
  },
  created() {
    this.$route.meta.reload = true;
  },
  activated() {
    if (this.$route.meta.reload) {
      // 教师端关键词查询本班学生粗略信息 (通讯录)
      this.getStudentConnect();
      this.$route.meta.reload = false;
    }
  },
  watch: {
    searchKey: function () {
      this.getStudentConnect();
    },
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },

    // 教师端关键词查询本班学生粗略信息 (通讯录)
    getStudentConnect() {
      let req = {
        clazzId: this.$store.state.clazz.clazzId,
        keyword: this.searchKey,
      };
      getStudentConnect(req).then((res) => {
        if (res.data.code == 1) {
          this.orderList = {};
          this.list = res.data.data;
          console.log("this.list", this.list);
          this.deal();
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 处理学生列表 按姓氏排序分开
    deal() {
      this.list.map((item) => {
        var char = getFirstLetter.makePy(item.studentName)[0].slice(0, 1);
        if (this.orderList[char]) {
          this.orderList[char].push(item);
        } else {
          let newList = [];
          this.orderList[char] = newList;
          this.orderList[char].push(item);
        }
      });
      console.log(this.orderList);
      this.$forceUpdate();

      // var str = ""
      // for(let key in this.orderList){
      //   str = str +  "<van-index-anchor index='" + key + "' />"
      //   // newIndex.setAttribute("index", key)
      //   // document.getElementById("bar").appendChild(newIndex)
      //   this.orderList[key].map(item => {
      //     str = str + "<van-cell title='" + item.studentName + "' @click='jumpToDetail("+ item.studentId +")'/>"
      //   })
      // }
      // console.log(str);
      // document.getElementById("bar").innerHTML = str
      // this.$forceUpdate()
    },

    // 查询学生信息详情
    jumpToDetail(item) {
      this.$router.push(
        "/tabMe/connect/3/connectDetail?studentId=" + item.studentId
      );
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .search {
    width: 100%;
    margin-top: 45px;
  }
  .index {
    width: 100%;
    overflow: auto;
    ::v-deep .van-index-bar__sidebar {
      right: 3px;
      .van-index-bar__index {
        font-size: 15px;
        margin-bottom: 5px;
        text-align: center;
      }
    }
  }
}
</style>
