<template>
  <!-- 教师和家长的通讯录 -->
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      :title="this.topTitle"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- <div class="top">
            <div class="top_left" @click="back()">
                <van-icon name="arrow-left" />
                返回
            </div>
            <div class="top_center">
                教师/家长信息
            </div>
        </div> -->
    <!-- 搜索框 -->
    <!-- <div class="search">
      <van-search
        v-model="search"
        shape="round"
        placeholder="搜索老师/家长名字"
      />
    </div> -->
    <!-- 老师信息 -->
    <div class="teachers">
      <div class="teacher" v-for="(item, index) in teacherList" :key="index">
        <div class="top">
          <div class="head_img_box">
            <div :class="{ head_img: true, head_img_teacher: type == 1 }">
              {{
                item.teacherName.slice(
                  item.teacherName.length - 2,
                  item.teacherName.length
                )
              }}
            </div>
          </div>
          <div class="detail_box">
            <div class="detail">
              <div class="name">{{ item.teacherName }}</div>
            </div>
            <div class="job_number">
              <div class="job">{{ item.teacherRoleName }}</div>
              <div class="detail" @click="toDetail(item)">详情</div>
            </div>
          </div>
        </div>
        <div class="divide"></div>
      </div>
    </div>
    <!-- 家长信息 -->
    <div class="teachers">
      <div class="teacher" v-for="(item, index) in guardianList" :key="index">
        <div class="top">
          <div class="head_img_box">
            <div :class="{ head_img: true, head_img_guardian: type == 2 }">
              {{
                item.guardianName.slice(
                  item.guardianName.length - 2,
                  item.guardianName.length
                )
              }}
            </div>
          </div>
          <div class="detail_box">
            <div class="detail">
              <div class="name">{{ item.guardianName }}</div>
            </div>
            <div class="job_number">
              <!-- <div class="job">{{ item.guardianTypeName }}</div> -->
              <div class="detail" @click="toDetail(item)">详情</div>
            </div>
          </div>
        </div>
        <div class="divide"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStudentGuardianInfoList,
  getTeacherDetail,
} from "@/api/guardian_api";

export default {
  data() {
    return {
      // 类型 1为查看教师 2是查看家长
      type: "1",
      // 标题
      topTitle: "教师信息",
      // 搜索关键字
      search: "",
      // 家长列表粗略信息
      guardianList: "",
      // 教师列表
      teacherList: "",
    };
  },
  mounted() {
    // 获取url上的type 1为教师列表 2为家长列表
    if (this.$route.params.type == 2) {
      this.type = 2;
      this.topTitle = "家长信息";
      // 获取监护人列表粗略信息
      this.getStudentGuardianInfoList();
    } else {
      this.type = 1;
      // 监护人通过班级id查询班主任电话(通讯录)
      this.getTeacherDetail();
    }
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 跳转到详情
    toDetail(item) {
      if (this.$route.params.type == 1) {
        // 去教师详情
        this.$router.push({
          path: "/tabMe/connect/" + this.$route.params.type + "/connectDetail",
          query: {
            teacherName: item.teacherName,
            teacherPhone: item.teacherPhone,
            teacherRoleName: item.teacherRoleName,
            teacherEmail: item.teacherEmail,
          },
        });
      } else {
        // 去监护人详情
        this.$router.push({
          path: "/tabMe/connect/" + this.$route.params.type + "/connectDetail",
          query: {
            guardianId: item.guardianId,
            guardianName: item.guardianName,
            guardianTypeName: item.guardianTypeName,
            guardianPhone: item.guardianPhone,
          },
        });
      }
    },

    // 获取监护人列表粗略信息
    getStudentGuardianInfoList() {
      getStudentGuardianInfoList({
        studentId: this.$store.state.child.studentId,
      }).then((res) => {
        if (res.data.code == 1) {
          this.guardianList = res.data.data;
          console.log("this.guardianList", this.guardianList);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 监护人通过班级id查询班主任电话(通讯录 包括详细信息)
    getTeacherDetail() {
      let req = {
        clazzId: this.$store.state.clazz.clazzId,
      };
      getTeacherDetail(req).then((res) => {
        if (res.data.code == 1) {
          this.teacherList = res.data.data;
          console.log("this.teacherList", this.teacherList);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 45px;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .search {
    width: 100%;
  }
  .teachers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    overflow: auto;
    .teacher {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 30px;
      .top {
        width: calc(100% - 40px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .head_img_box {
          width: 85px;
          height: 85x;
          display: flex;
          align-items: center;
          margin-right: 15px;
          .head_img {
            width: 85px;
            height: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            // font-size: 20px;
            font-size: 14px;
            text-indent: 10px;
            letter-spacing: 10px;
            font-weight: bold;
            color: white;
            &.head_img_guardian {
              background-image: linear-gradient(
                rgb(77, 148, 255),
                rgb(21, 103, 226)
              );
              width: 60px;
              height: 60px;
            }
            &.head_img_teacher {
              background-image: linear-gradient(
                rgb(255, 142, 49),
                rgb(243, 115, 10)
              );
              width: 60px;
              height: 60px;
            }
          }
        }
        .detail_box {
          width: 100%;
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          .detail {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            flex-direction: column;
            .name {
              font-size: 19px;
              font-weight: bold;
              margin-bottom: 5px;
            }
            .describe {
              color: grey;
            }
          }
          .job_number {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            .job {
              width: 70px;
              height: 23px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              background-color: #09d0b2;
              border-radius: 10px;
              margin-bottom: 35px;
            }
            .detail {
              height: 15px;
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              padding: 2px;
              margin-right: 5px;
              border: 1px solid grey;
              border-radius: 5px;
              background-color: white;
            }
          }
        }
      }
      .divide {
        width: calc(100% - 30px);
        left: 0;
        bottom: 0;
        height: 10.5px;
        border-bottom: 1px solid #c1c0c5;
        transform-origin: center;
        transform: scaleY(0.2);
        margin-top: 30px;
      }
    }
  }
}
</style>
