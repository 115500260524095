<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      :title="
        this.flag == 3 ? '修改密码' : this.flag == 4 ? '修改手机' : '修改邮箱'
      "
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="change_password" v-if="this.flag == 3">
      <div class="input_box">
        <van-field
          class="input"
          v-model="password"
          label="旧密码"
          type="password"
          required
          placeholder="请输入密码"
        />
        <van-field
          class="input1"
          v-model="password1"
          label="新密码"
          type="password"
          required
          placeholder="请输入新密码"
        />
        <van-field
          class="input1"
          v-model="password2"
          label="确认密码"
          type="password"
          required
          placeholder="请再次输入新密码"
        />
      </div>
      <van-button class="submit" color="#09c3b1" @click="submit"
        >确认提交</van-button
      >

      <div class="forget" @click="show = !show">忘记密码</div>

      <van-overlay :show="show" @click="show = false">
        <div class="wrapper">
          <div class="block">
            <div class="title">
              <van-icon name="checked" color="green" /> 遗忘密码请联系学校管理员
            </div>
            <div class="detail">
              请致电学校管理员，提供绑定的手机号码、监护人姓名、学生姓名。由管理员为您进行密码重置。
            </div>
            <van-button class="submit" color="#09c3b1" @click="show = false"
              >确定</van-button
            >
          </div>
        </div>
      </van-overlay>
    </div>

    <div class="change_phone_email" v-if="this.flag == 4">
      <van-field
        class="input"
        v-model="newTel"
        type="tel"
        required
        label="手机号"
        placeholder="请输入新手机号"
      />
      <van-button class="submit" color="#09c3b1" @click="submit"
        >确认提交</van-button
      >
    </div>

    <div class="change_phone_email" v-if="this.flag == 5">
      <van-field
        class="input"
        v-model="emailAdress"
        required
        label="邮箱地址"
        placeholder="请输入新邮箱地址"
      />
      <van-button class="submit" color="#09c3b1" @click="submit"
        >确认提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { changeSelfPassword, changeSelfInfo } from "@/api/teacher_api";
import { changePhonNumber } from "@/api/guardian_api";
export default {
  data() {
    return {
      // 1为教师端 2为监护人端
      type: "1",
      // 修改密码3  修改手机4  邮箱5
      flag: "",
      tel: "",
      password: "",
      // 新密码
      password1: "",
      password2: "",
      // 忘记密码弹窗
      show: false,

      // 修改手机
      newTel: "",
      emailAdress: "",
    };
  },
  watch: {
    newTel: function (val) {
      this.newTel = val.replace(/\D/g, "");
    },
    tel: function (val) {
      this.tel = val.replace(/\D/g, "");
    },
    emailAdress: function (val) {},
  },
  mounted() {
    // 获取教师端还是监护人端
    this.type = this.$store.state.type;
    console.log("type=" + this.type);
    this.flag = this.$route.query.flag;
    console.log("flag=" + this.flag);
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },

    // 修改密码 两端通用
    changeSelfPassword() {
      let req = {
        oldPassword: this.password,
        newPassword: this.password2,
        openid: this.$store.state.openid,
        userId:
          this.type == 1
            ? this.$store.state.teacherId
            : this.$store.state.guardianId,
        userRole: this.type == 1 ? "teacher" : "guardian",
      };
      changeSelfPassword(req).then((res) => {
        if (res.data.code == 1) {
          this.$toast("修改成功！");
          this.$router.go(-1);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 教师修改自己的信息
    changeSelfInfo(req) {
      changeSelfInfo(req).then((res) => {
        if (res.data.code == 1) {
          this.$toast("修改成功！");
          this.$router.go(-1);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 监护人修改自己的手机
    changePhonNumber() {
      let req = {
        guardianId: this.$store.state.guardianId,
        guardianPhone: this.newTel,
      };
      changePhonNumber(req).then((res) => {
        if (res.data.code == 1) {
          this.$toast("修改成功！");
          this.$router.go(-1);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 提交
    submit() {
      if (this.flag == 3) {
        // 修改密码
        if (this.password == "") {
          this.$toast("请输入旧密码！");
          return;
        } else if (this.password1 == "" && this.password2 == "") {
          this.$toast("新密码不能为空！");
          return;
        } else if (this.password1 != this.password2) {
          this.$toast("两次密码不一致！");
          return;
        } else {
          this.changeSelfPassword();
        }
      } else if (this.flag == 4) {
        // 修改手机验证
        if (this.newTel.length != 11) {
          this.$toast("手机号格式错误 !");
          return;
        }
        // 教师修改手机
        if (this.type == 1) {
          let req = {
            teacherId: this.$store.state.teacherId,
            teacherPhone: this.newTel,
          };
          this.changeSelfInfo(req);
        }
        // 家长修改手机
        else {
          this.changePhonNumber();
        }
      } else {
        // 修改邮箱
        if (this.type == 1) {
          if (
            !this.emailAdress.match(
              /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              ""
            )
          ) {
            this.$toast("邮箱格式错误!");
            return;
          }
          let req = {
            teacherId: this.$store.state.teacherId,
            email: this.emailAdress,
          };
          this.changeSelfInfo(req);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .change_password {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input_box {
      width: 100%;
      padding-left: 15px;
      box-sizing: border-box;
      background-color: white;
      .input {
        margin-top: 60px;
      }
      .input,
      .input1 {
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 17px;
      }
    }
    .submit {
      width: calc(100% - 20px);
      margin-top: 30px;
      font-size: 17px;
    }
    .forget {
      width: calc(100% - 20px);
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      font-size: 17px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .block {
      width: 70%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 10px;
      background-color: #fff;
      .title {
        font-size: 17px;
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
  }
  .change_phone_email {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input {
      margin-top: 60px;
    }
    .input,
    .input1 {
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    .submit {
      width: calc(100% - 20px);
      margin-top: 30px;
      font-size: 20px;
    }
    .forget {
      width: calc(100% - 20px);
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      font-size: 17px;
    }
  }
}
</style>
