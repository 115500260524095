<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="请假申请"
      left-text="返回"
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <!-- <div class="tip">
      {{"温馨提示：提交审批后，将由班主任进行审批！"}}
    </div> -->
    <van-notice-bar
      class="tip"
      scrollable
      text="提交审批后，将由班主任进行审批！"
    />

    <!-- <div class="top">
            <div class="top_left" @click="back()">
                <van-icon name="arrow-left" />
                返回
            </div>
            <div class="top_center">
                请假申请
            </div>
        </div> -->
    <!-- 表格 -->
    <div class="form">
      <div class="item">
        <div class="title">请假类型</div>
        <div class="chose" @click="typeShow = true">
          {{
            type == "1"
              ? "事假"
              : type == "2"
              ? "病假"
              : type == "0"
              ? "其他"
              : "请选择请假类型"
          }}
          <van-icon class="icon" name="arrow" />
        </div>
        <van-action-sheet
          v-model="typeShow"
          :close-on-click-action="true"
          :actions="types"
          @select="onSelectType"
        />
      </div>

      <div class="item">
        <div class="title">开始时间</div>
        <div class="chose" @click="startDateShow = true">
          {{ startDateDisplay }}
          <van-icon class="icon" name="arrow" />
        </div>
        <van-action-sheet v-model="startDateShow" title="">
          <van-datetime-picker
            v-model="startDate"
            type="datehour"
            title="选择开始时间"
            :columns-order="['year', 'month', 'day', 'hour']"
            :formatter="formatter"
            :min-date="minStartDate"
            @confirm="onConfirmStart"
          />
        </van-action-sheet>
      </div>

      <div class="item">
        <div class="title">结束时间</div>
        <div class="chose" @click="endDateShow = true">
          {{ endDateDisplay }}
          <van-icon class="icon" name="arrow" />
        </div>
        <van-action-sheet v-model="endDateShow" title="">
          <van-datetime-picker
            v-model="endDate"
            type="datehour"
            title="选择结束时间"
            :columns-order="['year', 'month', 'day', 'hour']"
            :formatter="formatter"
            :min-date="minEndDate"
            @confirm="onConfirmEnd"
          />
        </van-action-sheet>
      </div>

      <div class="item">
        <div class="title">请假事由</div>
        <div class="reason" @click="babyShow = true">请输入请假理由</div>
      </div>
      <div class="input_reason">
        <van-field
          v-model="reason"
          rows="3"
          autosize
          label=""
          type="textarea"
          maxlength="160"
          placeholder="请输入请假理由"
          show-word-limit
        />
      </div>
    </div>
    <div class="button">
      <van-button
        class="btn2"
        round
        color="#83d1c8"
        type="info"
        :loading="loading"
        @click="confirm()"
        >提交申请</van-button
      >
    </div>
  </div>
</template>

<script>
import {
  submitLeaveApplication,
  editLeaveApplication,
} from "@/api/guardian_api";
export default {
  data() {
    return {
      // 请假记录id  修改申请的时候用
      recessRecordId: "",
      // 请假类型 事假1 病假2 其他0
      typeShow: false,
      type: "",
      types: [
        { name: "事假", value: "1" },
        { name: "病假", value: "2" },
        { name: "其他", value: "0" },
      ],
      // 日期选择
      startDate: "",
      startDateDisplay: "请选择开始时间",
      endDate: "",
      endDateDisplay: "请选择结束时间",
      minStartDate: new Date(),
      minEndDate: new Date(Date.now() + 60 * 60 * 1000),
      // 第二个参数-1是月份 第三个参数是日期
      maxStartDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      maxEndDate: new Date(),
      startDateShow: false,
      endDateShow: false,
      // 请假理由
      reason: "",
      // 按钮加载的时候用
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.recessRecordId) {
      this.recessRecordId = this.$route.query.recessRecordId;
      this.type = this.$route.query.type;
      this.startDateDisplay = this.$route.query.startDate;
      this.endDateDisplay = this.$route.query.endDate;
      this.reason = this.$route.query.reason;
      this.startDate = new Date(this.startDateDisplay);
      this.endDate = new Date(this.endDateDisplay);
    }
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },

    // 类型选择
    onSelectType(item) {
      this.type = item.value;
    },

    // 日期格式函数
    formatter(type, val) {
      if (type == "year") {
        return val + "年";
      }
      if (type == "month") {
        return val + "月";
      }
      if (type == "day") {
        return val + "日";
      }
      if (type == "hour") {
        return val + "时";
      }
      return val;
    },

    // 日期选择
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()} ${date.getHours()}:00:00`;
    },
    onConfirmStart(date) {
      this.startDateShow = false;
      this.startDate = date;
      this.startDateDisplay = this.formatDate(date);
    },
    onConfirmEnd(date) {
      this.endDateShow = false;
      this.endDate = date;
      this.endDateDisplay = this.formatDate(date);
    },

    // 发送请求前调整日期格式
    dateFormatter(date) {
      // var date1 = date.toLocaleDateString().split('/').join('-') + " " + this.startDate.getHours()+":00:00"
      if (date.getMonth() + 1 < 10) {
        var mon = "0" + (date.getMonth() + 1);
      } else {
        var mon = date.getMonth() + 1;
      }
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      } else {
        var day = date.getDate();
      }
      return (
        date.getFullYear() +
        "-" +
        mon +
        "-" +
        day +
        " " +
        date.getHours() +
        ":00:00"
      );
    },

    // 发送请假申请
    sentLeaveAccess() {
      if (this.type == "") {
        this.$toast("请选择请假类型");
        return;
      } else if (this.startDate == "") {
        this.$toast("请选择开始时间！");
        return;
      } else if (this.endDate == "") {
        this.$toast("请选择结束时间！");
        return;
      } else if (this.endDate < this.startDate) {
        this.$toast("结束时间不得早于开始时间！");
        return;
      } else if (this.reason == "") {
        this.$toast("请输入请假理由！");
        return;
      }
      this.loading = true;
      let req = {
        guardianId: this.$store.state.guardianId,
        studentId: this.$store.state.child.studentId,
        studentName: this.$store.state.child.text,
        gradeClazzName: "三年一班",
        guardianName: this.$store.state.guardianName,
        type: this.type,
        reason: this.reason,
        startTime: this.dateFormatter(this.startDate),
        endTime: this.dateFormatter(this.endDate),
        schoolId: "1",
      };
      // 修改申请
      if (this.$route.query.recessRecordId) {
        let req_change = {
          id: this.$route.query.recessRecordId,
          guardianId: this.$store.state.guardianId,
          guardianName: this.$store.state.guardianName,
          type: this.type,
          reason: this.reason,
          startTime: this.dateFormatter(this.startDate),
          endTime: this.dateFormatter(this.endDate),
        };
        editLeaveApplication(req_change).then((res) => {
          if (res.data.code == 1) {
            this.$toast("修改成功！");
            this.$router.push({
              path: "/leaveApplicationRecord/leaveApplicationDetail",
              query: {
                recessRecordId: this.recessRecordId,
                update: 0,
              },
            });
          } else {
            this.$toast(res.data.message);
          }
          this.loading = false;
        });
      }
      // 新提交
      else {
        submitLeaveApplication(req).then((res) => {
          if (res.data.code == 1) {
            this.$toast("申请成功！");
            this.$router.push("/leaveApplicationRecord");
          } else {
            this.$toast(res.data.message);
          }
          this.loading = false;
        });
      }
    },

    // 确认
    confirm() {
      this.sentLeaveAccess();
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: white;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .tip {
    width: calc(100% - 14px);
    font-size: 14px;
    padding: 3px 7px 3px 7px;
    // color: white;
    // background: #ff9800;
    height: 25px;
    position: absolute;
    top: 45px;
  }
  .form {
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    padding: 25px 15px 40px 15px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 3px rgb(207, 207, 207);
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      .title {
        width: 35%;
        font-size: 14px;
      }
      .chose {
        margin-right: 10px;
        font-size: 14px;
        color: #b7b7b7;
        .icon {
          margin-left: 10px;
        }
      }
      .reason {
        margin-right: 37px;
        font-size: 14px;
        color: #b7b7b7;
      }
    }
    .input_reason {
      width: 100%;
      margin-top: 10px;
      ::v-deep .van-cell__value {
        padding: 5px;
        border-radius: 5px;
        background: #faf9f9;
      }
    }
  }
  .button {
    width: 80%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    .btn2 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
    }
  }
}
</style>
