<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      class="top"
      title="消息通知"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <!-- 轮播图 -->
    <div class="swiper_box" style="height: 27vh">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        lazy-render
      >
        <van-swipe-item
          v-for="(item, index) in schoolAdvertImages"
          :key="index"
        >
          <img :src="item.image" alt="" width="100%" height="100%" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 接送提醒详情 -->
    <div class="box" v-if="this.messageType == 'pickup'">
      <div class="item">
        <div class="title">学生姓名：</div>
        <div class="value">
          {{ this.pickupInfo.studentName }}
        </div>
      </div>
      <div class="item" v-if="this.pickupInfo.guardianName">
        <div class="title">接送人员：</div>
        <div class="value">
          {{ this.pickupInfo.guardianName }}
        </div>
      </div>
      <!-- <div class="item">
        <div class="title">监护关系：</div>
        <div class="value">
          {{ this.pickupInfo.guardianType }}
        </div>
      </div> -->
      <div class="item" v-if="this.pickupInfo.physicalCardNumber">
        <div class="title">卡号信息：</div>
        <div class="value">
          {{ this.pickupInfo.physicalCardNumber }}
        </div>
      </div>
      <div class="item" v-if="this.pickupInfo.physicalCardNumber">
        <div class="title">刷卡时间：</div>
        <div class="value">
          {{ this.pickupInfo.broadcastTime }}
        </div>
      </div>
      <div class="item" v-else>
        <div class="title">刷脸时间：</div>
        <div class="value">
          {{ this.pickupInfo.broadcastTime }}
        </div>
      </div>
    </div>
    <!-- 进出校详情 -->
    <div class="box" v-else>
      <div class="item">
        <div class="title">学生姓名：</div>
        <div class="value">
          {{ this.inoutInfo.studentName }}
        </div>
      </div>
      <div class="item">
        <div class="title">通行方向：</div>
        <div class="value">
          {{ this.inoutInfo.accessDirection == 1 ? "出校" : "入校" }}
        </div>
      </div>
      <div class="item">
        <div class="title">地点：</div>
        <div class="value">
          {{ this.inoutInfo.accessDeviceName }}
        </div>
      </div>
      <div class="item">
        <div class="title">通行时间：</div>
        <div class="value">
          {{ this.inoutInfo.accessTime }}
        </div>
      </div>
      <!-- <div class="item">
        <div class="title">卡号信息</div>
        <div class="value">
          {{ this.inoutInfo.accessInfo }}
        </div>
      </div> -->
    </div>
    <!-- 抓拍 -->
    <img
      v-if="this.inoutInfo.photoUrl"
      class="scence_photo"
      :src="this.inoutInfo.photoUrl"
      alt=""
      @click="onClick"
    />
    <img
      v-if="this.pickupInfo.photoUrl"
      class="scence_photo"
      :src="this.pickupInfo.photoUrl"
      alt=""
      @click="onClick"
    />
    <!-- 紧急联系老师 -->
    <!-- <van-button
      class="btn"
      type="danger"
      color="#d41426"
      v-if="this.type == '2'"
      @click="emergency()"
      >紧急联系老师</van-button
    > -->
  </div>
</template>

<script>
import { getSinglePickupRecord, getInoutRecordDetail } from "@/api/teacher_api";

/**
 * 引入图片控件，点击可以显示大图
 */
import { ImagePreview } from "vant";
import { getSchoolAdvert } from "@/api/school_api";

export default {
  data() {
    return {
      // 类型 1为教师端 2是家长端
      type: "2",
      // 消息类型
      messageType: "",
      // 消息记录id
      recordId: "",
      // 接送记录信息
      pickupInfo: {},
      // 进出记录信息
      inoutInfo: {},
      // 上一级路由
      beforeUrl: "",
      schoolAdvertImages: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    // ...
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
  created() {
    this.getSchoolAdvert();
  },
  mounted() {
    // 获取教师端还是监护人端
    this.type = this.$store.state.type;
    // 获取url上的type pickup为接送消息  inout为进出校
    this.messageType = this.$route.params.messageType;
    // 获取路由上的recordId
    this.recordId = this.$route.query.recordId;
    if (this.messageType == "pickup") {
      // 获取本条接送记录详情
      this.getSinglePickupRecord();
    } else {
      // 否则为进出信息详情
      // if(this.$route.query.studentName){
      //   this.inoutInfo['accessInfo'] = this.$route.query.accessInfo
      //   this.inoutInfo['accessTime'] = this.$route.query.accessTime
      //   this.inoutInfo['photoUrl'] = this.$route.query.photoUrl
      //   this.inoutInfo['recordId'] = this.$route.query.recordId
      //   this.inoutInfo['studentName'] = this.$route.query.studentName
      //   this.inoutInfo['accessDirection'] = this.$route.query.accessDirection
      //   this.inoutInfo['accessDeviceName'] = this.$route.query.accessDeviceName
      // }else{
      this.getInoutRecordDetail();
      // }
    }
  },
  methods: {
    async getSchoolAdvert() {
      await getSchoolAdvert().then((res) => {
        if (res.data.code === 1) {
          this.schoolAdvertImages = res.data.data;
          console.log("获取后的数据", this.schoolAdvertImages);
        } else {
          Dialog.alert({
            title: "提示",
            message: "获取学校介绍信息失败，请关闭页面重新进入",
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 返回
    back() {
      console.log(this.beforeUrl);
      this.$router.go(-1);
    },

    /**
     * 点击图片，放大
     * @param {} photo_url
     */
    onClick() {
      // ImagePreview([this.inoutInfo.photoUrl]);
    },

    // 查询单条接送记录详情(教师端和家长端一起)
    getSinglePickupRecord() {
      if (this.recordId == null || this.recordId == "") {
        this.$toast("路由上没有recordId");
        return;
      }
      let req = {
        broadcastRecordId: this.recordId,
      };
      getSinglePickupRecord(req).then((res) => {
        if (res.data.code == 1) {
          if (res.data.data == undefined) {
            console.log("查询单条接送记录详情没有返回数据");
            return;
          }
          this.pickupInfo = res.data.data;
          console.log("this.pickupInfo", this.pickupInfo);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 查询进出记录详情  （两端通用）
    getInoutRecordDetail() {
      let req = {
        accessDeviceRecordId: this.recordId,
      };
      getInoutRecordDetail(req).then((res) => {
        if (res.data.code == 1) {
          if (res.data.data == undefined) {
            console.log("查询单条进出记录详情没有返回数据");
            return;
          }
          this.inoutInfo = res.data.data;
          console.log("this.inoutInfo", this.inoutInfo);
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    // 紧急联系老师
    emergency() {
      this.$router.push("/tabMe/connect/1");
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    ::v-deep .van-nav-bar__text {
      font-size: 16px;
    }
    ::v-deep .van-nav-bar__title {
      font-weight: bold;
    }
    // height: 50px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // background-color: #ffffff;
    // .top_left{
    //     position: absolute;
    //     left: 5px;
    //     top: 13px;
    //     font-size: 17px;
    // }
    // .top_center{
    //     font-size: 18px;
    //     font-weight: bold;
    // }
  }
  .advertisement {
    width: 100%;
    height: 50vw;
    margin-top: 45px;
    // 轮播图样式
    .my-swipe,
    .van-swipe-item {
      height: 100%;
      width: 100%;
      color: #fff;
      text-align: center;
      background-color: #f9f9f9;
    }
    .img {
      width: 100%;
      object-fit: contain;
    }
  }
  .box {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 20px;
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
  .scence_photo {
    width: 60%; /* 你希望的宽度 */
    object-fit: contain;
  }

  .btn {
    width: 150px;
    margin-top: 30px;
    font-size: 17px;
  }
}
</style>
