// 支付相关API
import request from "../utils/request.js";
export function reqRenew(data) {
  return request({
    url: "/payment/renew",
    method: "post",
    data: data,
  });
}

export function reqGetPaymentRecord(params) {
  return request({
    url: "/payment/paymentRecord",
    method: "get",
    params: params,
  });
}
