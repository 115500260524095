import request from "../utils/request.js";

// 查询某一宝贝所有监护人信息详情列表（通讯录） 已对
export function getStudentGuardianInfoList(params) {
  return request({
    url: "guardian/selectGuardianInfoListByStudentId",
    method: "get",
    params: params,
  });
}
// homepage页面-------------------------------------------------------------------------
// 请假相关
// 取消请假
export function cancelLeaveApplication(params) {
  return request({
    url: "recess/withdrawRecord",
    method: "get",
    params: params,
  });
}

// 监护人修改请假申请
export function editLeaveApplication(data) {
  return request({
    url: "recess/guardianUpdateRecessRecord",
    method: "post",
    data: data,
  });
}
// 监护人查询某个宝贝所有粗略请假记录
export function getLeaveApplicationRecord(params) {
  return request({
    url: "recess/selectRecessBasicRecord",
    method: "get",
    params: params,
  });
}

// 监护人端增加请假记录并推送至教师端
export function submitLeaveApplication(data) {
  return request({
    url: "recess/insertRecord",
    method: "post",
    data: data,
  });
}
// 消息页面-------------------------------------------------------------------------
// 监护人端查询某个宝贝所有接送记录
export function getPickupRecordGuar(params) {
  console.log("监护人获取接送记录接口...");
  return request({
    url: "broadcastDevice/selectBroadcastBasicRecord",
    method: "get",
    params: params,
  });
}
// 监护人端查询某个宝贝所有进出记录
export function getInoutRecordGuar(params) {
  console.log("监护人获取进出记录接口...");
  return request({
    url: "accessDevice/selectAccessBasicRecord",
    method: "get",
    params: params,
  });
}
// Me我的页面-------------------------------------------------------------------------
// 查询学生基本信息
export function getStudentInfo(params) {
  return request({
    url: "guardian/selectBabyInfo",
    method: "get",
    params: params,
  });
}

// 查询某一宝贝状态
export function getStudentStatus(params) {
  return request({
    url: "student/status/selectStudentStatus",
    method: "get",
    params: params,
  });
}

// 修改监护人自己的手机号码
export function changePhonNumber(data) {
  return request({
    url: "guardian/updateGuardianPhone",
    method: "post",
    data: data,
  });
}

// 监护人通过班级id查询班主任电话(通讯录)  可以去掉
export function getAllTeacher(params) {
  return request({
    url: "guardian/selectMainTeacherPhoneByGradeClazzId",
    method: "get",
    params: params,
  });
}

// 监护人查询该班级所有教师的具体信息列表(通讯录详情)   已对
export function getTeacherDetail(params) {
  return request({
    url: "guardian/selectTeacherInfoByClazzId",
    method: "get",
    params: params,
  });
}

export function reqGuardianRegister(data) {
  return request({
    url: "guardian/register",
    method: "post",
    data: data,
  });
}
