import request from "../utils/request.js";
export function getSchoolIntroduction() {
  return request({
    url: "/school/introduction",
    method: "get",
  });
}

export function getSchoolAdvert(schoolId) {
  if (!schoolId) {
    return request({
      url: "/school/advert",
      method: "get",
    });
  } else {
    return request({
      url: "/school/advert" + "/" + schoolId,
      method: "get",
    });
  }
}

export function reqGetSchoolInfo(params) {
  return request({
    url: "/school/info",
    method: "get",
    params,
  });
}
