<template>
  <div class="main">
    <!-- 导航栏 -->
    <van-nav-bar
      title="学校/园区介绍"
      left-text="返回"
      left-arrow
      @click-left="back"
    />

    <!-- 轮播图 -->
    <div class="swiper_box" v-if="show == 0" style="height: 27vh">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        lazy-render
      >
        <van-swipe-item
          v-for="(item, index) in schoolAdvertImages"
          :key="index"
        >
          <img :src="item.image" alt="" width="100%" height="100%" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="schoolIntroduction" v-html="schoolIntroduction"></div>
  </div>
</template>

<script>
import { getSchoolAdvert, getSchoolIntroduction } from "@/api/school_api";
import { Dialog } from "vant";
import request from "../utils/request.js";

export default {
  data() {
    return {
      //轮播图
      schoolAdvertImages: [],
      //富文本
      schoolIntroduction: "",

      // 园区展示为0 详细介绍为1
      show: 0,
    };
  },
  created() {
    this.getSchoolIntroduction();
    this.getSchoolAdvert();
  },
  methods: {
    //获取学校介绍
    async getSchoolIntroduction() {
      await getSchoolIntroduction().then((res) => {
        if (res.data.code === 1) {
          this.schoolIntroduction = res.data.data.content;
        } else {
          Dialog.alert({
            title: "提示",
            message: "获取学校介绍信息失败，请关闭页面重新进入",
          }).then(() => {
            // on close
          });
        }
      });
    },
    //获取学校轮播图
    async getSchoolAdvert() {
      await getSchoolAdvert().then((res) => {
        if (res.data.code === 1) {
          this.schoolAdvertImages = res.data.data;
          console.log("获取后的数据", this.schoolAdvertImages);
        } else {
          Dialog.alert({
            title: "提示",
            message: "获取学校介绍信息失败，请关闭页面重新进入",
          }).then(() => {
            // on close
          });
        }
      });
    },

    // 返回
    back() {
      this.$router.push("/");
    },
    // 点击标签页
    onClick(name, title) {
      // if (title == "园区展示") {
      //   this.show = 0;
      // } else {
      //   this.show = 1;
      // }
      // console.log(this.show);
      // this.$toast(title);
    },
  },
};
</script>

<style lang="scss" scoped></style>
