<template>
  <div>
    <!-- 轮播图 -->
    <div class="swiper_box" v-if="show == 0" style="height: 27vh">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        lazy-render
      >
        <van-swipe-item
          v-for="(item, index) in schoolAdvertImages"
          :key="index"
        >
          <img :src="item.image" alt="" width="100%" style="height: 27vh" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="box">
      <div>
        <van-form class="form" :model="form" ref="myform">
          <van-cell-group>
            <van-field
              label="学生姓名"
              class="input"
              v-model="form.studentName"
              required
              placeholder="请输入学生姓名"
              :rules="nameRule"
            />
            <van-field
              label="身份证号"
              class="input"
              v-model="form.idCard"
              required
              placeholder="请输入身份证号"
              type="text"
              :rules="idCardRule"
            />
            <!-- 上传学生照 -->

            <van-field
              required
              name="uploader"
              label="学生照"
              :rules="photoRules"
            >
              <template #input>
                <van-uploader
                  v-model="fileList"
                  :after-read="afterRead"
                  :max-count="1"
                  :before-read="beforeRead"
                  :max-size="50000 * 1024"
                />
              </template>
              <!-- 提示 -->
            </van-field>
            <span
              >可上传蓝底证件大头照;<br />
              需手机现场拍摄则挑选光线较好的白天，背景选择白墙
            </span>
            <!-- 来个开关 是否开启付费功能 -->
            <van-field name="switch" label="是否开启付费功能" class="red-label">
              <template #input>
                <van-switch v-model="form.isPay" />
              </template>
            </van-field>

            <van-divider />
            <van-field
              label="家长姓名1"
              class="input"
              v-model="form.guardianInfo1.name"
              required
              placeholder="请输入家长姓名"
              type="text"
              :rules="nameRule"
            />
            <van-field
              label="家长手机号1"
              class="input"
              v-model="form.guardianInfo1.phone"
              required
              placeholder="请输入家长手机号"
              type="text"
              :rules="phoneRule"
            />
            <van-field
              label="家长姓名2"
              class="input"
              v-model="form.guardianInfo2.name"
              placeholder="请输入家长姓名"
              type="text"
            />
            <van-field
              label="家长手机号2"
              class="input"
              v-model="form.guardianInfo2.phone"
              placeholder="请输入家长手机号"
              type="text"
            />
          </van-cell-group>
        </van-form>
      </div>
      <van-button color="#4ba578" round type="info" @click="register()">
        提交注册
      </van-button>
    </div>
  </div>
</template>

<script>
import { register } from "@/api/api";
import { getSchoolAdvert } from "@/api/school_api";
import request from "../utils/request.js";
import { reqNewStudentRegister } from "@/api/student_api";
import { getUserInfo } from "@/api/api";
import { Dialog } from "vant";
import { reqRenew } from "@/api/payment_api.js";

export default {
  async created() {
    await this.getUserInfoV2();
    await this.getSchoolAdvert();
  },
  data() {
    return {
      schoolAdvertImages: [],
      nameRule: [
        {
          required: true,
          //   message: "姓名不能为空",
          trigger: "onBlur",
        },
        {
          validator: this.validateIdCard,
          trigger: "blur",
        },
      ],
      photoRules: [
        {
          required: true,
          message: "请上传学生照",
          trigger: "onBlur/onChange",
        },
      ],

      fileList: [],
      idCardRule: [
        {
          // 是否必填
          required: true,
          //   message: "身份证号不能为空",
          trigger: "onBlur/onChange",
        },
        {
          // 自定义表单校验
          validator: (value) => {
            const idCardReg = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;
            if (!idCardReg.test(value)) {
              return false;
            } else {
              return true;
            }
          },
          //   message: "身份证号格式不正确",
          trigger: "onBlur/onChange",
        },
      ],
      phoneRule: [
        {
          required: true,
          //   message: "手机号不能为空",
          trigger: "onBlur/onChange",
        },
        {
          validator: (value) => {
            const phoneReg = /^1[3456789]\d{9}$/;
            if (!phoneReg.test(value)) {
              return false;
            } else {
              return true;
            }
          },
        },
      ],
      active: 0,
      // 忘记密码弹窗
      show: false,
      form: {
        studentName: "测试人员",
        idCard: "440803200206123456",
        photoUrl: [],
        isPay: false,
        guardianInfo1: { name: "测试人员2", phone: "17817672742" },
        guardianInfo2: { name: "", phone: "" },
        guardianList: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  methods: {
    //获取学校轮播图
    async getSchoolAdvert() {
      await getSchoolAdvert(localStorage.getItem("schoolId"))
        .then((res) => {
          if (res.data.code === 1) {
            this.schoolAdvertImages = res.data.data;
            console.log("获取后的数据", this.schoolAdvertImages);
          } else {
            // eslint-disable-next-line no-undef
            Dialog.alert({
              title: "提示",
              message: "获取学校介绍信息失败，请关闭页面重新进入",
            }).then(() => {
              // on close
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    register() {
      console.log("isPay", this.form.isPay);
      let schoolId = localStorage.getItem("schoolId");
      console.log("学校ID", schoolId);
      //获取用户openid
      this.$refs.myform
        .validate()
        .then(() => {
          if (
            this.form.guardianInfo1.name !== "" &&
            this.form.guardianInfo1.phone !== ""
          ) {
            this.form.guardianList.push(this.form.guardianInfo1);
          }
          if (
            this.form.guardianInfo2.name !== "" &&
            this.form.guardianInfo2.phone !== ""
          ) {
            this.form.guardianList.push(this.form.guardianInfo2);
          }
          let req = {
            studentName: this.form.studentName,
            idCard: this.form.idCard,
            photoUrl: this.form.photoUrl,
            guardianList: this.form.guardianList,
            openId: this.$store.state.openid,
            isPay: this.form.isPay,
            schoolId: schoolId,
          };
          reqNewStudentRegister(req).then((res) => {
            if (res.data.code == 1) {
              this.$toast.success("注册成功");
              console.log("注册成功", res.data.data);
              console.log("this.isP");
              if (this.form.isPay) {
                console.log("开始支付");
                //开始支付
                this.toPay(res.data.data);
              }
            } else {
              this.$toast.fail("注册失败:" + res.data.message);
            }
          });
        })
        .catch((e) => {
          console.log("验证不通过");
          console.log(e);
        });
      this.form.guardianList = [];
    },
    toPay(data) {
      let {
        appId,
        timeStamp,
        nonceStr,
        package: packageStr,
        signType,
        paySign,
      } = data;
      console.log("预下单数据：", data);
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId, // 微信的appid
          timeStamp, //时间戳
          nonceStr, //随机串
          package: packageStr, // 订单号
          signType, //微信签名方式：
          paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
          }
        }
      );
    },
    beforeRead(event) {
      this.$toast("上传图片");
      this.$toast("even", event);
      //File文件 ，判断是否是图片类型
      if (event) {
        console.log(event.type);
        //含image即可
        if (event.type.indexOf("image") === -1) {
          //不是图片格式
          this.$toast.fail("请上传图片");
          return false;
        } else {
          return true;
        }
      } else {
        this.$toast("东西不存在");
      }
    },
    afterRead(event) {
      const { file } = event;
      // 当设置 mutiple 为 true 时, file 为数组格式，否则为对象格式
      //上传
      let formData = new FormData();
      formData.append("file", file);
      console.log("上传的文件", formData);
      request
        .post("http://42.193.243.167:8080/file/uploadImg/student", formData)
        .then((res) => {
          console.log("上传成功", res);
          this.form.photoUrl = res.data.data;
          console.log("上传成功", this.form.photoUrl);
        })
        .catch((err) => {
          console.log("上传失败", err);
        });
    },
    getUserInfoV2() {
      if (!this.$route.query.token) {
        // jiaji的
        // let hhAppId = "wx9c68a4cb0ec896a8";
        //公司的
        let hhAppId = "wxa9991f1c4fbe89bb";
        //公司域名
        let domain = "wxtest.ubooking.cn";
        //jj域名
        // let domain = "shjj.ubooking.cn";
        //测试域名
        // let domain = "xyue.mynatapp.cc";
        let type = this.$route.query.type;
        let id = this.$route.query.id;
        let schoolId = localStorage.getItem("schoolId");
        let authUrl = this.buildAuthUrl(hhAppId, domain, type, id, schoolId);
        if (authUrl) {
          console.log("authUrl", authUrl);
          window.location.href = authUrl;
        } else {
          Dialog.alert({
            title: "提示",
            message: "获取学校缓存信息失败，请关闭页面重新进入",
          }).then(() => {
            // on close
          });
        }
      } else {
        getUserInfo({ token: this.$route.query.token }).then((res) => {
          //有可能有信息，但是被禁用了，所以需要判断
          res.data.data.userInfo.schoolId = this.$route.query.schoolId;
          this.$store.commit("setOpenid", res.data.data.userInfo.openid);
          this.$store.commit(
            "setUserInfo",
            JSON.stringify(res.data.data.userInfo)
          );
          sessionStorage.setItem(
            "userInfo",
            JSON.stringify(res.data.data.userInfo)
          );
        });
      }
    },
    buildAuthUrl(hhAppId, domain, type, id, schoolId) {
      let backUrl =
        // 云上nginx部署用这个
        "http%3A%2F%2F" + domain + "%2Fapi%2Fwechat%2FauthorizeInfo%3F";
      // 本地测试用这个，因为没有自动去掉api
      // "http%3A%2F%2F" + domain + "%2Fwechat%2FauthorizeInfo%3F";
      let href =
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${hhAppId}&redirect_uri=` +
        backUrl +
        "type=" +
        type +
        "%26id=" +
        id +
        "%26schoolId=" +
        schoolId +
        "%26isRegister=" +
        1 +
        "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
      return href;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  height: 100%;
  width: 100%;
}
.red-label .van-field__label {
  color: red;
}
</style>
