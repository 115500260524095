<template>
  <!-- 默认首页 -->
  <div class="main">
    <!-- 顶上背景图 -->
    <!-- 教师端顶部 -->
    <div v-if="this.type == 1" class="backimg">
      <div class="box">
        <div class="headimg_box">
          <img class="headimg" :src="this.headImgUrl" alt="" width="50px" />
        </div>
        <div class="right">
          <div class="top">
            <div class="text">
              {{ this.teacherName }}
            </div>
            <van-popover
              v-model="changeVisible"
              :actions="this.classes"
              @select="onConfirm"
              placement="bottom-start"
            >
              <template #reference>
                <span
                  class="change_class"
                  @click="changeVisible = !changeVisible"
                >
                  <van-icon class="icon" name="sort" />
                  {{ "切换班级" }}
                </span>
              </template>
            </van-popover>
          </div>
          <div class="bottom">
            {{ clazz ? clazz.text : "" }}
          </div>
        </div>
      </div>
    </div>
    <!-- 家长端顶部 -->
    <div v-if="this.type == 2" class="backimg">
      <div class="greetTitle">
        <div class="text">
          {{ this.guardianName + "家长，您好" }}
          <!-- {{child.text + "家长，您好" }} -->
        </div>
        <van-popover
          v-model="changeVisible"
          :actions="this.children"
          @select="onConfirm"
          placement="bottom-start"
        >
          <template #reference>
            <span class="change_child" @click="changeVisible = !changeVisible">
              <van-icon class="icon" name="sort" />
              {{ child ? child.text : "切换宝贝" }}
            </span>
          </template>
        </van-popover>
      </div>
      <div class="headimg_box">
        <img class="headimg" :src="this.headImgUrl" alt="" width="50px" />
      </div>
    </div>

    <!-- 宫格 -->
    <div class="grid_box">
      <van-grid
        class="grid"
        :column-num="this.type == '1' ? 3 : 3"
        :clickable="true"
        :border="false"
        icon-size="32px"
      >
        <van-grid-item
          class="grid_item"
          v-for="(item, index) in this.type == 1
            ? teacherGridItems
            : guardianGridItems"
          :key="index"
          @click="gridClick(index)"
        >
          <img
            class="grid_img"
            :src="item.icon"
            alt=""
            width="50"
            height="50"
          />
          <!-- <van-image :src="require(item.icon)" /> -->
          <div class="grid_title">
            {{ item.text }}
          </div>
        </van-grid-item>
      </van-grid>
    </div>

    <!-- 下方最新消息 -->
    <!-- <div class="neweast_message">
      <div class="title">
        {{ "最新消息" }}
      </div>
      <div class="messageCards">
        <div class="messageCard" v-if="this.type == 1">
          <div class="top">
            <div class="top_left">
              <van-icon class="icon" name="friends" />
              <span class="messageTitle">{{ "到校统计" }}</span>
            </div>
            <div class="top_right">未读</div>
          </div>
          <div class="divide"></div>
          <div class="describe">
            今日上午班级已到校{{ arriveRoughSummary.inTheSchoolNumber }}人，
            未到{{ arriveRoughSummary.notInTheSchoolNumber }}人。
          </div>
          <div class="footer">
            <div class="footer_left" @click="toDetail(1)">
              <div class="detail">
                查看详情
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="footer_right">2020/12/30 17:30</div>
          </div>
        </div>

        <div class="messageCard">
          <div class="top">
            <div class="top_left">
              <van-icon class="icon" name="friends" />
              <span class="messageTitle">{{ "接送提醒" }}</span>
            </div>
            <div class="top_right">未读</div>
          </div>
          <div class="divide"></div>
          <div class="describe">
            {{ "接送人已到达学校。。。。" }}
          </div>
          <div class="footer">
            <div class="footer_left" @click="toDetail(2)">
              <div class="detail">
                查看详情
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="footer_right">2020/12/30 17:30</div>
          </div>
        </div>

        <div class="messageCard">
          <div class="top">
            <div class="top_left">
              <van-icon class="icon" name="friends" />
              <span class="messageTitle">{{ "接送提醒" }}</span>
            </div>
            <div class="top_right">未读</div>
          </div>
          <div class="divide"></div>
          <div class="describe">
            {{ "接送人已到达学校。。。。" }}
          </div>
          <div class="footer">
            <div class="footer_left" @click="toDetail(2)">
              <div class="detail">
                查看详情
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="footer_right">2020/12/30 17:30</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 切换小孩/班级选择 -->
    <!-- <van-popup
      v-model="changeVisible"
      position="bottom"
      round
      :style="{ height: '30%' }">
      <van-picker
        show-toolbar
        :title="this.type == 1 ? '切换班级':'切换小孩'"
        :columns="this.type == 1 ? this.classes:this.children"
        :default-index="0"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup> -->
  </div>
</template>
<script>
import { getUserInfo, getUnreadCount } from "@/api/api";
import { getArriveRoughSummary } from "@/api/teacher_api";
import { Dialog } from "vant";
import { mapGetters, mapState } from "vuex";
import { reqGetAllStudent } from "@/api/student_api";
import { reqGetSchoolInfo } from "@/api/school_api";
export default {
  name: "homepage",
  data() {
    return {
      // 类型 1为教师端 2是家长端
      // type:"1",
      // 宫格图标
      guardianGridItems: [
        {
          text: "进出记录",
          icon: require("../assets/homepageGrid/2.png"),
        },
        {
          text: "接送记录",
          icon: require("../assets/homepageGrid/1.png"),
        },
        // {
        //   text: "请假申请",
        //   icon: require("../assets/homepageGrid/3.png"),
        // },
        // {
        //   text: "请假记录",
        //   icon: require("../assets/homepageGrid/4.png"),
        // },
        {
          text: "学生信息",
          icon: require("../assets/homepageGrid/5.png"),
        },
        {
          text: "家长信息",
          icon: require("../assets/homepageGrid/6.png"),
        },
        // {
        //   text: "公告信息",
        //   icon: require("../assets/homepageGrid/7.png"),
        // },，
        {
          text: "修改密码",
          icon: require("../assets/homepageGrid/7.png"),
        },
        {
          text: "学校介绍",
          icon: require("../assets/homepageGrid/8.png"),
        },
        {
          text: "缴费管理",
          icon: require("../assets/homepageGrid/7.png"),
        },
        {
          text: "缴费记录",
          icon: require("../assets/homepageGrid/8.png"),
        },
        {
          text: "下期功能",
          icon: require("../assets/homepageGrid/8.png"),
        },
      ],
      teacherGridItems: [
        {
          text: "消息记录",
          icon: require("../assets/homepageGrid/1.png"),
        },
        {
          text: "考勤汇总",
          icon: require("../assets/homepageGrid/2.png"),
        },
        {
          text: "接送统计",
          icon: require("../assets/homepageGrid/3.png"),
        },
        {
          text: "班级信息",
          icon: require("../assets/homepageGrid/5.png"),
        },
        {
          text: "请假管理",
          icon: require("../assets/homepageGrid/4.png"),
        },
        {
          text: "修改密码",
          icon: require("../assets/homepageGrid/7.png"),
        },
        {
          text: "学校介绍",
          icon: require("../assets/homepageGrid/8.png"),
        },
      ],
      // 顶部切换选择
      changeVisible: false,

      // 首页下部教师粗略到校统计
      arriveRoughSummary: "",
      // token: "",
    };
  },
  computed: {
    // 普通写法
    // headImgUrl: function() {
    //   return this.$store.state.headImgUrl
    // },
    ...mapState({
      // 类型 1为教师端 2是家长端
      type: "type",
      headImgUrl: "headImgUrl",
      teacherName: "teacherName",
      guardianName: "guardianName",
      clazz: "clazz",
      child: "child",
      // teacherName: "teacherName",
      // teacherName: "teacherName",
    }),
    // 或者
    // ...mapState(["type","headImgUrl","teacherName","guardianName",
    //   "clazz","child","teacherName","teacherName"])
    ...mapGetters({
      classes: "getClasses",
      children: "getChildren",
    }),
  },
  // activated() {
  //   if (this.type == 2) {
  //     // 粗略查询本班到校统计(首页下部消息提醒)
  //     this.getArriveRoughSummary();
  //   } else {
  //     console.log("家长捏");
  //   }
  //   this.$forceUpdate();
  // },
  // mounted() {
  //   // 获取UserInfo，验证登录结果，只能用一次  部署取消注释
  //   // this.getUserInfo();
  //   // console.log(83);
  //   // 获取教师端还是监护人端  以下所有部署前注释-------------------
  //   // this.$store.commit("setType",1)
  //   // // 暂时写死
  //   // localStorage.setItem("token","eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwicm9sZSI6IlRlYWNoZXIiLCJvcGVuaWQiOm51bGwsImV4cCI6MTY1MzMwMzIxOSwiaWF0IjoxNjUzMDQ0MDE5LCJhY2NvdW50IjoiMSIsImp0aSI6ImIyOTEwMjVmLTMxMzktNGUyMy05YTQ4LWU5YTk3MWQzZjM3YyJ9._J0JXAlXRRJmB_h5Yy2OEzHAOgo5Z2gtMGkNJaU15mM")
  //   // // 获取当前角色未读消息数量
  //   // this.getUnreadCount();
  // },
  created() {
    // 先获取schoolid，如果schoolId不存在，就说明是非法的
    let schoolId = this.$route.query.schoolId;
    let isRegister = this.$route.query.isRegister;
    let isGuardianRegister = this.$route.query.isGuardianRegister;

    this.$toast(
      "参数::" + schoolId + "::" + isRegister + "::" + isGuardianRegister
    );
    if (schoolId) {
      // 如果学校id存在，就把id缓存到本地，调用接口的时候，直接调用了，就不需要没给接口都写获取一遍
      // 但是需要注意的是，如果参数没有，并且缓存里面也没有，说明这个可能是非法的参数
      localStorage.setItem("schoolId", schoolId);
      this.initSchoolInfo(schoolId);
      //然后获取学校信息，放到仓库里
    } else {
      // 参数里面没有schoolId，查询一下缓存里面是否存在
      let localSchooldId = localStorage.getItem("schoolId");
      if (!localSchooldId) {
        // 非法访问
        console.log("未获取到合法的学校ID");
        return;
      }
    }
    if (isRegister == 1) {
      this.$toast("前往新生注册");
      this.$router.replace({
        path: "/register",
        query: { schoolId: schoolId, token: this.$route.query.token },
      });
    } else if (isGuardianRegister == 1) {
      this.$toast("前往家长注册");
      this.$router.replace({
        path: "/guardianRegister",
        query: { schoolId: schoolId },
      });
    } else {
      this.$toast("正常访问");
      this.getUserInfoV2();
    }
  },
  methods: {
    async getAllStudent() {
      await reqGetAllStudent().then((res) => {
        console.log("所有学生", res.data.data);
        if (res.data.code === 1) {
          this.$store.commit("setChildrenV2", res.data.data);
        } else {
          this.$toast("获取学生列表失败,请重新进入。");
        }
      });
    },

    async initSchoolInfo(schoolId) {
      let params = {
        schoolId: schoolId,
      };
      await reqGetSchoolInfo(params).then((res) => {
        if (res.data.code == 1) {
          this.$store.commit("setSchoolInfo", res.data.data);
          console.log("this.$store.state.schoolInfo", res.data.data);
        }
      });
    },
    // 切换小孩/班级
    onConfirm(item) {
      this.changeVisible = false;
      if (this.type == 1) {
        // 操作身份是教师，切换班级
        console.log("切换班级");
        this.$store.commit("setClazz", item);
        console.log(this.clazz);
      } else {
        console.log("切换小孩");
        // 操作身份是家长，切换小孩
        this.$store.commit("setChild", item);
        console.log(this.child);
      }
    },
    // 粗略查询本班到校统计(首页下部消息提醒)
    getArriveRoughSummary() {
      let req = {
        clazzId: this.$store.state.clazz.clazzId,
        attendanceStartTime: "2022-01-20 00:17:49",
        attendanceEndTime: "2022-01-20 00:17:49",
      };
      getArriveRoughSummary(req).then((res) => {
        if (res.data.code == 1) {
          this.arriveRoughSummary = res.data.data;
          console.log("this.arriveRoughSummary", this.arriveRoughSummary);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 获取当前角色未读消息数量
    getUnreadCount() {
      // this.$toast("获取未读消息数量");
      getUnreadCount().then((res) => {
        if (res.data.code == 1) {
          this.$store.commit("setUnreadCount", res.data.data);
          // console.log("未读消息数量", res.data.data);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 点击宫格内的按钮
    gridClick(index) {
      console.log("index+", index);
      console.log("点击宫格this.type", this.type);
      if (this.type == 1) {
        // 消息记录
        if (index == 0) {
          this.$router.push("/tabMessage?active=0");
        }
        // 考勤汇总
        else if (index == 1) {
          this.$router.push("/attendanceSummary");
        }
        // 接送统计
        else if (index == 2) {
          this.$router.push("/pickupSummary");
        }
        // 班级信息
        else if (index == 3) {
          this.$router.push("/tabMe/studentList");
        }
        // 请假管理
        else if (index == 4) {
          this.$router.push("/leaveApplicationRecord");
        }

        //     修改密码
        else if (index == 5) {
          this.$router.push("/tabMe/changePasswordOrPhone?flag=" + 3);
          // this.$router.push("/tabAnnouncement");
        }
        // 学校介绍
        else if (index == 6) {
          console.log("6666");
          this.$router.push("/schoolIntroduction");
        }
      } else {
        // 家长页面
        // TODO 根据index的值判读路由，如果增加或删除一个路由，后面的路由全部都需要修改，这个不合理
        // // 进出记录
        // if (index == 0) {
        //   this.$router.push("/tabMessage?active=1");
        // }
        // //  接送记录
        // else if (index == 1) {
        //   Dialog.alert({
        //     title: "提示",
        //     message: "该功能待上线",
        //   }).then(() => {
        //     // on close
        //   });
        //   // this.$router.push("/tabMessage?active=0");
        // }
        // // 请假申请
        // // else if (index == 2) {
        // //   Dialog.alert({
        // //     title: "提示",
        // //     message: "该功能待上线",
        // //   }).then(() => {
        // //     // on close
        // //   });
        // //   // this.$router.push("/leaveApplication");
        // // }
        // // 请假记录
        // // else if (index == 3) {
        // //   Dialog.alert({
        // //     title: "提示",
        // //     message: "该功能待上线",
        // //   }).then(() => {
        // //     // on close
        // //   });
        // //   // this.$router.push("/leaveApplicationRecord");
        // // }
        // // 学生信息
        // else if (index == 4) {
        //   this.$router.push("/tabMe");
        // }
        // // 家长信息
        // else if (index == 5) {
        //   this.$router.push("/tabMe/connect/2");
        // }
        // // 公告信息
        // else if (index == 6) {
        //   Dialog.alert({
        //     title: "提示",
        //     message: "该功能待上线",
        //   }).then(() => {
        //     // on close
        //   });
        //   // this.$router.push("/tabAnnouncement");
        // }
        // // 学校介绍
        // else if (index == 7) {
        //   this.$router.push("/schoolIntroduction");
        // }

        // 进出记录
        if (index == 0) {
          this.$router.push("/tabMessage?active=1");
        }
        //  接送记录
        else if (index == 1) {
          // Dialog.alert({
          //   title: "提示",
          //   message: "该功能待上线",
          // }).then(() => {
          //   // on close
          // });
          this.$router.push("/tabMessage?active=0");
        }
        // 学生信息
        else if (index == 2) {
          this.$router.push("/tabMe");
        }
        // 家长信息
        else if (index == 3) {
          this.$router.push("/tabMe/connect/2");
        }
        // 公告信息
        else if (index == 4) {
          // Dialog.alert({
          //   title: "提示",
          //   message: "该功能待上线",
          // }).then(() => {
          //   // on close
          // });
          // this.$router.push("/tabAnnouncement");
          // 修改密码
          this.$router.push("/tabMe/changePasswordOrPhone?flag=" + 3);
        }
        // 学校介绍
        else if (index == 5) {
          this.$router.push("/schoolIntroduction");
        }
        // 缴费管理
        else if (index == 6) {
          this.$router.push("/paymentManagement");
        }
        // 缴费记录
        else if (index == 7) {
          this.$router.push("/paymentRecord");
        }
      }
    },

    // 下部消息点击查看详情
    toDetail(flag) {
      // flag == 1是教师端查看接送统计详情
      if (flag == 1) {
        this.$router.push("/attendanceSummary");
        return;
      }
      this.$router.push("/tabMessage");
      // /**
      //  * @date 2023-08-30
      //  * 从底部的导航点击消息时，需要指定active的值
      //  */
      // console.log("99999999999999999999999999999999");
      // this.$router.push("/tabMessage?active=1");
    },

    getUserInfoV2() {
      console.log("进入V2");
      if (!this.$route.query.token) {
        console.log("没有token");
        // jiaji的
        // let hhAppId = "wx9c68a4cb0ec896a8";
        //公司appID
        let hhAppId = "wxa9991f1c4fbe89bb";
        //公司域名
        let domain = "wxtest.ubooking.cn";
        //jj的
        // let domain = "shjj.ubooking.cn";
        // let domain = "xyue.mynatapp.cc";
        let type = this.$route.query.type;
        let id = this.$route.query.id;
        let schoolId = localStorage.getItem("schoolId");
        let authUrl = this.buildAuthUrl(hhAppId, domain, type, id, schoolId);
        if (authUrl) {
          console.log("authUrl", authUrl);
          window.location.href = authUrl;
        } else {
          Dialog.alert({
            title: "提示",
            message: "获取学校缓存信息失败，请关闭页面重新进入",
          }).then(() => {
            // on close
          });
        }
      } else {
        getUserInfo({ token: this.$route.query.token }).then((res) => {
          // 1001 监护人 1002教师  负则登陆失败
          if (res.data.code == 1001 || res.data.code == 1002) {
            console.log("有verifyResult", res.data.data.verifyResult);
            // 家长
            if (res.data.code == 1001) {
              this.$store.commit("setType", 2);
              this.$store.commit(
                "setGuardianId",
                res.data.data.verifyResult.guardianId
              );
              this.$store.commit(
                "setGuardianName",
                res.data.data.verifyResult.guardianName
              );
              this.$store.commit(
                "setChildren",
                res.data.data.verifyResult.studentIdAndNameVoList
              );
              this.$store.commit("setChild", {
                text: res.data.data.verifyResult.studentIdAndNameVoList[0]
                  .studentName,
                studentId:
                  res.data.data.verifyResult.studentIdAndNameVoList[0]
                    .studentId,
              });
              this.getAllStudent();
            }
            // 老师
            else {
              this.$store.commit("setType", 1);
              this.$store.commit(
                "setTeacherId",
                res.data.data.verifyResult.teacherId
              );
              this.$store.commit(
                "setTeacherName",
                res.data.data.verifyResult.teacherName
              );
              this.$store.commit(
                "setClasses",
                res.data.data.verifyResult.selectTeacherGradeClazzVoList
              );
              this.$store.commit("setClazz", {
                text: res.data.data.verifyResult
                  .selectTeacherGradeClazzVoList[0].gradeClazzName,
                clazzId:
                  res.data.data.verifyResult.selectTeacherGradeClazzVoList[0]
                    .gradeClazzId,
              });
              this.getArriveRoughSummary();
              this.$forceUpdate();
            }
            // 设置通用信息
            this.$store.commit(
              "setHeadImgUrl",
              res.data.data.verifyResult.headImgUrl
            );
            this.$store.commit("setOpenid", res.data.data.verifyResult.openid);
            this.$store.commit(
              "setUserInfo",
              JSON.stringify(res.data.data.userInfo)
            );
            localStorage.setItem("token", res.data.data.verifyResult.token);
            // 获取当前角色未读消息数量
            if (this.$route.query.type != 0) {
              let type = this.$route.query.type;
              console.log("type::", type);
              console.log("模板消息进入");
              // 请假申请1 请假状态更新2 进出记录3 班播记录4 监护人异常记录5 教师异常记录6 缴费记录7
              if (
                this.$route.query.type == "1" ||
                this.$route.query.type == "2"
              ) {
                this.$router.push({
                  path: "/leaveApplicationRecord/leaveApplicationDetail",
                  query: { recessRecordId: this.$route.query.id },
                });
              } else if (this.$route.query.type == "3") {
                this.$router.push({
                  path: "/tabMessage/messageDetail/inout",
                  query: { recordId: this.$route.query.id },
                });
              } else if (this.$route.query.type == "4") {
                this.$router.push({
                  path: "/tabMessage/messageDetail/pickup",
                  query: { recordId: this.$route.query.id },
                });
              } else if (this.$route.query.type == "5") {
                this.$router.push({ path: "/tabMe" });
              } else if (this.$route.query.type == "6") {
                this.$router.push({
                  path: "/tabMe/connect/3/connectDetail",
                  query: { studentId: this.$route.query.id },
                });
              } else if (type === "7" || type === 7) {
                console.log("跳转到缴费记录....");
                this.$router.push({ path: "/paymentRecord" });
              }
            } else {
              // 如果userInfo存在  则说明是未登录(或者token过期了)  vertifyResult为空
              if (res.data.data && res.data.data.userInfo) {
                //有可能有信息，但是被禁用了，所以需要判断
                if (
                  res.data.code === -1 &&
                  res.data.message === "账号已被禁用"
                ) {
                  this.$toast.fail(res.data.message);
                } else {
                  this.$toast.success("请重新登录");
                }
                res.data.data.userInfo.schoolId = this.$route.query.schoolId;
                this.$store.commit(
                  "setUserInfo",
                  JSON.stringify(res.data.data.userInfo)
                );
                sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(res.data.data.userInfo)
                );
                this.$router.replace(
                  "/login?type=" +
                    this.$route.query.type +
                    "&id" +
                    this.$route.query.id
                );
              }
            }
          }
        });
      }
    },
    buildAuthUrl(hhAppId, domain, type, id, schoolId) {
      console.log("构建授权url");
      let backUrl =
        // 云上nginx部署用这个
        "http%3A%2F%2F" + domain + "%2Fapi%2Fwechat%2FauthorizeInfo%3F";
      // 本地测试用这个，因为没有自动去掉api
      // "http%3A%2F%2F" + domain + "%2Fwechat%2FauthorizeInfo%3F";
      let href =
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${hhAppId}&redirect_uri=` +
        backUrl +
        "type=" +
        type +
        "%26id=" +
        id +
        "%26schoolId=" +
        schoolId +
        "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
      return href;
    },
    // 验证身份接口
    // getUserInfo() {
    //   // 带this.$route.query.token的原因是，第一次登录时，后端会返回token为null的，然后拒绝访问结束，然后再重定向去微信授权，微信授权完会返回token，然后再次调用这个接口，此时这个接口路径参数是带有token了，所以需要带this.$route.query.token
    //   getUserInfo({ token: this.$route.query.token }).then((res) => {
    //     // 1001 监护人 1002教师  负则登陆失败
    //     if (res.data.code == 1001 || res.data.code == 1002) {
    //       console.log("有verifyResult", res.data.data.verifyResult);
    //       // 家长
    //       if (res.data.code == 1001) {
    //         console.log("是家长");
    //         this.$store.commit("setType", 2);
    //         console.log("this.$store.state.type", this.$store.state.type);
    //         this.$store.commit(
    //           "setGuardianId",
    //           res.data.data.verifyResult.guardianId
    //         );
    //         this.$store.commit(
    //           "setGuardianName",
    //           res.data.data.verifyResult.guardianName
    //         );
    //         this.$store.commit(
    //           "setChildren",
    //           res.data.data.verifyResult.studentIdAndNameVoList
    //         );
    //         this.$store.commit("setChild", {
    //           text: res.data.data.verifyResult.studentIdAndNameVoList[0]
    //             .studentName,
    //           studentId:
    //             res.data.data.verifyResult.studentIdAndNameVoList[0].studentId,
    //         });
    //       }
    //       // 老师
    //       else {
    //         console.log("是老师");
    //         this.$store.commit("setType", 1);
    //         console.log("this.$store.state.type", this.$store.state.type);
    //         this.$store.commit(
    //           "setTeacherId",
    //           res.data.data.verifyResult.teacherId
    //         );
    //         this.$store.commit(
    //           "setTeacherName",
    //           res.data.data.verifyResult.teacherName
    //         );
    //         this.$store.commit(
    //           "setClasses",
    //           res.data.data.verifyResult.selectTeacherGradeClazzVoList
    //         );
    //         this.$store.commit("setClazz", {
    //           text: res.data.data.verifyResult.selectTeacherGradeClazzVoList[0]
    //             .gradeClazzName,
    //           clazzId:
    //             res.data.data.verifyResult.selectTeacherGradeClazzVoList[0]
    //               .gradeClazzId,
    //         });
    //         this.getArriveRoughSummary();
    //         this.$forceUpdate();
    //       }
    //       this.$store.commit(
    //         "setHeadImgUrl",
    //         res.data.data.verifyResult.headImgUrl
    //       );
    //       this.$store.commit("setOpenid", res.data.data.verifyResult.openid);
    //       this.$store.commit(
    //         "setUserInfo",
    //         JSON.stringify(res.data.data.userInfo)
    //       );
    //       localStorage.setItem("token", res.data.data.verifyResult.token);
    //       // 获取当前角色未读消息数量
    //       if (this.$route.query.type != 0) {
    //         console.log("模板消息进入");
    //         // 请假申请1 请假状态更新2 进出记录3 班播记录4 监护人异常记录5 教师异常记录6
    //         if (
    //           this.$route.query.type == "1" ||
    //           this.$route.query.type == "2"
    //         ) {
    //           this.$router.push({
    //             path: "/leaveApplicationRecord/leaveApplicationDetail",
    //             query: { recessRecordId: this.$route.query.id },
    //           });
    //         } else if (this.$route.query.type == "3") {
    //           this.$router.push({
    //             path: "/tabMessage/messageDetail/inout",
    //             query: { recordId: this.$route.query.id },
    //           });
    //         } else if (this.$route.query.type == "4") {
    //           this.$router.push({
    //             path: "/tabMessage/messageDetail/pickup",
    //             query: { recordId: this.$route.query.id },
    //           });
    //         } else if (this.$route.query.type == "5") {
    //           this.$router.push({ path: "/tabMe" });
    //         } else if (this.$route.query.type == "6") {
    //           this.$router.push({
    //             path: "/tabMe/connect/3/connectDetail",
    //             query: { studentId: this.$route.query.id },
    //           });
    //         }
    //       }
    //     } else {
    //       // 如果userInfo存在  则说明是未登录(或者token过期了)  vertifyResult为空
    //       if (res.data.data && res.data.data.userInfo) {
    //         //有可能有信息，但是被禁用了，所以需要判断
    //         if (res.data.code === -1 && res.data.message === "账号已被禁用") {
    //           this.$toast.fail(res.data.message);
    //         }
    //         res.data.data.userInfo.schoolId = this.$route.query.schoolId;
    //         this.$store.commit(
    //           "setUserInfo",
    //           JSON.stringify(res.data.data.userInfo)
    //         );
    //         sessionStorage.setItem(
    //           "userInfo",
    //           JSON.stringify(res.data.data.userInfo)
    //         );
    //         this.$router.replace(
    //           "/login?type=" +
    //             this.$route.query.type +
    //             "&id" +
    //             this.$route.query.id
    //         );
    //       } else {
    //         // res.data.data为空  说明是重复调这个接口（刷新或者没走公众号下方进入）  则直接去微信公众号下方登录
    //         // window.location.href =
    //         //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa9991f1c4fbe89bb&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //   this.$route.query.type +
    //         //   "%26id=" +
    //         //   this.$route.query.id +
    //         //   "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //         // 粒子微校
    //         // let href =
    //         //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa9991f1c4fbe89bb&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //   this.$route.query.type +
    //         //   "%26id=" +
    //         //   this.$route.query.id +
    //         //   "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //         // 大晚小学
    //         // let href =
    //         //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx09f055c813056224&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //   this.$route.query.type +
    //         //   "%26id=" +
    //         //   this.$route.query.id +
    //         //   "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //         //
    //         let href = "";
    //         // if (this.$route.query.schoolId == '1' || this.$route.query.schoolId == '2' || this.$route.query.schoolId == '3') {
    //         // 大晚小学
    //         // href =
    //         //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx09f055c813056224&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //     this.$route.query.type +
    //         //     "%26id=" +
    //         //     this.$route.query.id +
    //         //     "%26schoolId=" +
    //         //     this.$route.query.schoolId +
    //         //     "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //         let appid = "";
    //         // if (this.$route.query.schoolId == '1') {
    //         //   appid = 'wx09f055c813056224'; //大晚
    //         // } else if (this.$route.query.schoolId == '2') {
    //         //   appid = 'wxf337790fefb48fc1';  //中心
    //         // } else if (this.$route.query.schoolId == '3') {
    //         //   appid = 'wx1fbc67070e8bed07';  //光大
    //         // }
    //         // if (this.$route.query.schoolId == '1') {
    //         //   appid = 'wx9c68a4cb0ec896a8'; //上海佳己
    //         // }
    //         // href =
    //         //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=http%3A%2F%2Fshjj.ubooking.cn%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //     this.$route.query.type +
    //         //     "%26id=" +
    //         //     this.$route.query.id +
    //         //     "%26schoolId=" +
    //         //     this.$route.query.schoolId +
    //         //     "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";

    //         // 粒子微校测试
    //         // 黄辉
    //         // 公司appId
    //         // let hhAppId = "wxa9991f1c4fbe89bb";
    //         // 测试号AppId
    //         let hhAppId = "wxc325ce2d2ba292a4";
    //         // 公司ip
    //         // let domain = "lzwxtest.lizihulian.com";
    //         // 本机ip
    //         let domain = "rcrhvx.natappfree.cc";
    //         // %2F = /
    //         // %3A = :
    //         // %3F = ?
    //         // %26 = &
    //         // 每次本地调试记得改这里
    //         let backUrl =
    //           // "http%3A%2F%2F" + domain + "%2Fapi%2Fwechat%2FauthorizeInfo%3F";
    //           "http%3A%2F%2F" + domain + "%2Fwechat%2FauthorizeInfo%3F";
    //         href =
    //           `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${hhAppId}&redirect_uri=` +
    //           backUrl +
    //           "type=" +
    //           this.$route.query.type +
    //           "%26id=" +
    //           this.$route.query.id +
    //           "%26schoolId=" +
    //           this.$route.query.schoolId +
    //           "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";

    //         // href =
    //         //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa9991f1c4fbe89bb&redirect_uri=https%3A%2F%2Fdwxx.liuyunchuan.com%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //     this.$route.query.type +
    //         //     "%26id=" +
    //         //     this.$route.query.id +
    //         //     "%26schoolId=" +
    //         //     this.$route.query.schoolId +
    //         //     "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //         // href =
    //         //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa9991f1c4fbe89bb&redirect_uri=http%3A%2F%2Fshjj.ubooking.cn%2Fapi%2Fwechat%2FauthorizeInfo%3Ftype=" +
    //         //     this.$route.query.type +
    //         //     "%26id=" +
    //         //     this.$route.query.id +
    //         //     "%26schoolId=" +
    //         //     this.$route.query.schoolId +
    //         //     "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
    //         // }
    //         // 链接存在才跳转
    //         if (href != "") {
    //           // 自动调整到授权页面
    //           window.location.href = href;
    //         } else {
    //           // 未找到学校信息
    //           Dialog.alert({
    //             title: "提示",
    //             message: "获取学校缓存信息失败，请关闭页面重新进入",
    //           }).then(() => {
    //             // on close
    //           });
    //         }
    //       }
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  font-size: 18px;
  background-color: #f9f9f9;
  .backimg {
    width: 100%;
    background: url("../assets/homepagebackimg.png") no-repeat;
    background-position: center;
    height: 220px;
    background-size: cover;
    // position: fixed;

    // 教师端
    .box {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 25px;
      font-size: 16px;
      .headimg_box {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 5px;
        .headimg {
          border-radius: 50%;
        }
      }
      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        .top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          .text {
            margin-right: 40px;
          }
          .change_class {
            font-size: 16px;
            color: rgb(204, 18, 55);
            .icon {
              transform: rotate(90deg);
              // ms-transform: rotate(90deg); /* IE 9 */
              // moz-transform: rotate(90deg); /* Firefox */
              // webkit-transform: rotate(90deg); /* Safari and Chrome */
              // o-transform: rotate(90deg); /* Opera */
            }
          }
        }
      }
    }

    // 监护人端
    .greetTitle {
      display: flex;
      align-items: center;
      padding-top: 30px;
      padding-left: 20px;
      padding-bottom: 20px;
      .text {
        padding-right: 40px;
      }
      .change_child {
        font-size: 16px;
        color: rgb(204, 18, 55);
        .icon {
          transform: rotate(90deg);
          // ms-transform: rotate(90deg); /* IE 9 */
          // moz-transform: rotate(90deg); /* Firefox */
          // webkit-transform: rotate(90deg); /* Safari and Chrome */
          // o-transform: rotate(90deg); /* Opera */
        }
      }
    }
    .headimg_box {
      padding-left: 20px;
      .headimg {
        border-radius: 50%;
      }
    }
  }
  .grid_box {
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9f4ee;
    .grid {
      width: calc(100% - 40px);
      .grid_item {
        .grid_img {
          object-fit: fill;
        }
        .grid_title {
          font-size: 16px;
          padding-top: 10px;
        }
      }
    }
  }
  .neweast_message {
    width: 100%;
    .title {
      font-size: 18px;
      margin: 10px;
    }
    .messageCards {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .messageCard {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        padding-top: 25px;
        padding-bottom: 25px;
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        .top {
          width: calc(100% - 60px);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 15px;
          .top_left {
            font-size: 16px;
            .icon {
              margin-right: 10px;
            }
          }
          .top_right {
            font-size: 16px;
            font-weight: bold;
            color: #fdbf41;
          }
        }
        .divide {
          width: 100%;
          left: 0;
          bottom: 0;
          height: 0.5px;
          border-bottom: 1px solid #c1c0c5;
          transform-origin: center;
          transform: scaleY(0.2);
        }
        .describe {
          width: calc(100% - 60px);
          font-size: 15px;
          color: #b7b7b7;
          height: 70px;
          padding-top: 10px;
        }
        .footer {
          width: calc(100% - 60px);
          font-size: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .footer_right {
            color: #b7b7b7;
          }
        }
      }
    }
  }
}
</style>
