<template>
  <van-cell-group>
    <van-field
      :label="father_data.label"
      label-width="25%"
      :error-message="father_data.errorMessage"
      required
    >
      <template #input>
        <van-uploader
          v-model="father_data.file_list"
          :max-count="father_data.max_count"
          :after-read="afterRead"
          :beforeDelete="beforeDelete"
          multiple
        />
        <!-- action="https://dwxx.liuyunchuan.com/api/account/upload" -->
      </template>
    </van-field>
  </van-cell-group>
</template>

<script>
import Exif from "exif-js";
import { upload } from "../api/api";

export default {
  name: "father_data",
  props: ["father_data"],
  data() {
    return {
      // father_data.file_list:[],
      files: {
        name: "", // 文件名称
        type: "", // 文件类型
      },
      //  v-model="father_data.file_list"
      file_list: [
        {
          url: "https://dwxx.liuyunchuan.com/static/SnapPhotos/student/2023-08-17/f75b3114489845b999b3f94334f75f17.jpg",
        },
      ],
    };
  },
  watch: {
    father_data: function () {
      console.log("444");
    },
  },

  created() {
    // this.father_data.file_list = this.father_data.file_list;
    // console.log(this.father_data)
    // console.log('34343')
  },
  methods: {
    handleParentClick(e) {
      console.log(e);
    },

    afterRead(file) {
      if (!file.length) {
        let index = this.father_data.file_list.length - 1;
        this.files.name = file.file.name; // 获取文件名
        this.files.type = file.file.type; // 获取类型
        this.picValue = file.file; // 文件流
        this.imgPreview(this.picValue, index);
        // file.status = 'uploading';
        file.message = "压缩中...";
      } else {
        let i = file.length;
        for (let item in file) {
          i--;
          let index = this.father_data.file_list.length - 1 - i;
          this.files.name = file[item].file.name; // 获取文件名
          this.files.type = file[item].file.type; // 获取类型
          this.picValue = file[item].file; // 文件流
          this.imgPreview(this.picValue, index);
          // file.status = 'uploading';
          file.message = "压缩中...";
        }
      }
      // console.log(this.father_data.file_list)
      this.$emit("goto", this.father_data.file_list);
    },

    // 处理图片
    imgPreview(file, index) {
      let self = this;
      let Orientation;
      // 去获取拍照时的信息，解决拍照出来的照片旋转问题
      Exif.getData(file, function () {
        Orientation = Exif.getTag(this, "Orientation");
      });
      // 看支持不支持FileReader
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2转化为 base64格式
        reader.readAsDataURL(file);
        // 读取成功后回调
        reader.onloadend = function () {
          let result = this.result;
          let img = new Image();
          img.src = result;
          // 判断图片是否小于500k，是就直接上传，反之压缩图片
          // if(this.result.length <= 500 * 1024){
          //   self.headerImage = this.result;
          //   self.postImg();
          // } else {
          img.onload = function () {
            let data = self.compress(img, Orientation);
            self.headerImage = data;
            self.postImg(index);
          };
          // }
        };
      }
    },

    // 压缩图片
    compress(img, Orientation) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      //瓦片canvas
      let tCanvas = document.createElement("canvas");
      let tctx = tCanvas.getContext("2d");
      // let initSize = img.src.length;
      let width = img.width;
      let height = img.height;
      //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      //    铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //如果图片像素大于100万则使用瓦片绘制
      let count;
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        let nw = ~~(width / count);
        let nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            );
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height);
      }

      // 判断设备的操作系统
      //       if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      //   console.log('isIOS');
      // } else if (/(Android)/i.test(navigator.userAgent)) {
      //   console.log('isAndroid');
      // } else {
      //   console.log('isPC');
      // }

      // console.log(Orientation);
      //修复ios上传图片的时候 被旋转的问题
      //？？？？
      //实际情况是在安卓系统需要对图片做旋转处理，在iOS中不需要对图片做旋转处理
      //
      if (
        Orientation != "" &&
        Orientation != 1 &&
        /(Android)/i.test(navigator.userAgent)
      ) {
        switch (
          Orientation
          // case 6: //需要顺时针（向左）90度旋转
          //   this.rotateImg(img, "left", canvas);
          //   break;
          // case 8: //需要逆时针（向右）90度旋转
          //   this.rotateImg(img, "right", canvas);
          //   // alert(888);
          //   break;
          // case 3: //需要180度旋转
          //   this.rotateImg(img, "right", canvas); //转两次
          //   this.rotateImg(img, "right", canvas);
          //   break;
        ) {
        }
      }

      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.1);
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
      return ndata;
    },

    // 旋转图片
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },

    //将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], this.files.name, {
        type: this.files.type,
      });
    },

    //这里写接口
    async postImg(index) {
      let that = this;

      that.father_data.file_list[index].content = that.headerImage; // 用手机拍照时，照片会顺时针旋转，这里把处理后的照片显示在页面上
      that.father_data.file_list[index].message = "压缩完成";
      that.father_data.file_list[index].status = "done";

      let file = that.dataURLtoFile(that.headerImage);
      let formData = new window.FormData();
      console.log(file);
      formData.append("file", file);

      upload(formData).then((res) => {
        console.log("getUserInfo返回数据", res.data.data);
        if (res.data.code == 1) {
          // 图片上传成功
          // that.father_data.file_list[index].photo_url = res.data.data;
          that.father_data.file_list[index].photo_url = res.data.data;
          that.father_data.photo_url = res.data.data;
        } else {
          // 图片上传失败
        }
      });
      return true;
    },

    beforeDelete(e) {
      this.father_data.file_list.splice(
        this.father_data.file_list.indexOf(e),
        1
      );
      this.$emit("goto", this.father_data.file_list);
      // this.father_data.file_list = this.father_data.file_list.filter(t => t.photo_url != e)
      console.log(this.father_data.file_list);
      return this.father_data.file_list;
    },
  },
};
</script>
